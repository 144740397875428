import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';
import { UnableToParseReturnsFlowStateError } from './UnableToParseReturnsFlowStateError';

export const { InvalidReturnsFlowStateDataError } = errorFactory(
  `InvalidReturnsFlowStateDataError`,
  UnableToParseReturnsFlowStateError
);

/** Error to use when a returns flows state parse fails due to invalid data. */
export type InvalidReturnsFlowStateDataError = InstanceTypeOf<
  typeof InvalidReturnsFlowStateDataError
>;
