'use client';

import {
  AriaRole,
  FunctionComponent,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useRef
} from 'react';

import { Nullable, Timeout } from '@/type-utils';
import { useBreakpoints } from '@/react/hooks/useBreakpoints';

import S from './styles.module.scss';

export interface IHoverIntentProps extends PropsWithChildren {
  /**
   * Callback that will be called when the hover intent is activated or deactivated.
   * @param active - The current state of the hover intent.
   */
  onHoverChange?: (active: boolean) => void;

  /** Callback that will be called when the hover intent is activated. */
  onHoverIn?: () => void;

  /** Callback that will be called when the hover intent is deactivated. */
  onHoverOut?: () => void;

  /** Delay in milliseconds to trigger the hover action. Defaults to `175`. */
  delay?: number;

  /**
   * Delay in milliseconds to deactivate the hover intent after the mouse leaves.
   * If unspecified, it will use the same value as `delay`.
   */
  delayOut?: number;

  /**
   * If supplied, the timeout will be stored in this ref. Use it when overriding the same
   * timeout with multiple `HoverIntent` components.
   *
   * @example
   *
   * ```tsx
   * const [panelActive, setPanelActive] = useState<boolean>(false);
   * const timeoutRef = useRef<Nullable<Timeout>>(null);
   *
   * // The hover intent of these three tabs share the same timeout.
   * return (
   *    <TabPanel>
   *      <HoverIntent onHoverChange={setPanelActive} timeoutRef={timeoutRef}>
   *          <Tab />
   *      </HoverIntent>
   *      <HoverIntent onHoverChange={setPanelActive} timeoutRef={timeoutRef}>
   *          <Tab />
   *      </HoverIntent>
   *      <HoverIntent onHoverChange={setPanelActive} timeoutRef={timeoutRef}>
   *          <Tab />
   *      </HoverIntent>
   *    </TabPanel>
   * );
   * ```
   */
  timeoutRef?: MutableRefObject<Nullable<Timeout>>;

  /**
   * The [ARIA Role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles) to use.
   * It is only advised to use interactive ARIA roles on hoverable elements.
   */
  ariaRole?: AriaRole;

  /**
   * Tab index. Please keep in mind that due to a11y guidelines, tabbable elements must also
   * include an [interactive ARIA role](https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-noninteractive-tabindex.md).
   */
  tabIndex?: number;

  /** If the hover intent should be activated on focus. Defaults to `true`. */
  activatedByFocus?: boolean;

  /** If the hover intent should be deactivated on blur. Defaults to `true`. */
  deactivatedByBlur?: boolean;

  /**
   * If the hover intent should be disabled. Use this prop to conditionally
   * allow hovering.
   */
  disabled?: boolean;
}

/**
 * Utility component that notifies (via the supplied callbacks) when the user
 * shows the intention of hovering over its contents.
 *
 * @example
 *
 * ```tsx
 * // TASK: Make it so a tooltip appears when the user hovers over the Save button.
 *
 * const [active, setActive] = useState<boolean>(false);
 *
 * return (
 *      <HoverIntent onHoverChange={setPanelActive}>
 *          <Tooltip visible={active} body="Saves the latest changes"/>
 *          <Button>Save</Button>
 *      </HoverIntent>
 * );
 *
 * // Or, if you want discrete callbacks for in and out:
 * return (
 *      <HoverIntent
 *        onHoverIn={() => setPanelActive(true)}
 *        onHoverOut={() => setPanelActive(false)}
 *      >
 *          <Tooltip visible={active} body="Saves the latest changes"/>
 *          <Button>Save</Button>
 *      </HoverIntent>
 * );
 * ```
 */
export const HoverIntent: FunctionComponent<IHoverIntentProps> = ({
  onHoverChange,
  onHoverIn,
  onHoverOut,
  delay = 175,
  delayOut = delay,
  timeoutRef,
  ariaRole = 'link',
  tabIndex = 0,
  activatedByFocus = true,
  deactivatedByBlur = true,
  disabled = false,
  children
}) => {
  // Check for desktop. On mobile, we do not want to use `HoverIntent`, as some mobile
  // browsers emulate hover events and others do not, leading to an inconsistent
  // experience. Due to this, we prevent hovering at mobile breakpoints.
  const { isDesktop } = useBreakpoints();

  // If a timeoutRef is not specified, create a new one.
  const ref = timeoutRef ?? useRef<Nullable<Timeout>>(null);

  // Activate will...
  const activate = useCallback(() => {
    // Do nothing if the hover intent is disabled or if we're currently at a mobile 
    // breakpoint.
    if (disabled || !isDesktop) return;

    if (onHoverChange) onHoverChange(true); // Call both `onHoverChange` with `true`...
    if (onHoverIn) onHoverIn(); // ...and `onHoverIn`.
  }, [disabled, onHoverChange, onHoverIn]);

  // Deactivate will...
  const deactivate = useCallback(() => {
    // Do nothing if the hover intent is disabled or if we're currently at a mobile 
    // breakpoint.
    if (disabled || !isDesktop) return;

    if (onHoverChange) onHoverChange(false); // Call both `onHoverChange` with `false`...
    if (onHoverOut) onHoverOut(); // ...and `onHoverOut`.
  }, [disabled, onHoverChange, onHoverOut]);

  const timeoutActivate = useCallback(() => {
    // Clear the current timeout (if any)...
    if (ref.current) clearTimeout(ref.current as Timeout);

    // ...and set a new timeout to call `activate`.
    ref.current = setTimeout(() => {
      activate();
    }, delay);
  }, [ref, activate]);

  const timeoutDeactivate = useCallback(() => {
    // Clear the current timeout (if any)...
    if (ref.current) clearTimeout(ref.current as Timeout);

    // ...and set a new timeout to call `deactivate`.
    ref.current = setTimeout(() => {
      deactivate();
    }, delayOut);
  }, [ref, deactivate]);

  return (
    <div
      className={S.hoverIntent}
      role={ariaRole}
      tabIndex={tabIndex}
      onMouseEnter={timeoutActivate}
      onMouseLeave={timeoutDeactivate}
      onFocus={activatedByFocus ? activate : undefined}
      onBlur={deactivatedByBlur ? deactivate : undefined}
    >
      {children}
    </div>
  );
};
