'use client';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';
import GenericSelector from '../GenericSelector';

interface IMaterialSelectorProps {
  /**
   * The product view model to render the material selector for.
   */
  product: IProductVM;
}

/** The Group selector component for the PDP. */
const MaterialSelector = observer(function MaterialSelector({
  product
}: IMaterialSelectorProps) {
  return (
    <GenericSelector
      variationType={DisplayVariationAttributeType.Material}
      product={product}
    />
  );
});

export default MaterialSelector;
