import { IMaskedHistoricOrder } from '../data-structures/IMaskedHistoricOrder';
import { IOrderHistory } from '../../../models/OrderHistory/IOrderHistory';

import {
  GET_ORDER_MASKED,
  BACKORDER_ORDER,
  CANCELED_ORDER,
  DELIVERY_ORDER,
  PREORDER_ORDER,
  PROCESSING_ORDER,
  SHIPPED_ORDER
} from './GET_ORDER_MASKED';

const GET_ORDERS_MASKED = {
  pageSize: 20,
  totalPages: 1,
  totalItems: 7,
  currentPage: 0,
  hasNext: false,
  hasPrevious: false,
  isLoadingNewPage: false,
  allItems: [
    GET_ORDER_MASKED,
    DELIVERY_ORDER,
    BACKORDER_ORDER,
    CANCELED_ORDER,
    PROCESSING_ORDER,
    SHIPPED_ORDER,
    PREORDER_ORDER
  ]
} as IOrderHistory<IMaskedHistoricOrder>;

export default GET_ORDERS_MASKED;
