import { IHistoricOrder } from '../data-structures/IHistoricOrder';
import { IOrderHistory } from '../../../models/OrderHistory/IOrderHistory';
import {
  BACKORDER_ORDER,
  CANCELED_ORDER,
  DELIVERY_ORDER,
  GET_ORDER_UNMASKED,
  PREORDER_ORDER,
  PROCESSING_ORDER,
  SHIPPED_ORDER
} from './GET_ORDER_UNMASKED';

const GET_ORDERS_UNMASKED = {
  pageSize: 20,
  totalPages: 1,
  totalItems: 7,
  currentPage: 0,
  hasNext: false,
  hasPrevious: false,
  isLoadingNewPage: false,
  allItems: [
    GET_ORDER_UNMASKED,
    DELIVERY_ORDER,
    BACKORDER_ORDER,
    CANCELED_ORDER,
    PROCESSING_ORDER,
    SHIPPED_ORDER,
    PREORDER_ORDER
  ]
} as IOrderHistory<IHistoricOrder>;

export default GET_ORDERS_UNMASKED;
