import LoggerService from '@/services/isomorphic/LoggerService';
import {
  PersonalizationService,
  type Decision,
  type ExperienceID
} from '@/services/isomorphic/PersonalizationService';
import type { IPage } from '@/services/models/Page';
import type { Nullable } from '@/type-utils';
import { useEffect, useState } from 'react';

/** Options for the `useDecision` hook. */
export interface IUseDecisionOptions {
  /** The page that the experience is being rendered on. */
  page?: Nullable<IPage>;
}

/**
 * A hook that fetches the decision for the given experience ID.
 * @param experienceID - The experience ID to fetch a decision for.
 * @param options - The options for the hook.
 * @returns The decision object for the experience ID, or `null` if it is still loading.
 * @throws If there was an error fetching the decision.
 */
export function useDecision(
  experienceID: ExperienceID,
  options: IUseDecisionOptions = {}
): Decision | undefined {
  const { page } = options;

  // While the decision is unknown it is null.
  const [decision, setDecision] = useState<Decision | undefined>();

  // Error indicator.
  const [errorInd, setErrorInd] = useState<{ error: unknown } | undefined>();

  useEffect(
    function getDecision() {
      let isStale = false;

      const fetchDecision = async (): Promise<void> => {
        setErrorInd(undefined);

        try {
          const decision =
            await PersonalizationService.getExperienceForCurrentUser(
              experienceID,
              page
            );

          if (isStale) return;
          setDecision(decision);
        } catch (error) {
          LoggerService.warn((error as Error).message);
          if (isStale) return;
          setErrorInd({ error });
        }
      };

      fetchDecision();

      return () => {
        isStale = true;
      };
    },
    [experienceID, page]
  );

  // If there was an indication of an error, throw it to the error boundary.
  if (errorInd) {
    throw errorInd.error;
  }

  return decision;
}
