'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import type CartVM from '@/react/view-models/CartVM';
import { observer } from 'mobx-react-lite';
import type IStylable from '../../traits/IStylable';
import { ApplePayButton, type IApplePayButtonProps } from '../ApplePayButton';
import { useApplePayClickHandler } from './internal/useApplePayClickHandler';

import S from './styles.module.scss';

/**
 * The props for the `BuyWithApplePayButton` component.
 */
export interface IBuyWithApplePayButtonProps
  extends IStylable,
    Omit<IApplePayButtonProps, 'onClick' | 'locale'> {
  /**
   * The cart view model which contains the order to be placed with Apple Pay.
   */
  cart: CartVM;
}

/**
 * A higher-level Apple Pay button component that enables bringing up
 * the payment sheet and placing orders for the given cart.
 */
export const BuyWithApplePayButton = observer(function BuyWithApplePayButton({
  cart,
  isLoading = false,
  className,
  ...props
}: IBuyWithApplePayButtonProps) {
  const handlePayment = useApplePayClickHandler(cart);

  return (
    <ApplePayButton
      variant="buy"
      onClick={handlePayment}
      isLoading={isLoading || !cart.isReady || cart.isRevalidating} // show a spinner while the button doesn't do anything
      className={classes(S.buyWithApplePayBtn, className)}
      {...props}
    />
  );
});
