import { writePlacedOrderToSession } from '@/react/utils/checkout-utils/writePlacedOrderToSession';
import { withPreventUnload } from '@/react/utils/dom-utils';
import type CartVM from '@/react/view-models/CartVM';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PaymentInterruptedError } from '@/services/isomorphic/PaymentRequestService';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';

/**
 * A hook that provides the click handler for the Apple Pay button.
 * @param cart - The cart view model which contains the order to be placed with Apple Pay.
 * @returns The click handler for the Apple Pay button.
 *
 * **Implementation Note:** This hook is not meant to be reusable. It exists primarily to encapsulate
 * the event handler logic so that it can be tested holistically, due to the constraints of the Apple Pay API.
 */
export function useApplePayClickHandler(cart: CartVM): () => void {
  const router = useRouter();

  const handlePayment = useCallback(async () => {
    /** {@link CartVM.orderWithApplePay} must not be called if the cart is not ready. */
    if (!cart.isReady) return;

    /**
     * Do **NOT** place any asynchronous code before `orderWithApplePay()`.
     * It must run synchronously until the Apple Pay Session is created, or
     * it will error.
     */

    try {
      await withPreventUnload(async () => {
        const order = await cart.orderWithApplePay();

        writePlacedOrderToSession(order);
        router.push(`/checkout/confirmation?token=${order.orderToken}`);

        // prevent unload until we have finished emptying the cart
        // this helps prevent the user from ending up in an out-of-sync state
        await cart.empty();
      });
    } catch (error) {
      if (error instanceof PaymentInterruptedError) {
        LoggerService.error(error);
        return;
      }
      throw error;
    }
  }, [cart, router]);

  return handlePayment;
}
