'use client';

import { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import { IImage } from '@/services/models/Media/Image';
import { ISliderProps, Slide, Slider } from '../../../Slider';
import { Image } from '../../../core-ui/Image';

import { useBreakpoints } from '../../../../hooks/useBreakpoints';

import S from './styles.base.module.scss';

export interface IQuickviewCarouselProps {
  /** Images to display in the carousel. */
  images: Array<IImage>;
}

/**
 * Generates slides based on an array of images.
 * @param images - Images off the product model.
 * @returns An array of react elements.
 */
function generateSliderImages(
  images: ReadonlyArray<IImage>
): Array<ReactElement> {
  return images.map((imageObject) => {
    const image = {
      src: imageObject.src,
      alt: imageObject.alt,
      uuid: imageObject.uuid
    };
    return (
      <Slide key={image.src}>
        <Image image={image} className={S.imageContainer} />
      </Slide>
    );
  });
}

/** The main caraousel for the PDP. */
export const QuickviewCarousel: FunctionComponent<IQuickviewCarouselProps> = ({
  images
}) => {
  const { isDesktop } = useBreakpoints();

  const [mainImages, setMainImages] = useState<Array<ReactElement>>(
    generateSliderImages(images)
  );

  const sliderConfig = {
    height: '100%',
    loop: true,
    navigation: {
      enabled: true,
      buttonClassName: S.navigation,
      next: {
        className: S.next
      },
      prev: {
        className: S.prev
      }
    },
    pagination: {
      inset: false,
      activeClassName: S.activeCircle,
      wrapperClassName: S.pagination,
      buttonClassName: S.circle
    }
  } as ISliderProps;

  useEffect(() => {
    setMainImages(generateSliderImages(images));
  }, [isDesktop, images]);

  return (
    <div className={S.container}>
      {sliderConfig && <Slider {...sliderConfig}>{mainImages}</Slider>}
    </div>
  );
};
