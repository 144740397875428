'use client';

import { IPrice, PriceModel } from '@/services/models/Price';
import { Nullable } from '@/type-utils';
import { FunctionComponent } from 'react';

import { MoneyModel, stripDecimalsIfWhole } from '@/services/models/Money';
import { observer } from 'mobx-react-lite';
import IStylable from '../../traits/IStylable';
import SkeletonLoader from '../../utility/SkeletonLoader';
import S from './styles.module.scss';

export interface IPriceProps extends IStylable {
  /** The price to be converted. */
  price: Nullable<IPrice>;
}

/** Size selector component for the pdp. */
export const Price: FunctionComponent<IPriceProps> = observer(function Price({
  price,
  className,
  style
}) {
  // If the product model hasn't loaded, show a skeleton loader.

  if (!price) return <SkeletonLoader className={S.skeletonLoader} />;
  const priceModel = PriceModel.from(price);

  /**
   * The product is considered to be "on sale" when it has both
   * a "retail price" and "current price" and when the "current price"
   * is less than the "retail price".
   */
  const isOnSale = !!priceModel.currentPrice?.isLessThan(
    priceModel.retailPrice
  );

  return (
    <div className={className} style={style}>
      {isOnSale ? (
        <>
          <OriginalCost amount={priceModel.retailPrice} />
          <CurrentCost amount={priceModel.currentPrice!} />
        </>
      ) : (
        <CurrentCost amount={priceModel.retailPrice} />
      )}
    </div>
  );
});

/**
 * Props for rendering money.
 */
interface IMoneyProps {
  /**
   * The amount of money to display.
   */
  amount: MoneyModel;
}

/**
 * Renders the sale price.
 * @param props - The props for the component.
 */
const OriginalCost: FunctionComponent<IMoneyProps> = ({ amount }) => {
  return <span className={S.sale}>{stripDecimalsIfWhole(amount)}</span>;
};

/**
 * Renders the sale price.
 * @param props - The props for the component.
 */
const CurrentCost: FunctionComponent<IMoneyProps> = ({ amount }) => {
  return <span>{stripDecimalsIfWhole(amount)}</span>;
};
