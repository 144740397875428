import axios, { AxiosInstance } from 'axios';
import siteCached from '@/services/utils/siteCached';
import Service from '../../Service';
import type { ISizeChart } from '../../serverless/integrations/AWS/AWSSizeChartService';
import SizeChartServiceMock, { mockSizeChart } from './SizeChartServiceMock';

/**
 * Service that returns all available shoe sizes in different types of measurements.
 */
export class SizeChartService extends Service {
  private client: AxiosInstance;

  /** Constructs a new `SizeChartService`. */
  public constructor() {
    super();

    this.client = axios.create({
      baseURL: '/api'
    });
  }

  /**
   * Retrieves all available shoe sizes in different types of measurements.
   * @param productId - The slug for the specific blog post.
   * @returns A promise that resolves to an array of blog post objects `ISizeChart[]`.
   * @see {@link ISizeChart}
   */
  @siteCached
  public async getSizeChart(): Promise<ISizeChart> {
    // We need to use the data that is currently the mock. This needs to work in prod so,
    // we can't actually mock it.
    // const { data } = await this.client.get<ISizeChart>(`/size-chart`);

    const data = mockSizeChart;

    return data;
  }
}

export default SizeChartService.withMock(
  new SizeChartServiceMock(SizeChartService)
) as unknown as SizeChartService;
