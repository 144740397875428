'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { MoneyModel, type IMoney } from '@/services/models/Money';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

/** Props for the {@link LineItemTotal} component. */
export interface ILineItemTotalProps {
  /** The current total price of the line item. */
  currentTotal: IMoney;
  /**
   * The original total price of the line item.
   * If this is provided, it will be displayed with a strikethrough.
   */
  originalTotal?: IMoney;
}

/** Displays the total price of a line item. */
export const LineItemTotal = observer(function LineItemTotal({
  currentTotal,
  originalTotal
}: ILineItemTotalProps) {
  const isTotalSame =
    !originalTotal || MoneyModel.from(originalTotal).isEqualTo(currentTotal);

  return (
    <>
      {isTotalSame ? (
        <span className={S.priceValue}>
          {MoneyModel.from(currentTotal).toString()}
        </span>
      ) : (
        <>
          <span className={classes(S.priceValue, S.priceStrikethrough)}>
            {MoneyModel.from(originalTotal).toString()}
          </span>
          <span className={S.priceValue}>
            {MoneyModel.from(currentTotal).toString()}
          </span>
        </>
      )}
    </>
  );
});
