import { Country } from '@/constructs/Country';
import { Brand } from '@/constructs/Brand';
import { Currency } from '../../Money';
import IShippingMethod from '../IShippingMethod';

const MOCK_SHIPPING_METHOD: IShippingMethod = {
  id: 'STG',
  uid: 'n9sx1wf13so4m4550768ly0e',
  country: Country.US,
  brand: Brand.Ahnu,
  name: 'Standard Ground',
  description: 'Standard Ground Shipping',
  shippingCost: {
    currency: Currency.USD,
    amount: '0'
  },
  tax: {
    currency: Currency.USD,
    amount: '0'
  },
  taxRate: 0,
  cutoffTime: '20:00:00',
  earliestDeliveryDays: 3,
  latestDeliveryDays: 6
};

export default MOCK_SHIPPING_METHOD;
