import { mocked } from '@/configs';

import { Constructor, DTO } from '@/type-utils';
import Service from '../../Service';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { RecommendationService } from './RecommendationService';

export const MOCK_RECOMMENDATION = {
  title: 'New Arrivals',
  productList: ['1166670-WWH', '1166670-WWH', '1166670-WWH', '1166670-WWH']
};

const initialState = {};

/**
 * Mock implementation of the RecommendationService class.
 */
class RecommendationServiceMock extends ServiceMock<RecommendationService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): RecommendationService {
    return MockService.getMockOf(
      this.service
    ) as unknown as RecommendationService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<RecommendationService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<RecommendationService>
  ): void {
    const mockEnabled: boolean = mocked.RecommendationService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getDynamicRecommendations: async () => {
          return Promise.resolve(MOCK_RECOMMENDATION);
        },
        getStaticRecommendations: async () => {
          return Promise.resolve(MOCK_RECOMMENDATION);
        },
        getContextConfig: () => ({
          isRecommendationEnabled: true,
          isSlideOnMobile: false
        })
      },
      {},
      this.state
    );
  }
}

export default RecommendationServiceMock;
