import { LineItemType } from '../../Cart/LineItem';
import { MOCK_LINE_ITEM_PROMOTION } from '../../Cart/Promotion/mocks';
import { Currency } from '../../Money';
import { IOrderLine } from '../IOrderLine';

export default {
  lineNumber: 1,
  uuid: '0012ea21-0af8-4a95-9457-10aa6adb823b',
  sku: '1166670-WWH-11',
  upc: '194715359611',
  name: 'Sequence 1 Low',
  quantity: 1,
  netTotal: {
    currency: Currency.USD,
    amount: '50'
  },
  total: {
    currency: Currency.USD,
    amount: '50'
  },
  unitPrice: {
    currency: Currency.USD,
    amount: '50'
  },
  subtotal: {
    currency: Currency.USD,
    amount: '50'
  },
  tax: {
    currency: Currency.USD,
    amount: '0'
  },
  taxRate: 0,
  type: LineItemType.Product,
  promotions: [MOCK_LINE_ITEM_PROMOTION],
  isCancellable: true,
  isReturnable: true
} satisfies IOrderLine;
