'use client';

import { useContext } from 'react';

import {
  GlobalContext,
  IGlobalContext
} from '../providers/global/GlobalContext';

/**
 * `useGlobalContext` fetches global data that can be used by multiple components on any page.
 * This includes:
 * Cart - The unified cart data.
 * ShippingMethod - The current preferred shipping method and a method to set a new method name.
 * @returns The shared user cart, currently in the global context.
 * @throws If used outside the global context.
 */
export const useGlobalContext = (): IGlobalContext => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      'useGlobalContext hook can only be used inside a GlobalProvider.'
    );
  }

  return context;
};
