'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { useLocale } from '@/react/hooks/useLocale';
import { type FC } from 'react';
import type IStylable from '../../traits/IStylable';
import { useApplePayScript } from '../hooks/useApplePayScript';
import {
  IInternalApplePayButtonProps,
  InternalApplePayButton
} from './internal/InternalApplePayButton';

import S from './styles.module.scss';

/**
 * The props for the `ApplePayButton` component.
 */
export interface IApplePayButtonProps
  extends IStylable,
    Omit<IInternalApplePayButtonProps, 'buttonStyle' | 'type' | 'locale'> {
  /**
   * The theme of the Apple Pay button.
   * @default 'black'
   */
  theme?: IInternalApplePayButtonProps['buttonStyle'];
  /**
   * The variant of the Apple Pay button.
   * @default 'buy'
   */
  variant?: IInternalApplePayButtonProps['type'];
  /**
   * The locale of the Apple Pay button.
   * @default I18NService.currentLocale.toString()
   */
  locale?: IInternalApplePayButtonProps['locale'];
  /**
   * Whether or not the button is in a loading state and cannot be interacted with.
   * @default false
   */
  isLoading?: boolean;
}

/**
 * A thin wrapper over the `InternalApplePayButton` component that handles
 * the loading of the Apple Pay JS SDK, supports Storybook, and exposes
 * a slightly nicer API.
 */
export const ApplePayButton: FC<IApplePayButtonProps> = ({
  theme = 'black',
  variant = 'buy',
  isLoading = false,
  onClick,
  ...props
}) => {
  const locale = useLocale();
  const [script, isAvailable] = useApplePayScript();

  return (
    <>
      {script}
      {isAvailable && (
        <span className={classes(S.wrapper, isLoading && S.wait)}>
          <InternalApplePayButton
            type={variant}
            buttonStyle={theme}
            locale={locale.toString() as ApplePayButtonLocale}
            onClick={isLoading ? undefined : onClick}
            {...props}
          />
        </span>
      )}
    </>
  );
};
