'use client';

import { IProduct } from '@/services/models/Product';
import { Nullable } from '@/type-utils';
import { useEffect, useMemo } from 'react';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import ProductVM, { type IProductVM } from '../view-models/ProductVM';
import { ProductAdapter } from '../view-models/ProductVM/adapters';
import { GenderAdaptiveProductVM } from '../view-models/ProductVM/decorators/GenderAdaptiveProductVM';

/**
 * Creates a product view model from a given {@link IProduct}.
 * @param product - The product to create a view model from.
 * @returns A product view model.
 */
export function useProductVM(product: IProduct): IProductVM;
export function useProductVM(
  product: Nullable<IProduct>
): IProductVM | undefined;

/**
 * Creates a product view model from a given {@link IProduct}.
 * @param product - The product to create a view model from.
 * @returns A product view model.
 */
export function useProductVM(
  product: Nullable<IProduct>
): IProductVM | undefined {
  const productVM = useMemo(() => {
    if (product) {
      let vm: IProductVM = new ProductVM(ProductAdapter.fromProduct(product));

      const genderAdaptiveConfig = ConfigurationService.getConfig(
        'product'
      ).getSetting('details.genderAdaptiveDisplay');

      // Use Gender Adaptive Display if enabled and the product has all-gender
      // sizing available.
      if (genderAdaptiveConfig.enabled.value && vm.hasAllGenderSizing) {
        vm = new GenderAdaptiveProductVM(vm);
      }

      vm.updateInventory();

      return vm;
    }

    return undefined;
  }, [product]);

  useEffect(() => {
    productVM?.updateInventory();
  }, []);

  return productVM;
}
