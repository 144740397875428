import type { ReturnItem } from './ReturnItem';

/** Enumerates valid types for a {@link ReturnItem Return Item}. */
enum ReturnItemType {
  /** The item is returned and the customer receives a refund. */
  Refund = 'Refund',

  /**
   * The item is returned and the customer receives back
   * another one of the exact same SKU (model, color and size).
   *
   * Mostly used when the customer receives a defective item.
   *
   * Can be considered a perfectly Even Exchange since the item
   * being received in return is of the exact same variant.
   */
  SameSKUExchange = 'SameSKUExchange',

  /**
   * The item is returned and the customer receives back
   * a different variation from the same model (only color
   * or size can change).
   *
   * Useful when the original item didn't fit or if the color
   * was not as expected.
   *
   * This is also considered an Even Exchange since variants of
   * the same model usually have the same price.
   */
  SameModelExchange = 'SameModelExchange',

  /**
   * The item is returned and the customer receives back an item
   * of an entirely different model (model, color, and size can
   * change).
   *
   * Also known as an Uneven Exchange.
   */
  DifferentModelExchange = 'DifferentModelExchange'
}

export default ReturnItemType;
