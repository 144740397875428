import { msg } from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { GroupName } from '@/services/models/Product';
import { exhaustiveFallback } from '@/utils/function-utils';
import { product_group_men } from "@/lang/__generated__/ahnu/product_group_men";
import { product_group_women } from "@/lang/__generated__/ahnu/product_group_women";
import { product_group_youth } from "@/lang/__generated__/ahnu/product_group_youth";
import { product_group_kids } from "@/lang/__generated__/ahnu/product_group_kids";
import { product_group_unisex } from "@/lang/__generated__/ahnu/product_group_unisex";
import { product_group_allgender } from "@/lang/__generated__/ahnu/product_group_allgender";

/* eslint-disable @typescript-eslint/explicit-function-return-type -- The inferred type is
   more useful in this case and easier to maintain. */
/**
 * Returns the translated group string for a given group. For example, if the group is
 * `men`, this function will return the translated string for `product.group.men` ("Men"
 * in English). If the group is `women`, this function will return the translated string
 * for `product.group.women` ("Women" in English).
 * @param group - The group to translate, for instance "men".
 * @param sku - The product SKU. This is used for logging purposes.
 * @returns The translated group string.
 */
// TODO: Move this to the product view model.
export const toGroupString = (group: GroupName, sku: string) => {
  // While this helper might look like madness, it is necessary because  //
  // resource string identifiers need to be statically analyzable by the i18n tooling. We
  // do have the option of calling `msgDynamic` instead, but that would require us to load
  // all resource strings at runtime and make the value of this string async. To get the
  // greatest performance benefit we use the switch statement here instead.

  switch (group) {
    case GroupName.Men:
      return msg(product_group_men);
    case GroupName.Women:
      return msg(product_group_women);
    case GroupName.Youth:
      return msg(product_group_youth);
    case GroupName.Kids:
      return msg(product_group_kids);
    case GroupName.Unisex:
      return msg(product_group_unisex);
    case GroupName.AllGender:
      return msg(product_group_allgender);
    default: {
      return exhaustiveFallback(group, () => {
        LoggerService.warn(
          `Product "${sku}" tried to render an unknown group "${group}".`
        );
        return '' as const;
      });
    }
  }
};

/* eslint-enable @typescript-eslint/explicit-function-return-type */
