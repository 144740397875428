'use client';

import { useMemo } from 'react';

import { ReturnsFlowStateModel } from '@/services/models/Returns/flow';
import { Nullable } from '@/type-utils';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

/**
 * Helper hook that provides the {@link ReturnsFlowStateModel} stored
 * in the current session. If no returns flow state data is found
 * in session, a new one will be created and returned.
 *
 * @returns A `null` or `undefined` value if the model is still being
 * loaded from session or created, and a {@link ReturnsFlowStateModel} once loaded.
 */
export const useReturnsFlowState = (): Nullable<ReturnsFlowStateModel> => {
  const flowStateModel = useMemo(
    () =>
      (typeof window !== "undefined")
        ? ReturnsFlowStateModel.fromSessionStorage()
        : null,
    []
  );

  return flowStateModel;
};
