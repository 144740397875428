'use client';

import { useEffect, useState } from 'react';

import { ProductModel } from '@/services/models/Product';
import { Nullable } from '@/type-utils';

import ProductService from '@/services/isomorphic/ProductService';

/**
 * `useProduct` fetches product data based on sku.
 * @param sku - The product's Stock Keeping Unit.
 * @returns A Product Model.
 */
export const useProduct = (sku: string): Nullable<ProductModel> => {
  const [product, setProduct] = useState<Nullable<ProductModel>>();

  useEffect(() => {
    let isStale = false;

    const fetchProduct = async (): Promise<void> => {
      const product = await ProductService.getProduct(sku);
      if (!isStale) {
        setProduct(product);
      }
    };

    fetchProduct();

    return () => {
      isStale = true;
    };
  }, [sku]);

  return product;
};
