import { UnableToParseModelError } from '../../../serverless/ServerProductService';
import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { UnableToParseReturnsFlowStateError } = errorFactory(
  `UnableToParseReturnsFlowStateError`,
  UnableToParseModelError
);

/** Generic error to use when a returns flows state parse fails. */
export type UnableToParseReturnsFlowStateError = InstanceTypeOf<
  typeof UnableToParseReturnsFlowStateError
>;
