import Service from '@/services/Service';
import ConfigurationService from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';

/**
 * The `PaymentMethodsService` is used to handle operations related to payment methods.
 *
 * Specifically, it provides a method called `getAvailableExpressPaymentMethods()`
 * that retrieves the available express payment methods.
 *
 * Express payment methods are typically integrations with third-party payment
 * service providers or payment gateways that offer fast and secure payment processing.
 * Examples of express payment methods include Apple Pay, Google Pay, PayPal, and others.
 *
 * This service is important because it encapsulates the logic for retrieving and handling payment methods.
 */
export class PaymentMethodsService extends Service {
  /**
   * Simply checks that Apple Pay is enabled in `@/configs`. This does not guarantee
   * that Apple Pay features may be shown, nor that the user's browser supports them.
   * @returns `true` if Apple Pay is enabled, `false` otherwise.
   */
  public isApplePayEnabled(): boolean {
    const applePayConfig = ConfigurationService.getConfig('applePay');
    const enabledApplePay = applePayConfig.getSetting('enabled').value;

    return enabledApplePay;
  }

  /**
   * Checks if Apple Pay features are supported in the current environment.
   *
   * On the server, this method always returns `false` because the `ApplePaySession`
   * object is not available in the server. In the browser, this method checks that:
   * - The current protocol is HTTPS
   * - The browser supports the version of Apple Pay used by the site
   * - The device can make payments.
   *
   * **Note:** This method does not check if Apple Pay is enabled in the configuration.
   * It must be used in conjunction with {@link isApplePayEnabled isApplePayEnabled()} to determine if Apple Pay
   * features may be shown.
   *
   * @returns `true` if Apple Pay features are supported, `false` otherwise.
   */
  public isApplePaySupported(): boolean {
    if ((typeof window === "undefined")) return false;

    const applePayConfig = ConfigurationService.getConfig('applePay');
    const applePayVersion = applePayConfig.getSetting('version').value;

    return Boolean(
      // Apple Pay is only available over HTTPS
      window.location.protocol === 'https:' &&
        // ensure the version used by the site is supported by the browser
        window.ApplePaySession?.supportsVersion(applePayVersion) &&
        // ensure the device can make payments
        window.ApplePaySession.canMakePayments()
    );
  }
}

export default new PaymentMethodsService();
