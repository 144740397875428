import type IReturnOrder from './IReturnOrder';

/**
 * Enumerates valid types for a {@link IReturnOrder Return Order}.
 *
 * `Refund` type return orders will have an `"_RT"` string
 * appended to their order IDs.
 *
 * E.g.: `SUUSC6858ST18Q_RT`.
 *
 * -----.
 *
 * `Exchange` type return orders will have an `"_EX"` string
 * appended to their order IDs.
 *
 * E.g.: `SUUSC6858ST18Q_EX`.
 *
 * -----.
 *
 * Existing exchange-type return orders can also be exchanged
 * or refunded. The respective suffixes would stack in this case.
 *
 * E.g.: `SUUSC6858ST18Q_EX_EX`, `SUUSC6858ST18Q_EX_RT`.
 *
 * -----.
 *
 * Existing refund-type return orders CANNOT be stacked.
 *
 * E.g.: `SUUSC6858ST18Q_EX_RT` cannot be returned or exchanged.
 */
export enum ReturnOrderType {
  /** The items are returned and the customer receives a refund. */
  Refund,

  /**
   * The items are returned and the customer selects another set of
   * items to receive in exchange.
   */
  Exchange
}

export default ReturnOrderType;
