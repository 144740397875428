import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { DynamicExperienceError } = errorFactory(
  'DynamicExperienceError'
);

/** An error that occurs when the `DynamicExperience` component is used incorrectly. */
export type DynamicExperienceError = InstanceTypeOf<
  typeof DynamicExperienceError
>;
