import { StorageModel } from '../../models/Storage';
import Service from '@/services/Service';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { Nullable } from '@/type-utils';

/**
 * Client-only abstraction service for the
 * [Web Storage API](https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API).
 */
export class WebStorageService extends Service {
  /**
   * Retrieves a {@link StorageModel} representing the
   * document's [localStorage](https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage).
   *
   * @returns A {@link StorageModel} for `localStorage`, or `null` if this environment does not support it.
   */
  public get localStorage(): Nullable<StorageModel> {
    if ((typeof window !== "undefined"))
      return StorageModel.fromWebStorage(localStorage);

    return null;
  }

  /**
   * Retrieves a {@link StorageModel} representing the
   * document's [sessionStorage](https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage).
   *
   * @returns A {@link StorageModel} for `sessionStorage`, or `null` if this environment does not support it.
   */
  public get sessionStorage(): Nullable<StorageModel> {
    if ((typeof window !== "undefined"))
      return StorageModel.fromWebStorage(sessionStorage);

    return null;
  }
}

export default new WebStorageService();
