'use client';

import { PageService } from '@/services/isomorphic/PageService';
import type { FC, ReactNode } from 'react';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import type { Decision } from '../Decision';
import {
  IInternalDynamicExperienceProps,
  InternalDynamicExperience
} from './internal/InternalDynamicExperience';

export interface IDynamicExperienceProps
  extends Omit<IInternalDynamicExperienceProps, 'page'> {
  /**
   * A fallback component that is only shown when a decision fails to be made
   * due to some error.
   *
   * This is *not* the fallback for when the decision is loading.
   */
  fallback: Exclude<ReactNode, boolean>;
}

/**
 * Manages the shared state of the children {@link Decision} elements. The decisions will
 * wait until a choice has been selected by our `PersonalizationService` and then render
 * appropriately based on that choice.
 *
 * The children of this component must satisfy the following constraints:
 * - All children must be {@link Decision} elements, and there must be at least one.
 * - All `Decision` elements must have unique `id` prop, i.e. no duplicate IDs.
 * - There should be a `Decision` element for each possible choice.
 * Failure to meet these constraints will result in the fallback being rendered.
 * @example
 * <DynamicExperience experienceID="testExperience" fallback={<div>Fallback</div>}>
 *  <Decision id="decision-testExperience-1">
 *    {() => <div>Decision 1</div>}
 *  </Decision>
 *  <Decision id="decision-testExperience-2">
 *    {() => <div>Decision 2</div>}
 *  </Decision>
 * </DynamicExperience>
 */
export const DynamicExperience: FC<IDynamicExperienceProps> = ({
  fallback,
  ...restProps
}): ReactNode => {
  const { page } = PageService;

  return (
    <CommonErrorBoundary fallback={fallback}>
      <InternalDynamicExperience page={page} {...restProps} />
    </CommonErrorBoundary>
  );
};
