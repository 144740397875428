'use client';

import { IProduct } from '@/services/models/Product';
import { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import type { RecommendationContext } from '@/configs/recommendations';
import ProductTile from '../../ProductTile';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ISliderProps, Slide, Slider } from '../../../Slider';
import S from './styles.module.scss';
import { product_recommendations_title } from "@/lang/__generated__/ahnu/product_recommendations_title";

export interface IRecommendationsCarouselProps {
  /**
   * The recommendation context to render.
   */
  context: RecommendationContext;
}

/**
 * A slider intended to show product lifestyle images on the PDP.
 */
const DEFAULT_VARIANT = 'cart';
// Taken from design specs.
const DEFAULT_SLIDE_WIDTH = 190;
const DEFAULT_CONTAINER_HEIGHT = 235;
const DEFAULT_MAX_SLIDES_PER_VIEW = 3;
/**
 * Rec carousel.
 */
export const RecommendationsCarousel: FunctionComponent<
  IRecommendationsCarouselProps
> = ({ context }) => {
  const [msg, msgf] = useLocaleMessage();
  const { products, title, isRecommendationEnabled, isSlideOnMobile } =
    useRecommendedProducts(context);

  const containerRef = useRef<HTMLDivElement>(null);
  const key = uuidv4();
  const { width, height } = useWindowSize();

  const sliderProps = {
    direction: 'horizontal',
    loop: true,
    centeredSlides: false,
    slidesPerView: 1,
    navigation: {
      inset: true,
      arrowVariant: 'arrow',
      buttonClassName: S.navigation
    },
    pagination: {
      enabled: false
    },
    height: 'auto'
  } as ISliderProps;
  /**
   * SlidesPerView uses the baseWidth and baseHeight
   * to calculate the number of slides to show. Calculates maximum number
   * of slides given the width of the container.
   * @returns SlidesPerView - number of slides to show in the slider.
   * Should not return number higher than maxSlidesPerView or number of products.
   */
  const slidesPerView = useCallback(() => {
    const [baseWidth, baseHeight] = slideSize();
    if (baseWidth && baseHeight && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = DEFAULT_CONTAINER_HEIGHT;
      const slideWidth = containerHeight * (baseWidth / baseHeight);
      const slidesPerView = containerWidth / slideWidth;
      return Math.min(
        Math.floor(slidesPerView),
        Math.min(DEFAULT_MAX_SLIDES_PER_VIEW, products.length)
      );
    }

    return 1;
  }, [width]);
  /**
   * Generates slides for the slider when slidesPerView changes.
   * @returns Slides - array of slides to be rendered in the slider.
   */
  const slides = useMemo(() => {
    if (products.length) {
      return products?.map((product, index) => {
        const key = `${product.sku}_${index}`;
        return (
          <Slide key={key}>
            <div className={S.slide}>
              <ProductTile product={product} variant={DEFAULT_VARIANT} />
            </div>
          </Slide>
        );
      });
    }
    return null;
  }, [slidesPerView, products]);

  /**
   * Retrieves a property from the first slide in the slider.
   * @param property - The property to retrieve.
   * @returns The value of the property.
   */
  const slideSize = useCallback(() => {
    if (width && containerRef.current) {
      // This is necessary because there is no access to the slider context at this level.
      const slide = containerRef.current?.querySelector(
        `.${S.slide}`
      ) as HTMLDivElement;
      if (slide) {
        const { width, height } = slide.getBoundingClientRect();
        return [width, height];
      }
    }
    // Default to design specs.
    return [DEFAULT_SLIDE_WIDTH, DEFAULT_CONTAINER_HEIGHT];
  }, [width]);

  if (!isRecommendationEnabled) {
    return null;
  }

  return (
    <div ref={containerRef} className={S.container}>
      <div className={S.title}>
        {title ?? msg(product_recommendations_title)}
      </div>
      {width && height && (
        <Slider
          key={`${key}_slider`}
          {...sliderProps}
          slidesPerView={slidesPerView()}
          sliderClassName={S.slider}
          navigationWrapperClassName={S.wrapper}
        >
          {slides}
        </Slider>
      )}
    </div>
  );
};
