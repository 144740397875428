'use client';

import { useCallback, useMemo, useState } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { Button } from '@/react/components/core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '@/react/components/core-ui/Icon';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { EventType } from '@/services/isomorphic/UserInteractionService';

import { Modal } from '@/react/components/core-ui/Modal';
import { ModalHeader } from '@/react/components/core-ui/Modal/ModalHeader';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { IDisplaySizeVariationAttribute } from '@/react/view-models/ProductVM/IDisplayVariationAttribute';
import { SizeChart } from '@/react/components/product/SizeChart';

import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import S from './styles.base.module.scss';
import { product_details_addToCart_addSize } from "@/lang/__generated__/ahnu/product_details_addToCart_addSize";
import { product_details_sizeChart } from "@/lang/__generated__/ahnu/product_details_sizeChart";

export interface ISizeButtonProps {
  /** The size variation attribute. */
  size: IDisplaySizeVariationAttribute;

  /** The product view model being used in the parent size selector. */
  product: IProductVM;
}

/** An individual size button in a {@link SizeSelector Size Selector}. */
export const SizeButton = observer(function SizeButton({
  size,
  product
}: ISizeButtonProps) {
  const {
    type,
    value,
    isSelectable,
    isSelected,
    metadata: { name }
  } = size;

  const variantAvailability =
    product.determineResultingAvailabilityOnAttributeSelection(type, value);

  const isOutOfStockOrUnavailable =
    !isSelected && // Don't show OOS styling if selected
    (variantAvailability === ProductAvailabilityState.OutOfStock ||
      variantAvailability === ProductAvailabilityState.Unavailable);

  return (
    <button
      type="button"
      className={classes(S.size, {
        [S.outOfStock]: isOutOfStockOrUnavailable,
        [S.selected]: isSelected,
        [S.unselectable]: !isSelectable || isOutOfStockOrUnavailable
      })}
      key={value}
      onClick={() => product.selectVariationAttribute(type, value)}
      data-testid="pdpProductSize"
    >
      {name}
    </button>
  );
});

export interface ISizeSelectorProps {
  /** The product to display size options for. */
  product: IProductVM;
}

/** Size selector component for the pdp. */
export const SizeSelector = observer(function SizeSelector({
  product
}: ISizeSelectorProps) {
  const [msg] = useLocaleMessage();
  const sizes = product.getVariationAttributesByType(
    DisplayVariationAttributeType.Size
  );

  const [sizeChartOpen, setSizeChartOpen] = useState(false);

  const handleClose = useCallback(() => {
    setSizeChartOpen(false);
  }, []);

  return (
    <div className={S.container}>
      <div className={S.sizeSelectMsg}>
        {msg(product_details_addToCart_addSize)}
      </div>
      <div className={S.sizes}>
        {sizes.map((size) => (
          <SizeButton key={size.value} size={size} product={product} />
        ))}
      </div>
      <div className={S.sizeRow}>
        <Button
          className={S.sizeChart}
          variant="text"
          onClick={() => setSizeChartOpen(true)}
          interactionDetails={{
            action: EventType.ProductSizeChart
          }}
        >
          <span className={S.ruler}>
            <Icon icon={IconTypes.Ruler} size={IconSizeProp.SizeLG} />
          </span>
          <span>
            {product.sizeChart?.text ?? msg(product_details_sizeChart)}
          </span>
        </Button>

        <div className={S.sizeCallout}>{product.sizeChart?.callout}</div>
      </div>

      {/* Size Chart Modal */}
      <Modal className={S.modal} isOpen={sizeChartOpen} onClose={handleClose}>
        <ModalHeader className={S.modalHeader} onClose={handleClose}>
          <h3>{msg(product_details_sizeChart)}</h3>
        </ModalHeader>
        <div className={S.modalBody}>
          <SizeChart />
        </div>
      </Modal>
    </div>
  );
});
