import I18NService from '@/services/isomorphic/I18NService';
import InventoryService, {
  type UPCToInventoryModelRecord
} from '@/services/isomorphic/InventoryService';
import LoggerService from '@/services/isomorphic/LoggerService';
import {
  InventoryModel,
  ProductAvailabilityState
} from '@/services/models/Inventory';
import type { UPCToInventoryRecord } from '@/services/serverless/ServerInventoryService';
import type { Nullable } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';
import { exhaustiveFallback } from '@/utils/function-utils';
import { action, makeObservable, observable } from 'mobx';

/**
 * A view model that fetches and stores inventory for a group of products.
 */
export default class GroupInventoryVM {
  @observable private _inventoryMap: StaleWhileRevalidate<
    Nullable<UPCToInventoryModelRecord>
  > = new StaleWhileRevalidate(null);

  /**
   * @inheritdoc
   *
   * @param upcList - The list of Product UPC this model will handle inventory
   * for.
   */
  public constructor(
    public readonly upcList: ReadonlyArray<string>,
    staleInventory: Nullable<UPCToInventoryRecord>
  ) {
    if (staleInventory && !this._inventoryMap.value) {
      const staleInventoryModels =
        this.convertStaleInventoryToModels(staleInventory);

      this._inventoryMap = new StaleWhileRevalidate(staleInventoryModels);
    }
    makeObservable(this);
  }

  /**
   * Converts the stale inventory DTOs to InventoryModels while preserving the Record structure.
   * @param staleInventory - The stale inventory DTOs to convert.
   * @returns The stale inventory DTOs converted to InventoryModels inside a sku, model record structure.
   */
  private convertStaleInventoryToModels(
    staleInventory: UPCToInventoryRecord
  ): Record<string, InventoryModel> {
    const staleInventoryModels: Record<string, InventoryModel> = {};

    Object.entries(staleInventory).forEach((entry) => {
      const [sku, inventoryDTO] = entry;
      if (!inventoryDTO) return;

      const model = InventoryModel.from(inventoryDTO);
      staleInventoryModels[sku] = model;
    });

    return staleInventoryModels;
  }

  /** Updates inventory data for all UPCs in the group. */
  @action public updateInventory(): void {
    this._inventoryMap = new StaleWhileRevalidate(
      // The stale value is either the last value (if available), or the
      // synchronously retrieved stale inventory map otherwise.
      this._inventoryMap.value ??
        InventoryService.bulkGetStaleProductInventory(...this.upcList),
      InventoryService.bulkGetProductInventory(...this.upcList)
    );
  }

  /**
   * Given a UPC, synchronously determine its {@link ProductAvailabilityState availability state} using the
   * latest inventory data available.
   *
   * @param upc - The UPC of the product to get availability state for.
   * @returns The availability state, as a member of {@link ProductAvailabilityState}.
   *
   * @throws An {@link InvalidArgumentError} if the provided UPC does not exist
   * in the current group.
   *
   * @throws An {@link InvalidStateError} if inventory status is not available
   * for the current locale.
   */
  public getAvailabilityForUPC(upc: string): ProductAvailabilityState {
    if (!this._inventoryMap.value) {
      return ProductAvailabilityState.Unavailable;
    }

    const inventoryForUPC = this._inventoryMap.value[upc];

    if (!inventoryForUPC) {
      if (this._inventoryMap.pending) {
        return ProductAvailabilityState.Pending;
      }

      // A `null` value indicates there was some error fetching the inventory in bulk.
      if (inventoryForUPC === null) {
        return ProductAvailabilityState.Unknown;
      }

      // An `undefined` value indicates the UPC is not part of this inventory group,
      // and the developer is likely doing something wrong.
      if (inventoryForUPC === undefined) {
        throw new InvalidArgumentError(
          `Cannot get inventory for "${upc}" since it is not part of this` +
            ` inventory group.`
        );
      }

      return exhaustiveFallback(inventoryForUPC, () => {
        LoggerService.error(
          `Unexpected inventory value for UPC "${upc}": "${inventoryForUPC}"`
        );
        return ProductAvailabilityState.Unknown;
      });
    }

    const currentCountry = I18NService.currentLocale.country;

    const inventoryStatusForLocale =
      inventoryForUPC.inventoryStatus[currentCountry];

    if (!inventoryStatusForLocale) {
      throw new InvalidStateError(
        `Cannot get inventory for "${upc}": Inventory status is unavailable` +
          ' for the current locale'
      );
    }

    const { allocation, upcomingAllocation, perpetual } =
      inventoryStatusForLocale;

    if (allocation > 0 || perpetual) {
      return ProductAvailabilityState.InStock;
    }

    if (upcomingAllocation > 0) {
      // TODO: Find how to tell the difference between launched and unlaunched products.
      // Unlaunched products will return Preorder instead.
      return ProductAvailabilityState.Backorder;
    }

    return ProductAvailabilityState.OutOfStock;
  }

  /**
   * Determines if the entire group is either {@link ProductAvailabilityState.OutOfStock out of stock} or {@link ProductAvailabilityState.Unavailable unavailable}.
   *
   * Useful for notifying a customer that all of a product's variants are
   * unavailable for purchase.
   *
   * @returns `true` if all of the UPCs in the group are either {@link ProductAvailabilityState.OutOfStock out of stock} or
   * {@link ProductAvailabilityState.Unavailable unavailable}, and `false` otherwise.
   */
  public isEntireGroupOOSorUnavailable(): boolean {
    for (const upc of this.upcList) {
      const upcAvailability = this.getAvailabilityForUPC(upc);

      if (
        upcAvailability !== ProductAvailabilityState.OutOfStock &&
        upcAvailability !== ProductAvailabilityState.Unavailable
      ) {
        return false;
      }
    }

    return true;
  }
}
