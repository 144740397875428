import { Constructor } from '@/type-utils';
import { mocked } from '@/configs';

import Service from '../../Service';
import MockService, { MockState, ServiceMock } from '../MockService';
import { ISizeChart } from '../../serverless/integrations/AWS/AWSSizeChartService';

import type { SizeChartService } from './SizeChartService';

const initialState = {};

export const mockSizeChart: ISizeChart = {
  women: {
    'size-4.5': {
      jp: '21.5',
      mx: '21.5',
      eu: '35.5',
      uk: '2.5',
      us: '4.5',
      'heel-toe-measurement': '8.5',
      cn: '215'
    },
    'size-5': {
      jp: '22',
      mx: '22',
      eu: '36',
      uk: '3',
      us: '5',
      'heel-toe-measurement': '8.7',
      cn: '220'
    },
    'size-5.5': {
      jp: '22.5',
      mx: '22.5',
      eu: '36.5',
      uk: '3.5',
      us: '5.5',
      'heel-toe-measurement': '8.9',
      cn: '225'
    },
    'size-6': {
      jp: '23',
      mx: '23',
      eu: '37',
      uk: '4',
      us: '6',
      'heel-toe-measurement': '9.1',
      cn: '230'
    },
    'size-6.5': {
      jp: '23.5',
      mx: '23.5',
      eu: '37.5',
      uk: '4.5',
      us: '6.5',
      'heel-toe-measurement': '9.3',
      cn: '235'
    },
    'size-7': {
      jp: '24',
      mx: '24',
      eu: '38',
      uk: '5',
      us: '7',
      'heel-toe-measurement': '9.4',
      cn: '240'
    },
    'size-7.5': {
      jp: '24.5',
      mx: '24.5',
      eu: '38.5',
      uk: '5.5',
      us: '7.5',
      'heel-toe-measurement': '9.6',
      cn: '245'
    },
    'size-8': {
      jp: '25',
      mx: '25',
      eu: '39',
      uk: '6',
      us: '8',
      'heel-toe-measurement': '9.8',
      cn: '250'
    },
    'size-8.5': {
      jp: '25.5',
      mx: '25.5',
      eu: '39.5',
      uk: '6.5',
      us: '8.5',
      'heel-toe-measurement': '10',
      cn: '255'
    },
    'size-9': {
      jp: '26',
      mx: '26',
      eu: '40',
      uk: '7',
      us: '9',
      'heel-toe-measurement': '10.2',
      cn: '260'
    },
    'size-9.5': {
      jp: '26.5',
      mx: '26.5',
      eu: '40.5',
      uk: '7.5',
      us: '9.5',
      'heel-toe-measurement': '10.4',
      cn: '265'
    },
    'size-10': {
      jp: '27',
      mx: '27',
      eu: '41',
      uk: '8',
      us: '10',
      'heel-toe-measurement': '10.6',
      cn: '270'
    },
    'size-10.5': {
      jp: '27.5',
      mx: '27.5',
      eu: '41.5',
      uk: '8.5',
      us: '10.5',
      'heel-toe-measurement': '10.8',
      cn: '275'
    },
    'size-11': {
      jp: '28',
      mx: '28',
      eu: '42',
      uk: '9',
      us: '11',
      'heel-toe-measurement': '11',
      cn: '280'
    },
    'size-11.5': {
      jp: '28.5',
      mx: '28.5',
      eu: '42.5',
      uk: '9.5',
      us: '11.5',
      'heel-toe-measurement': '11.2',
      cn: '285'
    },
    'size-12': {
      jp: '29',
      mx: '29',
      eu: '43',
      uk: '10',
      us: '12',
      'heel-toe-measurement': '11.4',
      cn: '290'
    },
    'size-12.5': {
      jp: '29.5',
      mx: '29.5',
      eu: '43.5',
      uk: '10.5',
      us: '12.5',
      'heel-toe-measurement': '11.6',
      cn: '295'
    },
    'size-13': {
      jp: '30',
      mx: '30',
      eu: '44',
      uk: '11',
      us: '13',
      'heel-toe-measurement': '11.8',
      cn: '300'
    },
    'size-13.5': {
      jp: '30.5',
      mx: '30.5',
      eu: '44.5',
      uk: '11.5',
      us: '13.5',
      'heel-toe-measurement': '12',
      cn: '305'
    },
    'size-14': {
      jp: '31',
      mx: '31',
      eu: '45',
      uk: '12',
      us: '14',
      'heel-toe-measurement': '12.2',
      cn: '310'
    }
  },
  men: {
    'size-3': {
      jp: '21.5',
      mx: '21.5',
      eu: '35.5',
      uk: '2.5',
      us: '3',
      'heel-toe-measurement': '8.5',
      cn: '215'
    },
    'size-3.5': {
      jp: '22',
      mx: '22',
      eu: '36',
      uk: '3',
      us: '3.5',
      'heel-toe-measurement': '8.7',
      cn: '220'
    },
    'size-4': {
      jp: '22.5',
      mx: '22.5',
      eu: '36.5',
      uk: '3.5',
      us: '4',
      'heel-toe-measurement': '8.9',
      cn: '225'
    },
    'size-4.5': {
      jp: '23',
      mx: '23',
      eu: '37',
      uk: '4',
      us: '4.5',
      'heel-toe-measurement': '9.1',
      cn: '230'
    },
    'size-5': {
      jp: '23.5',
      mx: '23.5',
      eu: '37.5',
      uk: '4.5',
      us: '5',
      'heel-toe-measurement': '9.3',
      cn: '235'
    },
    'size-5.5': {
      jp: '24',
      mx: '24',
      eu: '38',
      uk: '5',
      us: '5.5',
      'heel-toe-measurement': '9.4',
      cn: '240'
    },
    'size-6': {
      jp: '24.5',
      mx: '24.5',
      eu: '39.5',
      uk: '5.5',
      us: '6',
      'heel-toe-measurement': '9.6',
      cn: '245'
    },
    'size-6.5': {
      jp: '25',
      mx: '25',
      eu: '40',
      uk: '6',
      us: '6.5',
      'heel-toe-measurement': '9.8',
      cn: '250'
    },
    'size-7': {
      jp: '25.5',
      mx: '25.5',
      eu: '40.5',
      uk: '6.5',
      us: '7',
      'heel-toe-measurement': '10',
      cn: '255'
    },
    'size-7.5': {
      jp: '26',
      mx: '26',
      eu: '41',
      uk: '7',
      us: '7.5',
      'heel-toe-measurement': '10.2',
      cn: '260'
    },
    'size-8': {
      jp: '26.5',
      mx: '26.5',
      eu: '40.5',
      uk: '7.5',
      us: '8',
      'heel-toe-measurement': '10.4',
      cn: '265'
    },
    'size-8.5': {
      jp: '27',
      mx: '27',
      eu: '41',
      uk: '8',
      us: '8.5',
      'heel-toe-measurement': '10.6',
      cn: '270'
    },
    'size-9': {
      jp: '27.5',
      mx: '27.5',
      eu: '41.5',
      uk: '8.5',
      us: '9',
      'heel-toe-measurement': '10.8',
      cn: '275'
    },
    'size-9.5': {
      jp: '28',
      mx: '28',
      eu: '42',
      uk: '8.5',
      us: '9.5',
      'heel-toe-measurement': '11',
      cn: '280'
    },
    'size-10': {
      jp: '28.5',
      mx: '28.5',
      eu: '42.5',
      uk: '9.5',
      us: '10',
      'heel-toe-measurement': '11.2',
      cn: '285'
    },
    'size-10.5': {
      jp: '29',
      mx: '29',
      eu: '43',
      uk: '10',
      us: '10.5',
      'heel-toe-measurement': '11.4',
      cn: '290'
    },
    'size-11': {
      jp: '29.5',
      mx: '29.5',
      eu: '43.5',
      uk: '10.5',
      us: '11',
      'heel-toe-measurement': '11.6',
      cn: '295'
    },
    'size-11.5': {
      jp: '30',
      mx: '30',
      eu: '44',
      uk: '10',
      us: '11.5',
      'heel-toe-measurement': '11.8',
      cn: '300'
    },
    'size-12': {
      jp: '31',
      mx: '31',
      eu: '44.5',
      uk: '11.5',
      us: '12',
      'heel-toe-measurement': '12',
      cn: '305'
    },
    'size-12.5': {
      jp: '30.5',
      mx: '30.5',
      eu: '45',
      uk: '12',
      us: '12.5',
      'heel-toe-measurement': '12.2',
      cn: '310'
    },
    'size-13': {
      jp: '31.5',
      mx: '31.5',
      eu: '45.5',
      uk: '12.5',
      us: '13',
      'heel-toe-measurement': '12.4',
      cn: '315'
    },
    'size-13.5': {
      jp: '32',
      mx: '32',
      eu: '46',
      uk: '13',
      us: '13.5',
      'heel-toe-measurement': '12.6',
      cn: '320'
    },
    'size-14': {
      jp: '32.5',
      mx: '32.5',
      eu: '46.5',
      uk: '13.5',
      us: '14',
      'heel-toe-measurement': '12.8',
      cn: '325'
    },
    'size-14.5': {
      jp: '33',
      mx: '33',
      eu: '47',
      uk: '14',
      us: '14.5',
      'heel-toe-measurement': '13',
      cn: '330'
    },
    'size-15': {
      jp: '33.5',
      mx: '33.5',
      eu: '47.5',
      uk: '14.5',
      us: '15',
      'heel-toe-measurement': '13.2',
      cn: '335'
    },
    'size-15.5': {
      jp: '34',
      mx: '34',
      eu: '48',
      uk: '15',
      us: '15.5',
      'heel-toe-measurement': '13.4',
      cn: '340'
    }
  },
  unisex: {
    'size-3': {
      jp: '21',
      mx: '21',
      eu: '34',
      uk: '2.5',
      us: 'M3 / W4.5',
      'heel-toe-measurement': '8.5',
      cn: '210'
    },
    'size-3.5': {
      jp: '21.5',
      mx: '21.5',
      eu: '34.5',
      uk: '3',
      us: 'M3.5 / W5',
      'heel-toe-measurement': '8.7',
      cn: '215'
    },
    'size-4': {
      jp: '22',
      mx: '22',
      eu: '35',
      uk: '3.5',
      us: 'M4 / W5.5',
      'heel-toe-measurement': '8.9',
      cn: '220'
    },
    'size-4.5': {
      jp: '22.5',
      mx: '22.5',
      eu: '36',
      uk: '4',
      us: 'M4.5 / W6',
      'heel-toe-measurement': '9.1',
      cn: '225'
    },
    'size-5': {
      jp: '23',
      mx: '23',
      eu: '36.5',
      uk: '4.5',
      us: 'M5 / W6.5',
      'heel-toe-measurement': '9.3',
      cn: '230'
    },
    'size-5.5': {
      jp: '23.5',
      mx: '23.5',
      eu: '37.5',
      uk: '5',
      us: 'M5.5 / W7',
      'heel-toe-measurement': '9.4',
      cn: '235'
    },
    'size-6': {
      jp: '24',
      mx: '24',
      eu: '38',
      uk: '5',
      us: 'M6 / W7.5',
      'heel-toe-measurement': '9.6',
      cn: '240'
    },
    'size-6.5': {
      jp: '24.5',
      mx: '24.5',
      eu: '38.5',
      uk: '5.5',
      us: 'M6.5 / W8',
      'heel-toe-measurement': '9.8',
      cn: '245'
    },
    'size-7': {
      jp: '25',
      mx: '25',
      eu: '39',
      uk: '6',
      us: 'M7 / W8.5',
      'heel-toe-measurement': '10',
      cn: '250'
    },
    'size-7.5': {
      jp: '25.5',
      mx: '25.5',
      eu: '40',
      uk: '6.5',
      us: 'M7.5 / W9',
      'heel-toe-measurement': '10.2',
      cn: '255'
    },
    'size-8': {
      jp: '26',
      mx: '26',
      eu: '40.5',
      uk: '7',
      us: 'M8 / W9.5',
      'heel-toe-measurement': '10.4',
      cn: '260'
    },
    'size-8.5': {
      jp: '26.5',
      mx: '26.5',
      eu: '41.5',
      uk: '7.5',
      us: 'M8.5 / W10',
      'heel-toe-measurement': '10.6',
      cn: '265'
    },
    'size-9': {
      jp: '27',
      mx: '27',
      eu: '42',
      uk: '8',
      us: 'M9 / W10.5',
      'heel-toe-measurement': '10.8',
      cn: '270'
    },
    'size-9.5': {
      jp: '27.5',
      mx: '27.5',
      eu: '42.5',
      uk: '8.5',
      us: 'M9.5 / W11',
      'heel-toe-measurement': '11',
      cn: '275'
    },
    'size-10': {
      jp: '28',
      mx: '28',
      eu: '43',
      uk: '9',
      us: 'M10 / W11.5',
      'heel-toe-measurement': '11.2',
      cn: '280'
    },
    'size-10.5': {
      jp: '28.5',
      mx: '28.5',
      eu: '44',
      uk: '9.5',
      us: 'M10.5 / W12',
      'heel-toe-measurement': '11.4',
      cn: '285'
    },
    'size-11': {
      jp: '29',
      mx: '29',
      eu: '44.5',
      uk: '10',
      us: 'M11 / W12.5',
      'heel-toe-measurement': '11.6',
      cn: '290'
    },
    'size-11.5': {
      jp: '29.5',
      mx: '29.5',
      eu: '45',
      uk: '10.5',
      us: 'M11.5 / W13',
      'heel-toe-measurement': '11.8',
      cn: '295'
    },
    'size-12': {
      jp: '30',
      mx: '30',
      eu: '45.5',
      uk: '11',
      us: 'M12 / W13.5',
      'heel-toe-measurement': '12',
      cn: '300'
    },
    'size-12.5': {
      jp: '30.5',
      mx: '30.5',
      eu: '46.5',
      uk: '11.5',
      us: 'M12.5 / W14',
      'heel-toe-measurement': '12.2',
      cn: '305'
    },
    'size-13': {
      jp: '31',
      mx: '31',
      eu: '47',
      uk: '12',
      us: 'M13 / W14.5',
      'heel-toe-measurement': '12.4',
      cn: '310'
    },
    'size-13.5': {
      jp: '31.5',
      mx: '31.5',
      eu: '47.5',
      uk: '12.5',
      us: 'M13.5 / W15',
      'heel-toe-measurement': '12.6',
      cn: '315'
    },
    'size-14': {
      jp: '32',
      mx: '32',
      eu: '48.5',
      uk: '13',
      us: 'M14 / W15.5',
      'heel-toe-measurement': '12.8',
      cn: '320'
    },
    'size-14.5': {
      jp: '32.5',
      mx: '32.5',
      eu: '49',
      uk: '13.5',
      us: 'M14.5 / W16',
      'heel-toe-measurement': '13',
      cn: '325'
    },
    'size-15': {
      jp: '33',
      mx: '33',
      eu: '49.5',
      uk: '14',
      us: 'M15 / W16.5',
      'heel-toe-measurement': '13.2',
      cn: '330'
    },
    'size-16': {
      jp: '33.5',
      mx: '33.5',
      eu: '50.5',
      uk: '15',
      us: 'M16 / W17.5',
      'heel-toe-measurement': '13.4',
      cn: '335'
    },
    'size-17': {
      jp: '34',
      mx: '34',
      eu: '51',
      uk: '16',
      us: 'M17 / W18.5',
      'heel-toe-measurement': '13.6',
      cn: '340'
    },
    'size-18': {
      jp: '34.5',
      mx: '34.5',
      eu: '52',
      uk: '16.5',
      us: 'M18 / W19.5',
      'heel-toe-measurement': '13.8',
      cn: '345'
    }
  },
  infants: {
    'size-1': {
      jp: '9',
      mx: '9',
      eu: '16',
      uk: '0.5',
      us: '0/1',
      'heel-toe-measurement': '3.2-3.5',
      cn: '90'
    },
    'size-2': {
      jp: '11',
      mx: '11',
      eu: '18',
      uk: '2',
      us: '02/03',
      cn: '110',
      'heel-toe-measurement': '3.8-4.1'
    },
    'size-3': {
      jp: '12.5',
      mx: '12.5',
      eu: '20.5',
      uk: '4',
      us: '04/05',
      cn: '125',
      'heel-toe-measurement': '4.5-4.8'
    }
  },
  toddlers: {
    'size-4': {
      jp: '11.5',
      mx: '11.5',
      eu: '19',
      uk: '3',
      us: '4',
      cn: '115',
      'heel-toe-measurement': '4.5'
    },
    'size-5': {
      jp: '12',
      mx: '12',
      eu: '20',
      uk: '4',
      us: '5',
      cn: '120',
      'heel-toe-measurement': '4.7'
    },
    'size-6': {
      jp: '13',
      mx: '13',
      eu: '21',
      uk: '5',
      us: '6',
      cn: '130',
      'heel-toe-measurement': '5.1'
    },
    'size-7': {
      jp: '14',
      mx: '14',
      eu: '22/23',
      uk: '6',
      us: '7',
      cn: '140',
      'heel-toe-measurement': '5.5'
    },
    'size-8': {
      jp: '15',
      mx: '15',
      eu: '24/25',
      uk: '7',
      us: '8',
      cn: '150',
      'heel-toe-measurement': '5.8'
    },
    'size-8.5': {
      jp: '15.5',
      mx: '15.5',
      eu: '25.5',
      uk: '7.5',
      us: '8.5',
      cn: '155',
      'heel-toe-measurement': '6'
    },
    'size-9': {
      jp: '16',
      mx: '16',
      eu: '26',
      uk: '8',
      us: '9',
      cn: '160',
      'heel-toe-measurement': '6.1'
    },
    'size-9.5': {
      jp: '16.5',
      mx: '16.5',
      eu: '26.5',
      uk: '8.5',
      us: '9.5',
      cn: '165',
      'heel-toe-measurement': '6.3'
    },
    'size-10': {
      jp: '17',
      mx: '17',
      eu: '27',
      uk: '9',
      us: '10',
      cn: '170',
      'heel-toe-measurement': '6.5'
    },
    'size-10.5': {
      jp: '17.5',
      mx: '17.5',
      eu: '27.5',
      uk: '9.5',
      us: '10.5',
      cn: '175',
      'heel-toe-measurement': '6.6'
    }
  },
  littleKids: {
    'size-1': {
      jp: '18',
      mx: '18',
      eu: '28',
      uk: '10',
      us: '11',
      cn: '180',
      'heel-toe-measurement': '6.8'
    },
    'size-2': {
      jp: '18.5',
      mx: '18.5',
      eu: '28.5',
      uk: '10.5',
      us: '11.5',
      cn: '185',
      'heel-toe-measurement': '7'
    },
    'size-3': {
      jp: '19',
      mx: '19',
      eu: '29/30',
      uk: '11',
      us: '12',
      cn: '190',
      'heel-toe-measurement': '7.1'
    },
    'size-4': {
      jp: '19.2',
      mx: '19.2',
      eu: '30.5',
      uk: '11.5',
      us: '12.5',
      cn: '192',
      'heel-toe-measurement': '7.2'
    },
    'size-5': {
      jp: '19.5',
      mx: '19.5',
      eu: '31',
      uk: '12',
      us: '13',
      cn: '195',
      'heel-toe-measurement': '7.5'
    },
    'size-6': {
      jp: '19.7',
      mx: '19.7',
      eu: '31.5',
      uk: '12.5',
      us: '13.5',
      cn: '197',
      'heel-toe-measurement': '7.6'
    },
    'size-7': {
      jp: '20',
      mx: '20',
      eu: '31',
      uk: '13',
      us: '1',
      cn: '200',
      'heel-toe-measurement': '7.8'
    },
    'size-8': {
      jp: '20.5',
      mx: '20.5',
      eu: '32.5',
      uk: '13.5',
      us: '1.5',
      cn: '205',
      'heel-toe-measurement': '8'
    },
    'size-9': {
      jp: '21',
      mx: '21',
      eu: '33/34',
      uk: '1',
      us: '2',
      cn: '210',
      'heel-toe-measurement': '8.1'
    },
    'size-10': {
      jp: '21.5',
      mx: '21.5',
      eu: '34.5',
      uk: '1.5',
      us: '2.5',
      cn: '215',
      'heel-toe-measurement': '8.25'
    },
    'size-11': {
      jp: '22',
      mx: '22',
      eu: '35',
      uk: '2',
      us: '3',
      cn: '220',
      'heel-toe-measurement': '8.5'
    },
    'size-12': {
      jp: '22.2',
      mx: '22.2',
      eu: '35.5',
      uk: '2.5',
      us: '3.5',
      cn: '222',
      'heel-toe-measurement': '8.6'
    }
  },
  bigKids: {
    'size-1': {
      jp: '23',
      mx: '23',
      eu: '36',
      uk: '3',
      us: '4',
      cn: '230',
      'heel-toe-measurement': '8.8'
    },
    'size-2': {
      jp: '23.2',
      mx: '23.2',
      eu: '36.5',
      uk: '3.5',
      us: '4.5',
      cn: '232',
      'heel-toe-measurement': '9'
    },
    'size-3': {
      jp: '23.5',
      mx: '23.5',
      eu: '37',
      uk: '4',
      us: '5',
      cn: '235',
      'heel-toe-measurement': '9.1'
    },
    'size-4': {
      jp: '24',
      mx: '24',
      eu: '37.5',
      uk: '4.5',
      us: '5.5',
      cn: '240',
      'heel-toe-measurement': '9.3'
    },
    'size-5': {
      jp: '24.5',
      mx: '24.5',
      eu: '38/39',
      uk: '5',
      us: '6',
      cn: '245',
      'heel-toe-measurement': '9.5'
    },
    'size-6': {
      jp: '25',
      mx: '25',
      eu: '39.5',
      uk: '5.5',
      us: '6.5',
      cn: '250',
      'heel-toe-measurement': '9.6'
    },
    'size-7': {
      jp: '25.5',
      mx: '25.5',
      eu: '40',
      uk: '6',
      us: '7',
      cn: '255',
      'heel-toe-measurement': '9.8'
    }
  },
  kids: {
    'size-1': {
      jp: '26',
      mx: '26',
      eu: '41',
      uk: '7',
      us: '8',
      cn: '260',
      'heel-toe-measurement': '10'
    },
    'size-2': {
      jp: '27',
      mx: '27',
      eu: '42',
      uk: '8',
      us: '9',
      cn: '270',
      'heel-toe-measurement': '10.2'
    },
    'size-3': {
      jp: '28',
      mx: '28',
      eu: '43',
      uk: '9',
      us: '10',
      cn: '280',
      'heel-toe-measurement': '10.4'
    },
    'size-4': {
      jp: '29',
      mx: '29',
      eu: '44',
      uk: '10',
      us: '11',
      cn: '290',
      'heel-toe-measurement': '10.6'
    },
    'size-5': {
      jp: '30',
      mx: '30',
      eu: '45',
      uk: '11',
      us: '12',
      cn: '300',
      'heel-toe-measurement': '10.8'
    },
    'size-6': {
      jp: '31',
      mx: '31',
      eu: '46',
      uk: '12',
      us: '13',
      cn: '310',
      'heel-toe-measurement': '11'
    },
    'size-7': {
      jp: '32',
      mx: '32',
      eu: '47',
      uk: '13',
      us: '1',
      cn: '320',
      'heel-toe-measurement': '11.2'
    },
    'size-8': {
      jp: '33',
      mx: '33',
      eu: '48',
      uk: '1',
      us: '2',
      cn: '330',
      'heel-toe-measurement': '11.4'
    },
    'size-9': {
      jp: '34',
      mx: '34',
      eu: '49',
      uk: '2',
      us: '3',
      cn: '340',
      'heel-toe-measurement': '11.6'
    }
  },
  youth: {
    'size-4': {
      jp: '22',
      mx: '22',
      eu: '36',
      uk: '3',
      us: '4',
      cn: '220',
      'heel-toe-measurement': '8.8'
    },
    'size-4.5': {
      jp: '22.5',
      mx: '22.5',
      eu: '36.5',
      uk: '3.5',
      us: '4.5',
      cn: '225',
      'heel-toe-measurement': '9'
    },
    'size-5': {
      jp: '23',
      mx: '23',
      eu: '37',
      uk: '4',
      us: '5',
      cn: '230',
      'heel-toe-measurement': '9.1'
    },
    'size-5.5': {
      jp: '23.5',
      mx: '23.5',
      eu: '37.5',
      uk: '4.5',
      us: '5.5',
      cn: '235',
      'heel-toe-measurement': '9.3'
    },
    'size-6': {
      jp: '24',
      mx: '24',
      eu: '38',
      uk: '5',
      us: '6',
      cn: '240',
      'heel-toe-measurement': '9.5'
    },
    'size-6.5': {
      jp: '24.5',
      mx: '24.5',
      eu: '39',
      uk: '5.5',
      us: '6.5',
      cn: '245',
      'heel-toe-measurement': '9.6'
    },
    'size-7': {
      jp: '25',
      mx: '25',
      eu: '40',
      uk: '6',
      us: '7',
      cn: '250',
      'heel-toe-measurement': '9.8'
    },
    'size-7.5': {
      jp: '25.5',
      mx: '25.5',
      eu: '40.5',
      uk: '6.5',
      us: '7.5',
      cn: '255',
      'heel-toe-measurement': '10'
    }
  }
};

/** Mock implementation of the {@link SizeChartService}. */
export default class SizeChartServiceMock extends ServiceMock<SizeChartService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): SizeChartService {
    return MockService.getMockOf(this.service) as unknown as SizeChartService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<SizeChartService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.SizeChartService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getSizeChart: async (): Promise<ISizeChart> => {
          return mockSizeChart;
        }
      },
      {},
      this.state
    );
  }
}
