import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '@/utils/error-utils';

export const { UnableToParseModelError } = errorFactory(
  'UnableToParseModelError'
);

/** Error to use to indicate that a Model could not be parsed. */
export type UnableToParseModelError = InstanceTypeOf<
  typeof UnableToParseModelError
>;
