import CartService from '@/services/isomorphic/CartService';
import { useEffect, useState } from 'react';
import CartVM from '../view-models/CartVM';
import type { IProductVM } from '../view-models/ProductVM';
import { usePending } from './usePending';

/**
 * A hook that manages a temporary cart for a single product to be purchased.
 * @param productVM - The product view model backing the product to be purchased.
 * @returns A tuple containing the ad-hoc cart VM and a boolean indicating if cart updates are pending.
 */
export function useSingleProductCart(productVM: IProductVM): [CartVM, boolean] {
  const cart = useInitializeAdHocCart();
  const isPending = useSyncCurrentProductWithCart(productVM, cart);

  return [cart, isPending];
}

/**
 * A hook which initializes an ad-hoc cart view model.
 * @returns A cart view model.
 */
function useInitializeAdHocCart(): CartVM {
  /**
   * **Implementation Note**: Although the initial cart in state is "{@link CartVM.isReady not ready}",
   * we use this cart as a placeholder to show the dependent UI as soon as possible,
   * even if it can't be interacted with yet.
   *
   * The effect below is responsible for initializing the ad-hoc cart model, and updating the
   * state with a "ready" cart. **Note**: It's not possible to defer ad-hoc cart creation to
   * after the button is clicked because some APIs (such as Apple Pay) require that the event
   * handler logic executes synchronously. As a result, the cart must be ready _before_ the
   * button is clicked.
   */
  const [cart, setCart] = useState<CartVM>(() => new CartVM());

  useEffect(function initializeAdHocCart() {
    let isStale = false;
    const fetchAdHocCart = async (): Promise<void> => {
      const cartModel = await CartService.getNewAdHocCart();

      if (isStale) return;
      setCart(new CartVM(cartModel));
    };

    fetchAdHocCart();

    return () => {
      isStale = true;
      /**
       * According to the {@link https://docs.talon.one/docs/dev/concepts/entities/customer-sessions Talon.One docs}, it is safe to leave a session
       * open indefinitely, such as when the customer never proceeds to
       * checkout. Therefore, we don't need to clean up the cart session.
       */
    };
  }, []);

  return cart;
}

/**
 * A hook that manages a single item cart.
 * @param productVM - The product view model backing the product to be purchased.
 * @param cart - A cart to be synchronized with the current product.
 * @returns A boolean indicating if cart updates are pending.
 */
function useSyncCurrentProductWithCart(
  productVM: IProductVM,
  cart: CartVM
): boolean {
  const [isPending, addPending] = usePending();

  const { isReady } = cart;
  const { isPurchasable, currentProduct } = productVM;

  useEffect(
    function syncCurrentProductWithAdHocCart() {
      if (!isReady) return;

      if (!isPurchasable) {
        if (cart.items.length > 0) {
          addPending(cart.removeItem(cart.items[0]));
        }
        return;
      }

      /**
       * If the ad-hoc cart is empty, add the product to the cart.
       * Otherwise, replace the existing product in the cart with the new product.
       *
       * Although "replace" doesn't have the ability to update the quantity of the product
       * (to assert that the quantity is 1), this is a safe operation because we make sure
       * to only add a single product in the beginning.
       */
      if (cart.items.length === 0) {
        addPending(cart.addItem(currentProduct, 1));
      } else {
        addPending(cart.replaceLineItem(cart.items[0], currentProduct));
      }
    },
    [isReady, isPurchasable, cart, addPending, currentProduct]
  );

  return isPending;
}
