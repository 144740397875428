import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

import {
  ClientOnlyCodeInServerError,
  ServerCodeInClientError
} from '@/utils/errors';

/**
 * A [method decorator](https://www.typescriptlang.org/docs/handbook/decorators.html#method-decorators)
 * that will throw when its target is executed in a non-client environment.
 *
 * @param target - The target method's prototype.
 * @param propertyKey - The target method's name.
 * @param descriptor - The target method's descriptor.
 *
 * @throws A {@link ClientOnlyCodeInServerError} when executed in a non-client environment.
 *
 * Will **not** throw when building to prevent SSG errors.
 *
 * **NOTE:** Members that use TS decorators cannot be tree shaken by Webpack.
 * This means that methods decorated with `clientOnlyMethod` will _not_ be eliminated
 * from the server's code. However, a static optimization is planned for this.
 * See [HP-1996](https://deckersbrand.atlassian.net/browse/HP-1996).
 *
 * In the meantime, if you want a tree-shakable alternative you can use a simple conditional:
 *
 * ```ts
 *
 * if (!EnvironmentService.inBrowser) {
 *    // Throw or return
 * }
 *
 * ```
 * .
 */
export function clientOnlyMethod(
  target: unknown,
  propertyKey: string,
  descriptor: PropertyDescriptor
): void {
  if (!(typeof window !== "undefined") && !EnvironmentService.isBuilding) {
    descriptor.value = () => {
      throw new ClientOnlyCodeInServerError(
        `The use of '${propertyKey}' is not allowed in non-client environments.`
      );
    };
  }
}

/**
 * A [method decorator](https://www.typescriptlang.org/docs/handbook/decorators.html#method-decorators)
 * that will throw when its target is executed in a non-server environment.
 *
 * @param target - The target method's prototype.
 * @param propertyKey - The target method's name.
 * @param descriptor - The target method's descriptor.
 *
 * @throws A {@link ServerCodeInClientError} when executed in a non-server environment.
 *
 * Will **not** throw when building to prevent SSG errors.
 *
 * **NOTE:**
 *
 * Members that use TS decorators cannot be tree shaken by Webpack.
 * This means that methods decorated with `serverOnlyMethod` will _not_ be eliminated
 * from the client's code. However, a static optimization is planned for this.
 * See [HP-1996](https://deckersbrand.atlassian.net/browse/HP-1996).
 *
 * In the meantime, if you want a tree-shakable alternative you can use a simple conditional:
 *
 * ```ts
 *
 * if (!EnvironmentService.onServer) {
 *    // Throw or return
 * }
 *
 * ```
 * .
 */
export function serverOnlyMethod(
  target: unknown,
  propertyKey: string,
  descriptor: PropertyDescriptor
): void {
  if (!(typeof window === "undefined") && !EnvironmentService.isBuilding) {
    descriptor.value = () => {
      throw new ServerCodeInClientError(
        `The use of '${propertyKey}' is not allowed in non-server environments.`
      );
    };
  }
}
