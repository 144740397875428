'use client';

import { useContext } from 'react';

import NotificationContext from './controller/NotificationContext';
import { INotification } from './types';

import type { IBasicNotification, ICustomNotification } from './types';

/**
 * Provides a function that can display notifications when called with valid notification objects
 * such as {@link IBasicNotification} or {@link ICustomNotification}.
 *
 * @returns The `queueNotification` function.
 *
 * Check out the stories and docs in Storybook at `Components > Utility > Notifications`
 * for detailed documentation and examples.
 */
const useNotification = (): ((notification: INotification) => void) => {
  // This hook is actually a thin wrapper that extracts only the `queueNotification` function
  // from the NotificationContext.
  const { queueNotification } = useContext(NotificationContext);

  return queueNotification;
};

export default useNotification;
