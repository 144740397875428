'use client';

import { observer } from 'mobx-react-lite';
import { useId, useMemo } from 'react';

import { IProduct } from '@/services/models/Product';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { usePending } from '@/react/hooks/usePending';
import { useProduct } from '@/react/hooks/useProduct';
import type CartVM from '@/react/view-models/CartVM';
import { MoneyModel, type IMoney } from '@/services/models/Money';
import type { MaybePromise } from '@/type-utils';
import { Button } from '../../core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '../../core-ui/Icon';
import { Image } from '../../core-ui/Image';
import { Select, type ISelectOption } from '../../core-ui/Select';
import { Spinner } from '../../core-ui/Spinner';
import { useChangeCartLineQuantity } from './hooks/useChangeCartLineQuantity';
import { useRemoveFromCart } from './hooks/useRemoveFromCart';
import { LineItemDetails } from './LineItemDetails';
import {
  LineItemPromotions,
  type ILineItemPromotionSummary
} from './LineItemPromotions';
import { LineItemTotal } from './LineItemTotal';
import S from './styles.module.scss';
import { cart_cartItem_each } from "@/lang/__generated__/ahnu/cart_cartItem_each";
import { cart_cartItem_quantity } from "@/lang/__generated__/ahnu/cart_cartItem_quantity";
import { general_total } from "@/lang/__generated__/ahnu/general_total";
import { cart_cartItem_remove } from "@/lang/__generated__/ahnu/cart_cartItem_remove";
import { cart_cartItem_addToWishList } from "@/lang/__generated__/ahnu/cart_cartItem_addToWishList";

/** A summary of a line item in the cart. */
export interface ILineItemSummary {
  /** The name of the product. */
  name: string;
  /** The quantity of the product in the cart. */
  quantity: number;
  /** The unique identifier of the product. */
  uuid: string;
  /** The SKU of the product. */
  sku: string;
  /** The promotions applied to the product. */
  promotions: ReadonlyArray<ILineItemPromotionSummary>;
  /** The price of the product after promotions. */
  netTotal: IMoney;
  /** The original price of the product, i.e. before promotions. */
  subtotal: IMoney;
}

/**
 * A set of properties for customizing the quantity
 * selector in the `LineItemSummary` component.
 */
export interface IQuantityControls {
  /** The maximum quantity that can be selected. */
  maxQuantity: number;
  /**
   * A callback that will be executed when the selected quantity is changed.
   * @param uuid - The unique identifier of the product.
   * @param quantity - The new quantity selected.
   * @returns A `MaybePromise` that resolves when the quantity has been updated.
   */
  onQuantityChange: (uuid: string, quantity: number) => MaybePromise<void>;
}

/** Props for the {@link LineItemSummary} component. */
export interface ILineItemSummaryProps {
  /** A summary of the line item to display. */
  line: ILineItemSummary;
  /**
   * Controls the layout of the component displaying the content in column
   * or row style. Row style displays each ProductLineItem in it's own row
   * i.e. the Cart page. The column layout is used to stack ProductLineItems
   * in a single column, i.e. the Order Summary card in checkout.
   * @default 'row'
   */
  direction?: 'column' | 'row';
  /**
   * The style of the product line item.
   * Simple option is displayed without cart components, used in
   * the AddToCartModal.
   * @default 'default'
   */
  variant?: 'default' | 'preview' | 'simple';
  /**
   * Callback that will be executed when the `Edit Details` link is
   * clicked on this line item. If omitted, the link will not be displayed.
   * @param product - The product corresponding to the line item.
   */
  onClickEdit?: (product: IProduct) => void;
  /**
   * An object for customizing the quantity selector in the component.
   * If omitted, the quantity selector will not be displayed.
   */
  quantityControls?: IQuantityControls | undefined;
  /**
   * Callback that will be executed when the `Remove` link is clicked.
   * If omitted, the link will not be displayed.
   */
  onClickRemove?: ((uuid: string) => MaybePromise<void>) | undefined;
  /**
   * Controls the visibility of the action buttons in the component.
   * If `false`, options such as `Edit` and `Remove` will not be displayed.
   * @default true
   *
   * **Note**: `quantityControls` and `onClickRemove` must still be provided
   * for the respective actions to be displayed.
   */
  showActions?: boolean;

  /**
   * Additional styling options for the component.
   * @example 'remove-padded-border' - Removes the border and side padding.
   */
  styleVariant?: 'default' | 'remove-padded-border';
}

/** Displays a summary of a line item. */
export const LineItemSummary = observer(function LineItemSummary({
  line,
  direction = 'row',
  variant = 'default',
  styleVariant = 'default',
  onClickEdit,
  quantityControls,
  onClickRemove,
  showActions = true
}: ILineItemSummaryProps) {
  const { name, quantity, uuid, sku, promotions } = line;

  const maxQuantity = quantityControls?.maxQuantity;
  const onQuantityChange = quantityControls?.onQuantityChange;

  const productQuantityId = useId();

  const product = useProduct(sku);
  const image = product?.primaryImage;

  const [isPending, addPending] = usePending();

  const isWishlistEnabled =
    ConfigurationService.getConfig('product').getSetting(
      'wishlist.enable'
    ).value;

  const quantityOptions: Array<ISelectOption> | null = useMemo(
    () =>
      maxQuantity
        ? Array.from({ length: maxQuantity }, (_, i) => ({
            value: i + 1
          }))
        : null,
    [maxQuantity]
  );

  return (
    <article
      className={classes(S.container, {
        [S.productRow]: direction === 'row',
        [S.productColumn]: direction !== 'row',
        [S.simple]: variant === 'simple',
        [S.removePaddedBorder]: styleVariant === 'remove-padded-border'
      })}
    >
      <div className={S.name}>{name}</div>

      <div className={S.product}>
        <div className={S.productDetails}>
          {image && (
            <div className={S.productImg}>
              <Image image={image} fill />
            </div>
          )}
          <LineItemDetails
            product={product}
            variant={variant}
            onClickEdit={onClickEdit}
            showActions={showActions}
          />
        </div>
        {variant !== 'simple' && (
          <div className={S.productOptions}>
            <div className={S.appliedPromotions}>
              <LineItemPromotions promotions={promotions} />
            </div>
            <div className={S.options}>
              <div className={S.price}>
                <span>{msg(cart_cartItem_each)}</span>
                <LineItemTotal
                  currentTotal={MoneyModel.divide(line.netTotal, quantity)}
                  originalTotal={MoneyModel.divide(line.subtotal, quantity)}
                />
              </div>
              <div className={S.price}>
                {variant === 'default' &&
                quantityOptions &&
                onQuantityChange ? (
                  <Select
                    isOptional
                    id={productQuantityId}
                    className={S.quantitySelector}
                    label={msg(cart_cartItem_quantity)}
                    options={quantityOptions}
                    value={quantity}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      addPending(onQuantityChange(uuid, value));
                    }}
                  />
                ) : (
                  <>
                    <span>{msg(cart_cartItem_quantity)}</span>
                    <span className={S.priceValue}>{quantity}</span>
                  </>
                )}
              </div>

              <div className={S.price}>
                <span className={S.alignRight}>{msg(general_total)}</span>
                <LineItemTotal
                  currentTotal={line.netTotal}
                  originalTotal={line.subtotal}
                />
              </div>
            </div>

            {showActions && (onClickRemove || isWishlistEnabled) && (
              <div className={S.actions}>
                {onClickRemove && (
                  <Button
                    className={S.remove}
                    variant="text"
                    onClick={() => {
                      addPending(onClickRemove(uuid));
                    }}
                  >
                    <Icon icon={IconTypes.Trash} size={IconSizeProp.SizeLG} />
                    {msg(cart_cartItem_remove)}
                  </Button>
                )}
                {isWishlistEnabled && (
                  <Button variant="text">
                    <Icon icon={IconTypes.Heart} />
                    {msg(cart_cartItem_addToWishList)}
                  </Button>
                )}
              </div>
            )}
            {isPending && <Spinner size="1em" className={S.spinner} />}
          </div>
        )}
      </div>
    </article>
  );
});

/** Props for the {@link LineItemSummaryList} component. */
export type ILineItemSummaryListProps = Omit<ILineItemSummaryProps, 'line'> & {
  lines: ReadonlyArray<ILineItemSummary>;
};

/** Displays a list of line item summaries. */
export const LineItemSummaryList = observer(function LineItemSummaryList({
  lines,
  ...rest
}: ILineItemSummaryListProps) {
  return lines.map((line) => (
    <LineItemSummary key={line.uuid} line={line} {...rest} />
  ));
});

/** Props for the {@link CartLineItemSummaryList} component. */
export type ICartLineItemSummaryListProps = Omit<
  ILineItemSummaryProps,
  'line' | 'quantityControls' | 'onClickRemove'
> & {
  cart: CartVM;
};

/** Displays a list of line item summaries from a {@link CartVM}. */
export const CartLineItemSummaryList = observer(
  function CartLineItemSummaryList({
    cart,
    styleVariant,
    ...rest
  }: ICartLineItemSummaryListProps) {
    const changeCartLineItemQuantity = useChangeCartLineQuantity(cart);
    const removeFromCart = useRemoveFromCart(cart);

    const quantityControls = useMemo(
      () => ({
        onQuantityChange: changeCartLineItemQuantity,
        maxQuantity: cart.maxQuantityPerLine
      }),
      [changeCartLineItemQuantity, cart.maxQuantityPerLine]
    );

    return (
      <LineItemSummaryList
        lines={cart.items}
        quantityControls={quantityControls}
        onClickRemove={removeFromCart}
        {...rest}
        styleVariant={styleVariant}
      />
    );
  }
);
