import { RecommendationContext } from '@/configs/recommendations';
import { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveFallback } from '@/utils/function-utils';
import Service from '../../Service';

import { IPage } from '../../models/Page';
import { siteCached } from '../../utils/siteCached/siteCached';
import { Config } from '../ConfigurationService';
import ConfigurationService from '../ConfigurationService/ConfigurationService';
import LoggerService from '../LoggerService';
import { PersonalizationService } from '../PersonalizationService';
import { IRecommendationContextConfig } from './IRecommendationContextConfig';
import IRecommendationsData from './IRecommendationsData';
import RecommendationServiceMock, {
  MOCK_RECOMMENDATION
} from './RecommendationServiceMock';

/**
 * RecommendationService handle the retrieval of recommendation products from the DY.
 * Integration Service.
 */
export class RecommendationService extends Service {
  /**
   * The Recommendations config.
   * @returns A `Config<'recommendations'>`.
   */
  @siteCached
  private get config(): Config<'recommendations'> {
    return ConfigurationService.getConfig('recommendations');
  }

  /**
   * The Dynamic Yield config.
   * @returns A `Config<'dynamicYield'>`.
   */
  @siteCached
  private get dyConfig(): Config<'dynamicYield'> {
    return ConfigurationService.getConfig('dynamicYield');
  }

  /** Initializes the recommendation service. */
  public constructor() {
    super();
  }

  /**
   * Gets the recommendation config values for a given context.
   * @param context - The recommendation context.
   * @returns - An object holding values useful to the frontend.
   * @throws If an invalid context is supplied.
   */
  public getContextConfig(
    context: RecommendationContext
  ): IRecommendationContextConfig {
    let isRecommendationEnabled = false;
    let isSlideOnMobile = false;

    switch (context) {
      case RecommendationContext.Account: {
        isRecommendationEnabled =
          this.config.getSetting('account.enabled').value;
        isSlideOnMobile = this.config.getSetting(
          'account.isSlideOnMobile'
        ).value;
        break;
      }

      case RecommendationContext.Cart: {
        isRecommendationEnabled = this.config.getSetting('cart.enabled').value;
        isSlideOnMobile = this.config.getSetting('cart.isSlideOnMobile').value;
        break;
      }

      case RecommendationContext.HomePage: {
        isRecommendationEnabled =
          this.config.getSetting('homepage.enabled').value;
        isSlideOnMobile = this.config.getSetting(
          'homepage.isSlideOnMobile'
        ).value;
        break;
      }

      case RecommendationContext.NotFoundPage: {
        isRecommendationEnabled = this.config.getSetting(
          'notFoundPage.enabled'
        ).value;
        isSlideOnMobile = this.config.getSetting(
          'notFoundPage.isSlideOnMobile'
        ).value;
        break;
      }

      case RecommendationContext.PDP: {
        isRecommendationEnabled = this.config.getSetting('pdp.enabled').value;
        isSlideOnMobile = this.config.getSetting('pdp.isSlideOnMobile').value;
        break;
      }

      case RecommendationContext.AddToCart: {
        isRecommendationEnabled =
          this.config.getSetting('addToCart.enabled').value;
        isSlideOnMobile = this.config.getSetting(
          'addToCart.isSlideOnMobile'
        ).value;
        break;
      }

      case RecommendationContext.Quickview: {
        isRecommendationEnabled =
          this.config.getSetting('addToCart.enabled').value;
        isSlideOnMobile = this.config.getSetting(
          'addToCart.isSlideOnMobile'
        ).value;
        break;
      }

      default: {
        exhaustiveFallback(context, () => {
          LoggerService.error(
            new InvalidArgumentError(
              `The context: ${context}, is not currently being handled.`
            )
          );
        });
      }
    }

    return { isRecommendationEnabled, isSlideOnMobile };
  }

  /**
   * A stub that will use the PersonalizationService to get dynamic recommendations. This method
   * should be used instead of the static recommendations method below to get DY's personalized
   * recommendations.
   * @param context - The context we want to retrieve the recommendation products.
   * @param page - The current page the user is on.
   * @returns A list of product SKUs with a title.
   */
  public async getDynamicRecommendations(
    context: RecommendationContext,
    page: IPage
  ): Promise<IRecommendationsData> {
    return Promise.resolve(MOCK_RECOMMENDATION);
  }

  /**
   * `getRecommendationsForCurrentUser` stablish the communication with DYService to
   * retrieve recommendation products based in a context: HOMEPAGE, PDP, CART, ACCOUNT.
   * @param context - The context we want to retrieve the recommendation products.
   * @param page - The current page the user is on.
   * @returns A list of product SKUs with a title.
   */
  public async getStaticRecommendations(
    context: RecommendationContext,
    page: IPage
  ): Promise<IRecommendationsData> {
    const isDYEnabled = this.dyConfig.getSetting('isDYEnabled').value;

    const fallbackRecommendations = {
      title: '',
      productList: []
    };

    // We currently have no default way of getting recommendations.
    // So if DY is not enabled then we return an empty list.
    if (!isDYEnabled) {
      return fallbackRecommendations;
    }

    /**
     * Gets the direct recomendation experience and pulls a list of products off of it.
     * In the future we will use the dynamic recommenations.
     */
    const decision = await PersonalizationService.getExperienceForCurrentUser(
      'directRecommendations',
      page
    );

    const { props } = decision;

    if (!props || !('productList' in props)) {
      return fallbackRecommendations;
    }

    const title = props.title ?? '';
    const productList = props.productList.split(',') ?? [];

    return {
      title,
      productList
    };
  }
}

export default RecommendationService.withMock(
  new RecommendationServiceMock(RecommendationService)
) as unknown as RecommendationService;
