import { Currency } from '@/services/models/Money';
import { OrderStatus } from '@/services/models/Order';
import { MOCK_ORDER_LINE } from '@/services/models/Order/mocks';
import { IMaskedShipment, ShippingProvider } from '@/services/models/Shipment';
import { MOCK_SHIPPING_METHOD } from '@/services/models/ShippingMethod/mocks';
import {
  IMaskedHistoricOrder,
  OrderType
} from '../data-structures/IMaskedHistoricOrder';

export const MASKED_SHIPMENT = {
  shippingProvider: ShippingProvider.UPS,
  trackingNumber: '9000423123',
  trackingUrl: 'tracking-url',
  items: [MOCK_ORDER_LINE],
  status: OrderStatus.Placed,
  id: 'test-id',
  promotions: [],
  shippingMethod: MOCK_SHIPPING_METHOD
} satisfies IMaskedShipment;

export const GET_ORDER_MASKED = {
  orderType: OrderType.Masked,
  orderID: 'SUUSC6858ST18Q',
  orderDate: '2023-05-10T19:44:36.919Z',
  shipments: [MASKED_SHIPMENT],
  subtotal: { amount: '50', currency: Currency.USD },
  total: { amount: '53.1', currency: Currency.USD },
  shippingCost: { amount: '3.1', currency: Currency.USD },
  tax: { amount: '0', currency: Currency.USD },
  discount: { amount: '0', currency: Currency.USD },
  giftCardTotal: { amount: '0', currency: Currency.USD },
  status: OrderStatus.Placed,
  email: '****@gmail.com',
  totalItems: 1
} as IMaskedHistoricOrder;

export const DELIVERY_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC4858BD18Q',
  shipments: [MASKED_SHIPMENT, MASKED_SHIPMENT]
};

export const BACKORDER_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC5858BD13D'
};

export const CANCELED_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC5858AC43S',
  shipments: [MASKED_SHIPMENT, MASKED_SHIPMENT, MASKED_SHIPMENT]
};

export const PROCESSING_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC8858FC31X'
};

export const SHIPPED_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC8858AA31D',
  shipments: [
    MASKED_SHIPMENT,
    MASKED_SHIPMENT,
    MASKED_SHIPMENT,
    MASKED_SHIPMENT
  ]
};

export const PREORDER_ORDER: IMaskedHistoricOrder = {
  ...GET_ORDER_MASKED,
  orderID: 'SUUSC7858TT22A'
};

export default GET_ORDER_MASKED;
