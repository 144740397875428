import { createContext } from 'react';
import { ProductModel } from '@/services/models/Product';
import { Nullable } from '@/type-utils';

/**
 *
 */
export interface IProductDisplayContext {
  /** The actual product model being tracked. */
  productModel: Nullable<ProductModel>;

  /** Updates the product Model. */
  setProductModel: Nullable<
    (model: ProductModel, attributesChanged?: boolean) => void
  >;

  /** Flag to notify the user if an attribute was changed/overwritten. */
  attributesChanged: boolean;
}

/**
 * The product context for used for all purchasable product components.
 * The callbacks are used to handle stages in modal products.
 */
export interface IProductPanelContext extends IProductDisplayContext {
  /** Callback for the removal of the first popup, say quickview. */
  resolvePanel?: Nullable<() => void>;

  /** Callback for when the add to cart modal is closed.  */
  callback?: Nullable<() => void>;
}

export const ProductDisplayContext = createContext<IProductDisplayContext>({
  productModel: null,
  setProductModel: null,
  attributesChanged: false
});

export const ProductPanelContext = createContext<IProductPanelContext>({
  productModel: null,
  setProductModel: null,
  attributesChanged: false
});
