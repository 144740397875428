'use client';

import { Decision } from '@/react/components/personalization/Decision';
import { DynamicExperience } from '@/react/components/personalization/DynamicExperience';
import GenericSelector from '@/react/components/product/VariationSelector/GenericSelector';
import type { IShoeHeightSelectorProps } from '@/react/components/product/VariationSelector/ShoeHeightSelector';
import ShoeHeightSelector from '@/react/components/product/VariationSelector/ShoeHeightSelector';
import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';

import {
  SHOE_HEIGHT_ORDER,
  type ShoeHeight
} from '../../../ShoeHeightMetadata';

import S from './styles.module.scss';
/**
 * Houses the Shoe Height appearance decisions. One shows a small image of a shoe sillouette, the other shows a button with the shoe height options.
 *
 * WARNING: This component will only work if an AB test with the ID "ab-pdp-shoe-height-appearance" is active, and at least one decision with the corresponding ID is available.
 */
export const ShoeHeightSelectorAB = observer(function ShoeHeightButtonAB({
  product
}: IShoeHeightSelectorProps) {
  return (
    <DynamicExperience
      experienceID="ab-pdp-shoe-height-appearance"
      fallback={<ShoeHeightSelector product={product} />}
    >
      <Decision id="ab-pdp-shoe-height-image">
        {() => <ShoeHeightSelector product={product} />}
      </Decision>
      <Decision id="ab-pdp-shoe-height-button">
        {() => (
          <GenericSelector
            style={{ minWidth: '100px' }}
            product={product}
            variationType={DisplayVariationAttributeType.ShoeHeight}
            sortFn={(a: string, b: string) =>
              SHOE_HEIGHT_ORDER.indexOf(a as ShoeHeight) -
              SHOE_HEIGHT_ORDER.indexOf(b as ShoeHeight)
            }
            // Since product group names are unpredictable, there may not be a 1:1; for now, this function should be consistent
            // how the value string is received.
            valueFormatterFn={(value: string) =>
              value.split('-').at(0)?.toLocaleUpperCase() ?? value
            }
            className={S.smallButton}
          />
        )}
      </Decision>
    </DynamicExperience>
  );
});
