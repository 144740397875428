import type ReturnItemType from '../order/item/ReturnItemType';

/**
 * Enumerates valid steps for the Returns Flow.
 *
 * Please keep the underlying strings URL-friendly since they are
 * used directly to update the URL.
 */
enum ReturnsFlowStep {
  /**
   * The first step, in which the customer looks up the original order
   * that the return will be based on.
   *
   * The customer can look up orders by entering their email or the order's
   * unique identifier. Since one email can have multiple associated orders,
   * email lookup will send the customer to the `OrderHistory` step, while
   * ID lookup will take them to the `OrderSummary` step.
   */
  OrderLookup = 'order-lookup',

  /**
   * This step shows all of the customer's orders that can be returned or
   * exchanged. The customer will be able to select one and start a return
   * from it.
   */
  OrderHistory = 'order-history',

  /**
   * This step shows a brief summary of the selected order and allows the
   * customer to select which items to return or exchange.
   */
  OrderDetails = 'order-details',

  /**
   * In this step, the customer will be prompted to select a {@link ReturnItemType return type}
   * for each of the items selected in the `OrderSummary` step.
   */
  ReturnTypeSelect = 'return-item-select',

  /**
   * This is the final step before confirming the return. The customer will
   * be able to review all their selections and select how to send the
   * items back.
   */
  Review = 'review',

  /** The final step of the flow. The return is now confirmed. */
  Confirmation = 'confirmation',

  /** In this step, the customer can capture and lookup a Return/Exchange Order based on Order Number. */
  TrackReturn = 'track-return'
}

export default ReturnsFlowStep;
