'use client';

import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';

import { IImageProps, Image } from '../../core-ui/Image';
import IStylable from '../../traits/IStylable';
import { IImage } from '@/services/models/Media/Image';

import S from './styles.module.scss';

export interface IImageSwitcherProps extends IStylable {
  /** Image to display. */
  image: IImage;

  /**
   * Props to pass to the underlying {@link Image} component.
   */
  imageProps?: Partial<IImageProps>;

  /**
   * Props to pass to the underlying {@link Image} component.
   */
  imageClassName?: string;
}

/**
 * A component that crossfades between images.
 * To change the image, simply change the `image` prop. The component will
 * automatically crossfade from the last image to the current one.
 */
const ImageSwitcher: FunctionComponent<IImageSwitcherProps> = ({
  image,
  imageProps = {},
  id,
  className,
  imageClassName,
  style
}) => {
  // WHY?
  // When you try to change an image, the `Image` component is rerendered.
  // Because of this, the new `Image` component just abruptly "pops" into existence.
  //
  // `ImageSwitcher` component enables the use of CSS transitions to swap images
  // gracefully.

  // HOW?
  // Two images are rendered: A and B. The first image is rendered on both components,
  // but the subsequent images alternate between being rendered on A and B.
  // B is always on top, fades in after an image is rendered on it, and fades out after
  // an image is rendered on A. This means that every new image is always rendered when its
  // container is transparent, and becomes opaque after the "popping" in has happened.
  //
  // The result: a smooth transition every time the image changes.

  // Images are stored on the `a` and `b` states. Whenever the image
  // prop changes, it gets assigned to the state opposite of the last image.

  // Store the last and current image to crossfade.
  const [a, setA] = useState<IImage>(image);
  const [b, setB] = useState<IImage>(image);

  // Flip (flop) flag, to indicate whether the next image will go to `a` or to `b`.
  const [flip, setFlip] = useState<boolean>(true);

  // On mount and when the image changes:
  useEffect(() => {
    setFlip((f) => {
      // If flip...
      if (f) {
        // store new image on `a`.
        setA(image);
      } else {
        // If not, store on `b`.
        setB(image);
      }

      // return `not(flip)` to do the opposite for the next image.
      return !f;
    });
  }, [image]);

  return (
    <div
      className={classNames(S.imageSwitcher, className)}
      id={id}
      style={style}
    >
      <div className={classes({ [S.visible]: !flip }, imageClassName)}>
        <Image fill image={a} fit="contain" />
      </div>

      {/* The topmost container will either fade in or out depending on `flip`. */}
      <div className={classes(S.top, { [S.visible]: flip }, imageClassName)}>
        <Image fill image={b} fit="contain" />
      </div>
    </div>
  );
};

export default ImageSwitcher;
