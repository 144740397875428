import {
  IShipment,
  ITrackingEvent,
  ShippingProvider
} from '@/services/models/Shipment';

import { CardNetwork } from '@/constructs/CardNetwork';

import { MOCK_ORDER_LINE } from '@/services/models/Order/mocks';
import { MOCK_SHIPPING_METHOD } from '@/services/models/ShippingMethod/mocks';
import { MOCK_ADDRESS } from '../../../models/Address';
import { Currency } from '../../../models/Money';
import { OrderStatus, PaymentSummaryType } from '../../../models/Order';
import { IHistoricOrder } from '../data-structures/IHistoricOrder';
import { OrderType } from '../data-structures/IMaskedHistoricOrder';

export const TRACKING_EVENTS = [
  {
    id: 1,
    event: 'In Transit, Flagstaff AZ 5/6/21 7:40:58 am'
  },
  {
    id: 2,
    event: 'In Transit, Flagstaff AZ 5/5/21 11:31:00 pm'
  },
  {
    id: 3,
    event: 'In Transit, Goodyear AZ 5/5/21 8:14:00 pm'
  },
  {
    id: 4,
    event: 'In Transit, Flagstaff AZ 5/6/21 7:40:58 am'
  },
  {
    id: 5,
    event: 'In Transit, Flagstaff AZ 5/5/21 11:31:00 pm'
  },
  {
    id: 6,
    event: 'In Transit, Goodyear AZ 5/5/21 8:14:00 pm'
  }
] as Array<ITrackingEvent>;

export const UNMASKED_SHIPMENT = {
  shippingProvider: ShippingProvider.UPS,
  trackingNumber: '9000423123',
  trackingUrl: 'tracking-url',
  items: [MOCK_ORDER_LINE],
  shippingAddress: MOCK_ADDRESS,
  trackingEvents: TRACKING_EVENTS,
  status: OrderStatus.Placed,
  shippingMethod: MOCK_SHIPPING_METHOD,
  id: 'test-id',
  promotions: []
} as IShipment;

export const GET_ORDER_UNMASKED = {
  orderToken: 'dummyToken',
  orderType: OrderType.Full,
  orderID: 'SUUSC6858ST18Q',
  orderDate: '2023-05-10T19:44:36.919Z',
  shipments: [UNMASKED_SHIPMENT],
  subtotal: { amount: '50', currency: Currency.USD },
  total: { amount: '53.1', currency: Currency.USD },
  shippingCost: { amount: '3.1', currency: Currency.USD },
  tax: { amount: '0', currency: Currency.USD },
  discount: { amount: '0', currency: Currency.USD },
  giftCardTotal: { amount: '10', currency: Currency.USD },
  payments: [
    {
      paymentMethod: PaymentSummaryType.GiftCard,
      endingDigits: '1234',
      expirationDate: '12/24'
    },
    {
      paymentMethod: PaymentSummaryType.CreditCard,
      endingDigits: '5678',
      expirationDate: '12/25',
      cardNetwork: CardNetwork.Visa
    }
  ],
  status: OrderStatus.Delivered,
  billingAddress: MOCK_ADDRESS,
  email: 'a.name@deckers.com',
  totalItems: 1
} as IHistoricOrder;

export const DELIVERY_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC4858BD18Q',
  shipments: [UNMASKED_SHIPMENT, UNMASKED_SHIPMENT]
};

export const BACKORDER_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC5858BD13D'
};

export const CANCELED_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC5858AC43S',
  shipments: [UNMASKED_SHIPMENT, UNMASKED_SHIPMENT, UNMASKED_SHIPMENT]
};

export const PROCESSING_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC8858FC31X'
};

export const SHIPPED_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC8858AA31D',
  shipments: [
    UNMASKED_SHIPMENT,
    UNMASKED_SHIPMENT,
    UNMASKED_SHIPMENT,
    UNMASKED_SHIPMENT
  ]
};

export const PREORDER_ORDER: IHistoricOrder = {
  ...GET_ORDER_UNMASKED,
  orderID: 'SUUSC7858TT22A'
};

export default GET_ORDER_UNMASKED;
