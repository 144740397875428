'use client';

// Import libraries
import { observer } from 'mobx-react-lite';
import Image, { StaticImageData } from 'next/image';
import { FunctionComponent, useMemo, useState } from 'react';

// Import services
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

// Import unit structs
import { IProduct, ProductModel } from '@/services/models/Product';
import { VariationAttributeType } from '@/services/models/Product/variation-attributes';

import { IImage } from '@/services/models/Media/Image';
import { classes } from '@/next-utils/css-utils/scss-utils';

// Import components
import { PriceModel } from '@/services/models/Price';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { Nullable } from '@/type-utils';
import { Link } from '../../core-ui/Link';
import RatingStars from '../../core-ui/RatingStars';
import QuickviewButton from '../quickview/QuickviewButton';
import { Price } from '../../product/Price';
import ImageSwitcher from '../../utility/ImageSwitcher';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { Image as GenericImage } from '../../core-ui/Image';
import ITestIdentifiable from '../../traits/ITestIdentifiable';
import ColorSelector, { ColorOption } from './ColorSelector';

// Import styles and assets
import newStyleBanner from './static/badges/new_style.png';
import S from './styles.base.module.scss';

/** Defines the possible badge types a product tile can have. */
export enum ProductBadge {
  newStyle = 'newStyle'
}

/**
 * Utility to match a `ProductBadge` enum to its corresponding image.
 * @param badge - The badge to use.
 * @returns The badge image or `null` if the supplied badge type is invalid.
 */
const getBadgeImage = (badge: ProductBadge): StaticImageData | null => {
  switch (badge) {
    case ProductBadge.newStyle:
      return newStyleBanner;

    default:
      return null;
  }
};

/** Defines props for the props `ProductTile` component. */
export interface IProductTileProps extends ITestIdentifiable {
  /** The product to display. */
  product: Nullable<IProduct>;

  /** A badge to include on this tile. */
  badge?: ProductBadge;

  /** Promotional text to include on this tile. */
  promotionText?: string;

  /** Defines tile type. */
  variant?: 'default' | 'recommendation' | 'cart';

  /** Is recommendation tile in a slide. */
  isSlideTile?: boolean;
}

/** Component that displays a product in a results or category page.  */
const ProductTile: FunctionComponent<IProductTileProps> = observer(
  ({
    product,
    badge,
    promotionText,
    variant = 'default',
    isSlideTile,
    datatestID
  }) => {
    const productModel = useMemo(
      () => (product ? ProductModel.from(product) : null),
      [product]
    );

    const { name, primaryImage, secondaryImage, price } = productModel ?? {};

    const [currentImage, setCurrentImage] =
      useState<Nullable<IImage>>(primaryImage);

    // If the product model is not yet available...
    if (!productModel) {
      // Render a tile container with skeleton loaders.
      return (
        <div
          className={classes(S.productTile, S.loading, {
            [S.recommendation]: variant === 'recommendation',
            [S.slideTile]: isSlideTile || false
          } as { [key: string]: boolean })}
          data-testid={datatestID}
        >
          <div className={classes(S.content, S.loading)}>
            <div className={S.imageContainer}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>

            <div className={S.productName}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>

            <div className={S.infoRow}>
              <SkeletonLoader className={S.skeletonLoader} />
            </div>
          </div>
        </div>
      );
    }

    const priceModel = price ? PriceModel.from(price) : null;

    const colorVariants = productModel?.getAvailableAttributesByType(
      VariationAttributeType.Color
    );

    const { origin, pathname } = EnvironmentService.url;
    // Retrieve paths to send to product page from a category page.
    const href = productModel.getUrlWithCategory(pathname);

    const variants = {
      default: variant === 'default',
      recommendation: variant === 'recommendation',
      cart: variant === 'cart'
    };

    return (
      <div
        className={classes(
          !variants.cart ? S.productTile : '',
          variants.recommendation ? S.recommendation : '',
          isSlideTile ? S.slideTile : '',
          variants.cart ? S.cart : ''
        )}
        data-testid={datatestID}
        // When the mouse enters the tile (hover), change image to secondary.
        onMouseEnter={() => {
          if (!variants.cart) setCurrentImage(secondaryImage);
        }}
        // Change back to primary when the mouse leaves.
        onMouseLeave={() => {
          if (!variants.cart) setCurrentImage(primaryImage);
        }}
      >
        <div className={S.content}>
          {badge ? (
            <div className={S.badge}>
              <Image
                // Temporary badge id as alt tag.
                alt={badge}
                className={S.image}
                src={getBadgeImage(badge) as StaticImageData}
                fill
              />
            </div>
          ) : null}

          {!variants.cart && (
            <div className={S.quickShopButton}>
              <QuickviewButton product={productModel} />
            </div>
          )}
          {!variants.cart ? (
            <Link
              href={href}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
            >
              <div className={S.imageContainer}>
                {currentImage ? (
                  <ImageSwitcher
                    image={currentImage}
                    imageProps={{
                      fit: 'contain'
                    }}
                    imageClassName={
                      variants.recommendation ? S.slideTileImage : undefined
                    }
                  />
                ) : null}
              </div>
            </Link>
          ) : (
            <Link
              href={href}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
            >
              <div className={S.imageContainer}>
                {primaryImage ? (
                  <GenericImage image={primaryImage} />
                ) : (
                  <SkeletonLoader />
                )}
              </div>
            </Link>
          )}

          <Link
            href={href.toString()}
            variant="text"
            interactionDetails={{
              action: EventType.TileClick,
              product: productModel
            }}
            datatestID="plpProductName"
          >
            <div className={S.productName}>{name}</div>
          </Link>
          {!variants.cart && (
            <div className={S.ratings}>
              {!productModel.ratings.pending ? (
                <RatingStars
                  rating={productModel.ratings.value?.averageRating ?? 0}
                />
              ) : (
                // A skeleton loading indicator would go well here.
                <SkeletonLoader className={S.skeletonLoader} />
              )}
            </div>
          )}

          <div className={S.infoRow}>
            {colorVariants ? (
              <>
                {priceModel && (
                  <Price price={priceModel} className={S.currentPrice} />
                )}

                {/* Colors/variants */}
                {colorVariants.length > 0 ? (
                  <>
                    <div className={S.variantsDivider}>/</div>
                    <div className={S.variants}>{`${
                      colorVariants.length
                    } color${
                      // Add an 's' only if it is more than one variant.
                      colorVariants.length > 1 ? 's' : ''
                    }`}</div>
                  </>
                ) : null}
              </>
            ) : (
              <SkeletonLoader className={S.skeletonLoader} />
            )}
          </div>

          {colorVariants && colorVariants.length > 1 && !variants.cart ? (
            <div className={S.colors}>
              <ColorSelector>
                {/* TODO: Use actual variant colors here. */}
                {colorVariants.map((color, i) => {
                  const [newSKU, attributesChanged] =
                    productModel.selectAttributeSKU(color);
                  const url = new URL(`/p/${newSKU}`, origin);

                  return (
                    <ColorOption
                      key={newSKU}
                      color={color}
                      onHover={() => {
                        if (color.secondaryImage) {
                          setCurrentImage(color.secondaryImage);
                        }
                      }}
                      href={url}
                    />
                  );
                })}
              </ColorSelector>
            </div>
          ) : null}

          {promotionText ? (
            <div className={S.promotionText}>{promotionText}</div>
          ) : null}
        </div>
      </div>
    );
  }
);

export default ProductTile;
