'use client';

import { useRouter } from '@/react/utils/router-utils/useRouter';
import { FunctionComponent, useContext } from 'react';

import { ProductLineItemModel } from '@/services/models/Cart/LineItem';
import { IProduct } from '@/services/models/Product';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';

import { RecommendationContext } from '@/configs/recommendations';
import { observer } from 'mobx-react-lite';
import { Button } from '../../core-ui/Button';
import { Card } from '../../core-ui/Card';
import { Icon, IconTypes } from '../../core-ui/Icon';
import { Modal } from '../../core-ui/Modal';
import { ModalHeader } from '../../core-ui/Modal/ModalHeader';
import { RecommendationsCarousel } from '../../product-list/recommendation/RecommendationsCarousel';
import { ProductPanelContext } from '../../product/ProductContext';
import { LineItemSummary } from '../LineItemSummary';

import S from './styles.module.scss';
import { product_details_viewCart } from "@/lang/__generated__/ahnu/product_details_viewCart";
import { product_details_checkoutNow } from "@/lang/__generated__/ahnu/product_details_checkoutNow";
import { product_details_addedToCart } from "@/lang/__generated__/ahnu/product_details_addedToCart";

interface IAddToCartProps {
  /** The current product model. */
  product: IProduct;

  /** Is it currently opened.  */
  opened?: boolean;

  /**
   * Set this modal to open.
   * @param value - Boolean value for if opened.
   */
  setOpened: (value: boolean) => void;
}

/** Modal that pops up when something is added to cart. */
export const AddToCartModal: FunctionComponent<IAddToCartProps> = observer(
  ({ product, setOpened, opened = false }) => {
    const [msg, msgf] = useLocaleMessage();
    const router = useRouter();

    const { callback } = useContext(ProductPanelContext);

    const handleClose = (): void => {
      // A callback that handles whatever action needs to happen when the modal closes.
      // EG: Closes the quickview modal.
      if (callback) callback();
      setOpened(false);
    };

    return (
      <div>
        <Modal isOpen={opened} onClose={handleClose}>
          <div className={S.container}>
            <ModalHeader onClose={handleClose} className={S.header}>
              {`
              ${msgf(product_details_addedToCart, {
                productName: product.name
              })}
            `}
            </ModalHeader>
            <Card padding="md">
              <LineItemSummary
                line={ProductLineItemModel.fromProduct(product, '', '')}
                variant="simple"
                showActions={false}
              />
              <div className={S.buttonContainer}>
                <Button
                  className={S.button}
                  onClick={() => router.push('/cart')}
                  variant="secondary"
                >
                  {msg(product_details_viewCart)}
                </Button>
                <Button
                  className={S.button}
                  onClick={() => router.push('/checkout')}
                >
                  <Icon icon={IconTypes.Lock} />
                  {msg(product_details_checkoutNow)}
                </Button>
              </div>
            </Card>
            <RecommendationsCarousel
              context={RecommendationContext.AddToCart}
            />
          </div>
        </Modal>
      </div>
    );
  }
);
