import type {
  DecisionID,
  PropsOfDecision
} from '@/services/isomorphic/PersonalizationService/campaigns';
import { InvalidStateError } from '@/utils/errors';
import { observer } from 'mobx-react-lite';
import { ReactNode, useContext } from 'react';
import SkeletonLoader from '../../utility/SkeletonLoader';
import { ExperienceContext } from '../DynamicExperience/ExperienceContext';

import S from './styles.module.scss';

export interface IPersonalizationDecisionProps<DID extends DecisionID> {
  /** The decision id, a property on the JSON payload. */
  id: DID;
  /** The fallback to render while the decision is still loading. */
  fallback?: ReactNode;
  /**
   * The children of the decision experience, allows you to feed props into it.
   * @param props - Props that are relevant to this specific decision.
   * @returns The children components that have been fed the decision props.
   */
  children: (
    /** Props must be able to accept a null in order to pre-render the children before the official props have come in. */
    props: PropsOfDecision<DID> | null
  ) => ReactNode;
}

const DecisionFallback = <SkeletonLoader />;

/**
 * The decision component holds a personalization experience, but does not render it until
 * the choice has been made by the `PersonalizationService`.
 * @param props - The props for this component.
 * @returns The children component or a placeholder until the choice is made.
 * @throws If not wrapped by DynamicExperience component.
 * @example
 * <DynamicExperience experienceID="testExperience" fallback={<div>Fallback</div>}>
 *  <Decision id="decision-testExperience-1">
 *    {() => <div>Decision 1</div>}
 *  </Decision>
 *  <Decision id="decision-testExperience-2">
 *    {() => <div>Decision 2</div>}
 *  </Decision>
 * </DynamicExperience>.
 */
// Since `children` is a function, it may close over observable values.
// Therefore to subscribe to observable changes, we must wrap it in `observer`.
export const Decision = observer(function Decision<DID extends DecisionID>({
  children,
  id,
  fallback = DecisionFallback
}: IPersonalizationDecisionProps<DID>) {
  const experienceContext = useContext(ExperienceContext);

  if (!experienceContext) {
    throw new InvalidStateError(
      'Decision must be wrapped in a DynamicExperience component.'
    );
  }
  const { decision } = experienceContext;

  if (!decision)
    return (
      <div className={S.loadingWrapper}>
        <div className={S.hiddenDecision}>
          <div className={S.fallbackWrapper}>{fallback}</div>
          {children(null)}
        </div>
      </div>
    );

  const { decisionID, props } = decision;

  if (decisionID !== id) return null;

  return <>{children(props as PropsOfDecision<DID>)}</>;
});
