'use client';

import Image from 'next/image';

import {
  type IProductVM,
  DisplayVariationAttributeType
} from '@/react/view-models/ProductVM';
import LoggerService from '@/services/isomorphic/LoggerService';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';
import {
  ShoeHeight,
  SHOE_HEIGHT_ORDER,
  STYLE_DATA
} from './ShoeHeightMetadata';

export interface IShoeHeightSelectorProps {
  /** The product to display shoe height options for. */
  product: IProductVM;
}

/** The Style selector component for the PDP. */
const ShoeHeightSelector = observer(function ShoeHeightSelector({
  product
}: IShoeHeightSelectorProps) {
  const variations = product
    .getVariationAttributesByType(DisplayVariationAttributeType.ShoeHeight)
    .slice(0); // clone the array

  // sort the variations to be in a consistent order
  variations.sort(
    (a, b) =>
      SHOE_HEIGHT_ORDER.indexOf(a.value as ShoeHeight) -
      SHOE_HEIGHT_ORDER.indexOf(b.value as ShoeHeight)
  );

  return (
    <div className={S.container}>
      {variations.map(({ type, value, isSelected, isSelectable }) => {
        // TODO: Since product group names are unpredictable, there may not be a 1:1
        // mapping between the variation value and the style data. As such, replace this
        // with a more dynamic solution once the product group metadata is available.
        const data = STYLE_DATA[value as keyof typeof STYLE_DATA];

        if (!data) {
          LoggerService.warn(
            `No style data found for shoe height variation: ${value}`
          );
          return undefined;
        }

        return (
          <button
            key={value}
            type="button"
            className={classNames(S.styleButton, { [S.active]: isSelected })}
            disabled={!isSelectable}
            onClick={() =>
              isSelectable && product.selectVariationAttribute(type, value)
            }
          >
            <Image
              priority
              src={data.src}
              alt={data.name}
              width={data.width}
              height={data.height}
              className={S.image}
            />
            <span className={S.nameLabel}>{data.name}</span>
          </button>
        );
      })}
    </div>
  );
});

export default ShoeHeightSelector;
