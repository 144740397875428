'use client';

import { RecommendationContext } from '@/configs/recommendations';
import { Nullable } from '@/type-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PageType, type IPage } from '@/services/models/Page';
import { IProduct } from '@/services/models/Product';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import ProductService from '@/services/isomorphic/ProductService';
import RecommendationService from '@/services/isomorphic/RecommendationService';
import { PageContext } from '../components/templates/context/PageContext';

/** `useRecommendedProducts` returning interface. */
interface IUseRecommendedProducts {
  /** A list of recommended products. */
  products: Array<IProduct>;

  /** A title to display above the recommendations. */
  title: Nullable<string>;

  /** Is the current recommendation context currently enabled. */
  isRecommendationEnabled: boolean;

  /** Is the current context set to slide on mobile, which is an alternate mobile style. */
  isSlideOnMobile: boolean;
}

/**
 * `useRecommendedProducts` fetches recommended products given an context.
 * @param context - The context to display the products.
 * @param recommendedProducts - When rendering the `Custom404Page` from the `GenericPage`
 * the props `recommendedProducts` will be undefined since it is fetched in a static manner.
 * Therefore, we need to conditionally check it before actually fetch recommended products again.
 * @returns - A list of recommended products.
 */
export const useRecommendedProducts = (
  context: RecommendationContext,
  recommendedProducts?: Array<IProduct>
): IUseRecommendedProducts => {
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const [title, setTitle] = useState<Nullable<string>>(null);

  const { isRecommendationEnabled, isSlideOnMobile } =
    RecommendationService.getContextConfig(context);

  const page = useContext(PageContext)?.page;

  const fetchProducts = useCallback(async () => {
    const { productList, title } =
      await RecommendationService.getStaticRecommendations(
        context,
        page ?? {
          pageType: PageType.Unknown,
          url: EnvironmentService.url.origin
        }
      );

    const getProductPromises = productList.map((sku) =>
      ProductService.getProductDTO(sku)
    );

    const recommendedProds = await Promise.all(getProductPromises);

    setTitle(title);
    setProducts(recommendedProds);
  }, []);

  useEffect(() => {
    if (isRecommendationEnabled) {
      if (recommendedProducts && recommendedProducts.length > 0) {
        setTitle(title);
        setProducts(recommendedProducts);
      } else {
        fetchProducts();
      }
    }
  }, [recommendedProducts, fetchProducts]);

  return {
    title,
    products,
    isRecommendationEnabled,
    isSlideOnMobile
  };
};
