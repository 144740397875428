import axios, { AxiosError } from 'axios';
import { v4 as uuid } from 'uuid';

import { InvalidArgumentError } from '@/utils/errors';

import { Nullable } from '@/type-utils';
import { isValidEmail } from '@/utils/string-utils';

import Service from '@/services/Service';
import CSRFTokenService from '@/services/isomorphic/CSRFTokenService';
import {
  IOrderLookupOptions,
  IOrderLookupParams,
  IOrderLookupResponse,
  OrderHistoryModel
} from '@/services/models/OrderHistory';

import WebStorageService from '@/services/client/WebStorageService';
import { isNullOrEmpty } from '@/utils/null-utils';
import { removeNullish } from '@/utils/object-utils';

import MemoryCache from '@/utils/MemoryCache';

import ConfigurationService from '../ConfigurationService/ConfigurationService';
import UserService from '../UserService';
import { IOrderHistoryOptions } from './data-structures/IOrderHistoryOptions';

import OrderNotFoundError from './errors/OrderNotFoundError';
import UnableToFindOrderError from './errors/UnableToFindOrderError';

import OrderLookupServiceMock from './OrderLookupServiceMock';
import type {
  HistoricOrder,
  IHistoricOrder,
  IMaskedHistoricOrder
} from './data-structures';

/**
 * `OrderLookupService` is a service that allows you to look up orders by zip code and
 * order ID or email address. Used in general to look up orders for a customer given the proper authorization.
 */
export class OrderLookupService extends Service {
  /**
   * This memory cache is used for the get order history call. This makes navigating the /orders
   * and /orders/${orderID} pages much faster.
   */
  private orderLookupCache = new MemoryCache<
    IOrderLookupResponse<HistoricOrder>
  >(30);

  private client = axios.create({ baseURL: '/api/order' });

  /** Initialize the service. */
  public constructor() {
    super();
  }

  /**
   * The implementation method for the `getOrderHistory` method. It should handle
   * both the authenticated call and the calls using the zip code and orderID or email address.
   *
   * @param historyOptions - The options for the order history lookup. This includes the options
   * for the lookup and whether to ignore the cache and whether the user is authenticated.
   * @returns A pageable `OrderHistoryModel`.
   */
  public async getOrderHistory(
    historyOptions: IOrderHistoryOptions
  ): Promise<
    OrderHistoryModel<IMaskedHistoricOrder> | OrderHistoryModel<IHistoricOrder>
  > {
    const { params, ignoreCache = false } = historyOptions;

    const pageSize = ConfigurationService.getConfig('order').getSetting(
      'orderHistory.pageSize'
    ).value;

    try {
      const response: IOrderLookupResponse<HistoricOrder> =
        await this.getOrders({
          pageSize,
          page: 0,
          params,
          ignoreCache
        });

      const { orders: firstPage, totalOrders } = response;
      const totalPages = Math.ceil(totalOrders / pageSize);

      const pages = new Array(totalPages).fill(undefined);
      pages[0] = firstPage;

      return new OrderHistoryModel<HistoricOrder>({
        pages,
        pageSize,
        allItems: [], // This gets filled by the model with the pages value
        totalPages,
        totalItems: totalOrders,
        currentPage: 0,
        hasNext: true,
        hasPrevious: true,
        isLoadingNewPage: false,
        lookupParams: params
      });
    } catch (error) {
      if (error instanceof OrderNotFoundError) {
        // Return an empty order history model
        return new OrderHistoryModel<HistoricOrder>({
          pages: [],
          pageSize,
          allItems: [],
          totalPages: 0,
          totalItems: 0,
          currentPage: 0,
          hasNext: false,
          hasPrevious: false,
          isLoadingNewPage: false,
          lookupParams: params
        });
      }

      throw error;
    }
  }

  /**
   * Given parameters for order lookup, will determine whether the parameters represent a
   * request to lookup an order anonymously, without relying the end-user being
   * authenticated and authorized to view the order.
   *
   * If the anonymous params are missing (such as email and zip code) then it must be an
   * authenticated lookup.
   * @param params - The order lookup params.
   * @returns If these params represent an anonymous lookup or not.
   */
  public isAnonymousParams(params: IOrderLookupParams): boolean {
    return 'email' in params || 'zipCode' in params;
  }

  /**
   * Retrieves orders based on the provided options.
   *
   * @param options - Lookup Options.
   * @returns An `IOrderLookupResponse` object.
   *
   * @throws An {@link InvalidArgumentError} if an invalid set of arguments is provided.
   * @throws An {@link OrderNotFoundError} if no orders match the specified
   * options.
   * @throws An {@link UnableToFindOrderError} if an unknown order lookup error
   * occurs.
   */
  public async getOrders(
    options: IOrderLookupOptions
  ): Promise<IOrderLookupResponse<HistoricOrder>> {
    const csrfHeaders = CSRFTokenService.getHeaders();

    const { params, pageSize, page } = options;

    if (this.isAnonymousParams(params)) {
      const paramsOK = await this.validateParams(params);

      if (!paramsOK) {
        throw new InvalidArgumentError(
          'Cannot look up orders: You must provide either an email + a zipCode' +
            ' OR an orderID + a zipCode for anonymous lookup operations.'
        );
      }

      const body = removeNullish({ lookupParams: params, page, pageSize });

      const res = await this.client
        .post<IOrderLookupResponse<IMaskedHistoricOrder>>('/lookup', body, {
          headers: {
            ...csrfHeaders
          }
        })
        .catch((error) => {
          if ((error as AxiosError).response?.status === 404) {
            throw new OrderNotFoundError(
              'No orders matched the specified lookup options.'
            );
          }

          throw new UnableToFindOrderError(
            'An unexpected error occurred when looking up orders.',
            { cause: error }
          );
        });

      return res.data;
    }

    // If you reached this point, this is an authenticated lookup (no params).
    // i.e. this lookup will get the current user's order history.
    const res = await this.client.post<IOrderLookupResponse<IHistoricOrder>>(
      '/lookup',
      removeNullish({
        lookupParams: params,
        page: options.page,
        pageSize: options.pageSize
      }),
      {
        headers: {
          ...csrfHeaders
        }
      }
    );

    return res.data;
  }

  /**
   * Given a string that could either be an email or an order ID, collapse it
   * into what it really is.
   *
   * Useful on UI components that can take either an email or an order ID in the
   * same field.
   *
   * @param emailOrOrderID - The string to evaluate.
   *
   * @returns An object with the collapsed value. One of them will contain the
   * string passed originally. And the other one will be `null`.
   *
   * @throws An {@link InvalidArgumentError} if passed a string that is neither
   * an email nor an order ID.
   */
  public collapseEmailOrOrderID(emailOrOrderID: string): {
    email?: Nullable<string>;
    orderID?: Nullable<string>;
  } {
    // Remove spaces from emailOrOrderNumber and format it
    // to lowercase for email or order ID verification.
    const emailOrOrderIDWithoutSpaces = emailOrOrderID
      .replace(/\s/g, '')
      .toLowerCase();

    if (isValidEmail(emailOrOrderIDWithoutSpaces)) {
      return { email: emailOrOrderIDWithoutSpaces, orderID: null };
    }

    // Normalize the possible order ID by removing all dashes and turning
    // it into uppercase.
    const normalizedOrderID = emailOrOrderIDWithoutSpaces
      .replaceAll('-', '')
      .toUpperCase();

    // TODO: Add a proper orderID regex
    if (normalizedOrderID.length >= 14) {
      return { orderID: normalizedOrderID, email: null };
    }

    throw new InvalidArgumentError(
      `Cannot collapse string "${emailOrOrderID}"` +
        " into an email or order ID since it doesn't look like either."
    );
  }

  /**
   * Determines if a given order is returnable.
   *
   * @param order - The order to check.
   * @returns `true` if the order is indeed returnable, and `false` otherwise.
   */
  public isOrderReturnable(order: IMaskedHistoricOrder): boolean {
    // Kudos to Matheus for this check.

    // The order is returnable if any of its items are returnable.
    const items = order.shipments.flatMap((shipment) => shipment.items);
    return items.some((item) => item.isReturnable);
  }

  /**
   * Checks if a given set of lookup parameters is valid.
   *
   * @param params - Parameters to validate.
   * @returns A `true` value if the params are valid, and `false` otherwise.
   */
  public async validateParams(params: IOrderLookupParams): Promise<boolean> {
    const { orderID, email, zipCode, userUUID } = params;

    if (!isNullOrEmpty(orderID)) {
      // Only allow order ID without zip code if the user is authenticated.
      if (
        !isNullOrEmpty(zipCode) ||
        UserService.isAuthenticated(await UserService.getCurrentUser())
      ) {
        return true;
      }
    }

    if (!isNullOrEmpty(email) && !isNullOrEmpty(zipCode)) {
      return true;
    }

    if (!isNullOrEmpty(userUUID)) {
      return true;
    }

    return false;
  }

  /**
   * Saves the lookup params into session storage. It generates a `CUID2` token
   * which it returns.
   * @param lookupParams - The lookup params you wish to save, it adds the token
   * to the params before saving them.
   * @returns The `CUID2` string.
   */
  public saveLookupParams(lookupParams: IOrderLookupParams): string {
    // Generates the token to be used as the lookup key for these params.
    // TODO: This should be made into a 'cuid2' right now using it is causing issues
    // with Chromatic, this should be changed when we update storybook.
    const token = uuid();

    WebStorageService.sessionStorage?.setItem(token, {
      ...lookupParams,
      token
    });

    return token;
  }

  /**
   * Gets the lookup params by a token. This is saved in session storage and can
   * be placed within the url to retrieve the lookup params.
   * @param lookupID - The token which should be a `CUID2`.
   * @returns The saved lookup params.
   */
  public getLookupParams(lookupID: string): IOrderLookupParams {
    // Get the params based on the token.
    const storedParams = WebStorageService.sessionStorage?.getItem(
      lookupID
    ) as IOrderLookupParams;

    return storedParams;
  }

  /**
   * Retrieves an order by token.
   *
   * @param token - The order token.
   * @returns The order, in {@link IHistoricOrder} form (if found).
   */
  public async getOrderByToken(token: string): Promise<HistoricOrder> {
    try {
      const csrfHeaders = CSRFTokenService.getHeaders();

      const res = await this.client.get<IHistoricOrder>('', {
        params: { token },
        headers: {
          ...csrfHeaders
        }
      });

      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        throw new OrderNotFoundError(`No order found for token "${token}".`);
      }

      throw new UnableToFindOrderError(
        `An unknown error ocurred when fetching order for token "${token}".`,
        {
          cause: error
        }
      );
    }
  }
}

export default OrderLookupService.withMock(
  new OrderLookupServiceMock(OrderLookupService)
) as unknown as OrderLookupService;
