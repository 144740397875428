'use client';

import { IProduct, type ProductModel } from '@/services/models/Product';
import { VariationAttributeType } from '@/services/models/Product/variation-attributes';

import { msg, msgf } from '@/services/isomorphic/I18NService';
import type { Nullable } from '@/type-utils';
import { observer } from 'mobx-react-lite';
import { Button } from '../../../core-ui/Button';
import SkeletonLoader from '../../../utility/SkeletonLoader';

import { toGroupString } from '../../../../utils/product-utils';
import S from './styles.module.scss';
import { cart_cartItem_color } from "@/lang/__generated__/ahnu/cart_cartItem_color";
import { cart_cartItem_editDetails } from "@/lang/__generated__/ahnu/cart_cartItem_editDetails";
import { cart_cartItem_genderAndSize } from "@/lang/__generated__/ahnu/cart_cartItem_genderAndSize";
import { cart_cartItem_sizeDisplay } from "@/lang/__generated__/ahnu/cart_cartItem_sizeDisplay";

/**
 * Line Item details props definition.
 */
export interface ILineItemDetailsProps {
  /** The product line item from cart. */
  product: Nullable<ProductModel>;
  /**
   * The style of the product line item.
   * Simple option is displayed without cart components, used in
   * the AddToCartModal.
   */
  variant?: 'default' | 'preview' | 'simple';
  /**
   * Callback that will be executed when the `Edit Details` link is
   * clicked on this line item. If omitted, the link will not be displayed.
   * @param product - The product corresponding to the line item.
   */
  onClickEdit?: (product: IProduct) => void;
  /**
   * Controls the visibility of the action buttons in the component.
   * If `false`, the `Remove` will not be displayed.
   * @default true
   */
  showActions?: boolean;
}

/** The details section of the line item component. */
export const LineItemDetails = observer(function LineItemDetails({
  product,
  variant,
  onClickEdit,
  showActions = true
}: ILineItemDetailsProps) {
  const sku = product?.sku ?? '';

  const color = product?.getSelectedAttributeByType(
    VariationAttributeType.Color
  );

  const sizeAttribute = product?.getSelectedAttributeByType(
    VariationAttributeType.Size
  );

  const group = product?.groupSize.group;
  const size = product?.groupSize.size;

  const genderValue = group ? toGroupString(group, sku) : null;
  const genderAndSizeDisplayText = genderValue
    ? msgf(cart_cartItem_genderAndSize, {
        size: size ?? '?',
        gender: genderValue ?? ''
      })
    : size;

  return (
    <div className={S.details}>
      {sizeAttribute?.name ? (
        <span>
          {variant !== 'simple' ? genderAndSizeDisplayText : genderValue}
        </span>
      ) : (
        <SkeletonLoader className={S.sizeLoader} />
      )}

      {color?.name ? (
        `${msg(cart_cartItem_color)}
      : ${color.name}`
      ) : (
        <SkeletonLoader className={S.colorLoader} />
      )}

      {variant === 'simple' && (
        <span>{msgf(cart_cartItem_sizeDisplay, { size: size ?? '' })}</span>
      )}

      {variant !== 'simple' && product && (
        <span className={S.inStock}>{product.availabilityStateText}</span>
      )}

      {showActions && onClickEdit && product && (
        <Button variant="text" onClick={() => onClickEdit(product)}>
          {msg(cart_cartItem_editDetails)}
        </Button>
      )}
    </div>
  );
});
