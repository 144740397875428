import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { errorFactory } from '@/utils/error-utils';

export const { InvalidReturnAddressError } = errorFactory(
  'InvalidReturnAddressError',
  {
    mapsToHTTPError: 'BadRequestError'
  } as IHTTPErrorMetaData
);

/** Error to use when a return address is deemed invalid. */
export type InvalidReturnAddressError = InstanceTypeOf<
  typeof InvalidReturnAddressError
>;
