/**
 * The type of a payment used for an order for the purposes of a summary.
 *
 * When updating this enum, be sure to update `AWSOrderLookupService.getPaymentSummaryType()`.
 */
export enum PaymentSummaryType {
  CreditCard = 'CreditCard',
  ApplePay = 'ApplePay',
  Afterpay = 'Afterpay',
  PayPal = 'PayPal',
  GiftCard = 'GiftCard',
  Unknown = 'Unknown'
}
