'use client';

import { makeAutoObservable } from 'mobx';
import { useCallback, useState } from 'react';

/** Models the tuple returned by {@link useViewModel} */
export type ViewModelState<T extends Record<string, unknown> | Array<unknown>> =
  [
    /**
     * The mutable and observable "view model" object. This object can be free mutated and
     * observer components will re-render as necessary to the mutations.
     */
    T,
    /**
     * The `replaceViewModel` function. This should generally not be needed as the view
     * models are freely mutable without any worries of state de-synchronization. Use this
     * function if you need to fundamentally replace the whole view model object with a new object.
     *
     * @param viewModel - The new view model object or array literal to replace the existing
     * view model.
     * @returns The newly replaced view model.
     */
    (viewModel: T) => T
  ];

/**
 * `useViewModel` is used for creating observable "view models". Essentially an object of
 * observable key / values pair where "observer" components will automatically re-render
 * when an observable value in the view model updates.
 *
 * These values are memoized, so
 * updates to the view model that result in the same value do _not_ re-render the
 * component.
 *
 * Additionally, accessors like "getters" and methods that depend on the values
 * of other observable values will update automatically when those values do, thus causing
 * a re-render as expected.
 *
 * The benefit to all of this is avoiding the need to use many repeated hooks such as
 * `useState` and `useRef` - this can replace both hooks.
 *
 * **Note**: The view model is not "deeply" observable, so any nested objects or arrays
 * will also need to use this hook to wrap them.
 *
 * **Note**: A React component that uses these values most be wrapped in the MobX
 * "observer" higher order component, as this is what subscribes the component to MobX
 * observable updates - the underlying library this hook uses.
 *
 * @param viewModel - An object literal or an array whose values will be observable. Any
 * nested objects and arrays will also need to be wrapped in this hook if they too should
 * be observable.
 *
 * @returns The observable "view model".
 *
 * @example
 * ```tsx
 * export const Counter: FunctionComponent = () => {
 *   // Create an observable view model.
 *   const [vm] = useViewModel({
 *     value: 0,
 *     get halvedValue(): number {
 *       if (this.value === 0) return 0;
 *       return this.value / 2;
 *     }
 *   });
 *
 *   return <>
 *     <button onClick={() => vm.value++}>+</button>
 *     <button onClick={() => vm.value--}>-</button>
 *     <p> The current value is: {vm.value} </p>
 *     <p> The current value divided by "2" is: {vm.halvedValue} </p>
 *   </>
 * }
 * ```
 */
export const useViewModel = <
  T extends Record<string, unknown> | Array<unknown>
>(
  viewModel: T
): ViewModelState<T> => {
  const [vm, setVM] = useState(() => makeAutoObservable(viewModel));
  const setter = useCallback((newViewModel: T) => {
    setVM(makeAutoObservable(newViewModel));
    return newViewModel;
  }, []);
  return [vm, setter];
};
