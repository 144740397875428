import { action, computed, makeObservable, observable } from 'mobx';
import { ZodError } from 'zod';

import { DTO, Nullable, Writeable } from '@/type-utils';

import {
  InvalidArgumentError,
  InvalidOperationError,
  InvalidStateError,
  UnableToAccessResourceError
} from '@/utils/errors';
import ProductService from '@/services/isomorphic/ProductService';
import ReturnsLabelService, {
  ILabelAddressData,
  IReturnLabel,
  LabelOption
} from '@/services/isomorphic/ReturnsLabelService';

import type {
  HistoricOrder,
  IHistoricOrder,
  IMaskedHistoricOrder
} from '@/services/isomorphic/OrderLookupService/data-structures';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { Country } from '@/constructs/Country';
import OrderLookupService from '@/services/isomorphic/OrderLookupService';
import { Province } from '@/constructs/provinces/Province';
import type { InvalidReturnAddressError } from '@/services/isomorphic/ReturnsLabelService/errors';
import Model from '../../Model';
import type { IAddressWithPhoneNumber } from '../../Address';
import { ReturnsFlowStateSchema } from './returns-schemas';
import { type IReturnOrder, ReturnOrderType, ReturnReason } from '../order';
import ReturnItemType from '../order/item/ReturnItemType';
import { InvalidReturnsFlowStateDataError } from './InvalidReturnsFlowStateDataError';

import IReturnsFlowState from './IReturnsFlowState';
import RETURNS_FLOW_STEP_ORDER from './RETURNS_FLOW_STEP_ORDER';
import ReturnsFlowStep from './ReturnsFlowStep';

import { ReturnItem } from '../order/item';
import { ProductModel, ProductType } from '../../Product';
import { ReturnItemUPCs } from './ReturnItemUPCs';
import type { IOrderLine } from '../../Order';
import { IOrderHistory, OrderHistoryModel } from '../../OrderHistory';
import type { IMaskedShipment } from '../../Shipment';

/**
 * # ReturnsFlowStateModel.
 *
 * Represents a **Returns Flow State**, an object that manages the state of a returns flow.
 *
 * In a similar fashion to an [MVVM View Model](https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93viewmodel),
 * this Model acts like an abstraction layer; exposing properties and methods that facilitate
 * the interactions between the UI and the Returns Wizard Flow.
 *
 * However, unlike a View Model, this model is meant to handle the state for _all_ the returns flow views. The
 * Model to View relationship is one to many.
 *
 * ## Key Features.
 *
 * ### Session Storage Persistence.
 *
 * Being a Model, a `ReturnsFlowStateModel` can be turned into and constructed from an `IReturnsFlowState` DTO.
 * This is particularly useful for persisting the state of the Returns Flow in the session storage.
 * For instance, say a user wants to do an uneven exchange (i.e. Exchange an item for another item of a different model).
 * The user should be allowed to browse the page in Exchange Mode, in which the "Add to Cart" button is replaced for
 * an "Add to Exchange" button in Product Details pages. This would mean that the state of the Returns Flow must be
 * saved somewhere before leaving the Returns Wizard. Being able to turn this Model into a DTO, save it to the session storage, and
 * then restore it when needed fulfills that need perfectly.
 *
 * ### Easy reference and observability.
 *
 * The {@link ReturnsFlowStateModel.fromSessionStorage} static method and the {@link useReturnsFlowState} helper hook make it
 * easy to reference the Returns Flow saved to the session storage from anywhere.
 * Not to mention that `ReturnsFlowStateModel` is MobX-observable, which means that it can make UI components react to
 * state changes; making it a drop-in solution for reading and manipulating the Returns Flow state from the UI.
 *
 * ## How to Use.
 *
 * Important Note: The `ReturnsFlowStateModel` is meant to manage the state of a Returns Flow as described in this
 * [diagram](https://lucid.app/lucidchart/cfa7feaa-9c39-429f-9fe0-d8ebb6a86375/edit?viewport_loc=810%2C-86%2C2304%2C1183%2C0_0&invitationId=inv_b5793945-e31d-43d4-b1dc-75a752a1fff8).
 *
 * ### Step 1: Create a ReturnsFlowStateModel.
 *
 * You can obtain a new ReturnsFlowStateModel by either:
 *
 * - Calling the {@link ReturnsFlowStateModel.create create} static method.
 * - Calling the {@link ReturnsFlowStateModel.fromSessionStorage fromSessionStorage} static method, which will act exactly the same as
 *   the {@link ReturnsFlowStateModel.create create} method if no `IReturnsFlowState` DTO is found in the session storage.
 * - Using the {@link useReturnsFlowState} hook in a React component, which uses {@link ReturnsFlowStateModel.fromSessionStorage fromSessionStorage}
 *   under the hood.
 *
 * ### Step 2: Load an Order.
 *
 * Before being able to select which items to return, the original order must be loaded into the model.
 * For this, use the {@link ReturnsFlowStateModel.loadOrder loadOrder} method.
 *
 * ```ts
 * const returnsFlowState = useReturnsFlowState();
 *
 * const onSelectOrder = useCallback((order: IHistoricOrder) => {
 *    // useReturnsFlowState will return null if the data hasn't been loaded from session storage yet.
 *    if (returnsFlowState) {
 *        returnsFlowState.loadOrder(returnsFlowState);
 *    }
 * }, []);
 * ```.
 *
 * This step is usually done when the customer is presented a list of their recent orders and prompted to select
 * one to start the return from.
 *
 * ### Step 3: Select items to return.
 *
 * Now that the order has been loaded in, you must select the subset of its items that will be returned or
 * exchanged. For this, use the {@link ReturnsFlowStateModel.selectItemsToReturn selectItemsToReturn} method, which takes an
 * array with the SKUs of the items that will be selected for return.
 *
 * ```ts
 * const onSelectReturnItems = useCallback((returnItemSKUs: Array<string>) => {
 *    if (returnsFlowState) {
 *        returnsFlowState.selectItemsToReturn(returnItemSKUs);
 *    }
 * }, []);
 * ```.
 *
 * ### Step 4: Select a return type for each item to return.
 *
 * After selecting which items will be returned, a {@link ReturnItemType return type} must be selected for each one.
 *
 * #### Refunds and Even Exchanges.
 *
 * Use the {@link ReturnsFlowStateModel.currentItem currentItem} attribute to identify the item the selection is currently being
 * made for, and the {@link ReturnsFlowStateModel.selectItemReturnType selectItemReturnType} method to select the desired return type.
 *
 * If the desired exchange item is of a different variant (maybe the user wants a different color or size),
 * specify its SKU as the third parameter.
 *
 * ```ts
 * const onSelectRefund = useCallback((returnReason: ReturnReason) => {
 *    returnsFlowState.selectItemReturnType(ReturnItemType.Refund, returnReason);
 * }, []).
 *
 * const onSelectSameSKUExchange = useCallback((returnReason: ReturnReason) => {
 *    returnsFlowState.selectItemReturnType(ReturnItemType.SameSKUExchange, returnReason);
 * }, []).
 *
 * const onSelectSameModelExchange = useCallback((returnReason: ReturnReason, exchangeItemSKU: string) => {
 *    returnsFlowState.selectItemReturnType(ReturnItemType.SameSKUExchange, returnReason, exchangeItemSKU);
 * }, [])
 * ```
 *
 * #### Uneven Exchanges.
 *
 * The flow for uneven exchanges is a tad bit more complex. It looks like this:
 *
 * 1. Call the {@link ReturnsFlowStateModel.startExchange startExchange} to enable Exchange Mode.
 * 2. Redirect the customer to the page so they can select which product to receive in exchange.
 * 3. When the customer selects a product, call the {@link ReturnsFlowStateModel.completeExchange completeExchange} method,
 *    which will record the customer's selection and disable Exchange Mode.
 * 4. Redirect the customer back to the Returns Wizard.
 *
 * ```ts
 * const onSelectDifferentModelExchange = useCallback((returnReason: ReturnReason) => {
 *    // This return reason will be stored in the model until the exchange is completed.
 *    returnsFlowState.startExchange(returnReason);
 *
 *    // Redirect the customer to the "All" category. Exchange Mode will now be enabled.
 *    router.push("/c/all")
 * }, []);
 *
 *
 * // On the PDP...
 * const onAddToCart = useCallback((itemSKU: string) => {
 *    // Complete the exchange selection by passing the SKU of the selected item.
 *    // This will disable Exchange Mode.
 *    returnsFlowState.completeExchange(itemSKU, ReturnItemType.DifferentModelExchange);
 *
 *    // Redirect back to the Returns Wizard.
 *    router.push("/returns/select-return-type")
 * }, []);
 * ```
 *
 * ### Step 5: Review.
 *
 * When a return type has been selected for the last item, the Returns Wizard can now
 * progress to the Review step.
 *
 * By this point, the {@link ReturnsFlowStateModel.returnOrder returnOrder} attribute should now be complete.
 * You can double check this by making sure the {@link ReturnsFlowStateModel.isReturnOrderComplete isReturnOrderComplete} is `true`.
 *
 * This step does not require any input to the `ReturnsFlowStateModel`. The advised next
 * step from here is to send the {@link ReturnsFlowStateModel.returnOrder Return order} to the Returns Service if the customer
 * decides that everything is good to go.
 *
 *
 * ### Step 6: Confirmation.
 *
 * The return order has been sent. The customer will now see a confirmation page.
 * Congratulations! You carried out a Returns Flow all the way to the end!
 */
export default class ReturnsFlowStateModel
  extends Model<DTO<IReturnsFlowState>>
  implements IReturnsFlowState
{
  @observable private _currentStep: ReturnsFlowStep;

  @observable private _emailAddressOrOrderNumber: Nullable<string>;
  @observable private _billingZipCode: Nullable<string>;
  @observable private _orderHistory: Nullable<OrderHistoryModel<HistoricOrder>>;

  @observable private _isOrderLoaded: boolean;
  @observable private _isExchangeModeActive: boolean;

  @observable private _returnOrder?: Nullable<Partial<Writeable<IReturnOrder>>>;
  @observable private _order?: Nullable<HistoricOrder>;
  @observable private _orderItems?: Nullable<Array<IOrderLine>>;
  @observable private _itemsSelectedForReturn?: Nullable<Array<string>>;
  @observable private _itemsPendingReturnTypeSelection?: Nullable<
    Array<string>
  >;

  @observable private _currentItem?: Nullable<string>;
  @observable private _storedReturnReason?: Nullable<ReturnReason>;
  @observable private _labelOption?: LabelOption;

  @observable private _returnLabel?: IReturnLabel;

  @observable private _errorMessage: Nullable<string> = null;

  /**
   * Is the current returns label using a QR code image instead of the normal
   * shipping label.
   * @returns `true` if the label is a QR code, `false` otherwise.
   */
  public get isQRCode(): boolean {
    return this._labelOption === LabelOption.QRCode;
  }

  /**
   * The current error message that can be used to display an error related to the
   * ReturnsFlowStateModel. Once it is read it will be made null.
   * @returns A nullable string.
   */
  @action public getErrorMessage(): Nullable<string> {
    const currentMessage = this._errorMessage;
    this._errorMessage = null;

    return currentMessage;
  }

  /**
   * Evaluate if a provided {@link ReturnItemType return type} represents an even exchange.
   * @param returnType - A {@link ReturnItemType} value to evaluate.
   *
   * @returns
   * - `true` if the return type is an even exchange.
   * - `false` if it is an uneven exchange.
   * - `undefined` if the return type is not an exchange type at all.
   */
  private isEvenExchange(returnType: ReturnItemType): Nullable<boolean> {
    switch (returnType) {
      // The item will represent an even exchange if the type is a same sku or same model exchange.
      case ReturnItemType.SameSKUExchange:
      case ReturnItemType.SameModelExchange:
        return true;

      // Use `false` if the item is an uneven exchange.
      case ReturnItemType.DifferentModelExchange:
        return false;

      // If it is neither of these types, the item is not an exchange at all, so we return
      // `undefined` rather than `false` to denote this.
      default:
        return undefined;
    }
  }

  /**
   * Creates a new, uninitialized {@link ReturnsFlowStateModel}.
   *
   * Use this method when starting a returns flow from scratch.
   * If you want to use a DTO to restore existing returns flow data,
   * use the constructor or {@link ReturnsFlowStateModel.from} instead.
   *
   * @returns A new, uninitialized {@link ReturnsFlowStateModel}.
   */
  public static create(): ReturnsFlowStateModel {
    const config = ConfigurationService.getConfig('returns');
    const isQRCodeEnabled = config.getSetting('enableQRCodes');
    const isQRCodeDefault = config.getSetting('qrCodeDefault');

    let labelOption = LabelOption.ShippingLabel;

    if (isQRCodeEnabled && isQRCodeDefault) {
      labelOption = LabelOption.QRCode;
    }

    return new ReturnsFlowStateModel({
      // Default step is the first step.
      currentStep: ReturnsFlowStep.OrderLookup,
      labelOption,
      isOrderLoaded: false,
      haveItemsToReturnBeenSelected: false,
      isExchangeModeActive: false,
      isReturnTypeSelectionComplete: false,
      isReturnOrderComplete: false
    });
  }

  /**
   * Attempts to build a model from an existing {@link IReturnsFlowState} DTO
   * stored in the session storage.
   *
   * If no DTO is found, a new model will be {@link ReturnsFlowStateModel.create created}.
   *
   * @returns A {@link ReturnsFlowStateModel}, either built from existing data in the session storage
   * if available, or a new one.
   */
  public static fromSessionStorage(): ReturnsFlowStateModel {
    // If not, create a new one.
    return (
      // Use returns flow data in session storage if found.
      ReturnsLabelService.getFlowStateFromSessionStorage() ??
      // Make a new model if not found.
      ReturnsFlowStateModel.create()
    );
  }

  /**
   * Clears the existing {@link IReturnsFlowState} DTO stored in the session storage (if any)
   * by storing a fresh, new one.
   * @returns The newly created. blank {@link ReturnsFlowStateModel}.
   */
  public static clearSessionFlowState(): ReturnsFlowStateModel {
    const newModel = ReturnsFlowStateModel.create();
    newModel.saveToSessionStorage();

    return newModel;
  }

  /**
   * Gets a URL for a provided step in the flow. Useful for
   * sending the user to a specific step in the flow.
   *
   * @param step - The {@link ReturnsFlowStep step} to go to.
   * @param error
   * @returns A {@link URL} object.
   */
  public static getStepURL(step: ReturnsFlowStep, error?: string): URL {
    const { origin } = EnvironmentService.url;

    const baseFlowLocation =
      ConfigurationService.getConfig('returns').getSetting(
        'flow.baseLocation'
      ).value;

    // ReturnsFlowSteps are used directly in URLs
    const url = error
      ? `${baseFlowLocation}/${step}?error=${error}`
      : `${baseFlowLocation}/${step}`;
    return new URL(url, origin);
  }

  /**
   * For a given step, gets its number in the flow.
   *
   * @param step - The {@link ReturnsFlowStep step} to get the number for.
   * @returns The step number.
   */
  public static getStepNumber(step: ReturnsFlowStep): number {
    return RETURNS_FLOW_STEP_ORDER[step];
  }

  /** @inheritdoc */
  public constructor(dto: DTO<IReturnsFlowState>) {
    super(dto);

    try {
      const parsedDTO = ReturnsFlowStateSchema.parse(
        dto
      ) as DTO<IReturnsFlowState>;

      this._currentStep = parsedDTO.currentStep;

      this._isOrderLoaded = parsedDTO.isOrderLoaded;
      this._isExchangeModeActive = parsedDTO.isExchangeModeActive;
      this._returnOrder = parsedDTO.returnOrder as Nullable<
        Partial<IReturnOrder>
      >;

      this._order = parsedDTO.order;
      this._orderItems = parsedDTO.orderItems && [...parsedDTO.orderItems];
      this._orderHistory =
        parsedDTO.orderHistory &&
        OrderHistoryModel.from(
          parsedDTO.orderHistory as DTO<IOrderHistory<HistoricOrder>>
        );

      this._itemsSelectedForReturn = parsedDTO.itemsSelectedForReturn && [
        ...parsedDTO.itemsSelectedForReturn
      ];

      this._itemsPendingReturnTypeSelection =
        parsedDTO.itemsPendingReturnTypeSelection && [
          ...parsedDTO.itemsPendingReturnTypeSelection
        ];

      this._currentItem = parsedDTO.currentItem;

      this._labelOption = parsedDTO.labelOption ?? LabelOption.ShippingLabel;

      makeObservable(this);
    } catch (error) {
      if (error instanceof ZodError) {
        LoggerService.error(
          new InvalidReturnsFlowStateDataError(
            'Error constructing ReturnsFlowStateModel:' +
              ' The passed DTO does not satisfy the configured schema.',
            { cause: error }
          )
        );

        /**
         * When this fails we should abandon the state.
         * Going to order lookup (first step) will practically
         * reset the flow state.
         *
         * @see {@link resetFlowToStep}
         */
        this.goTo(ReturnsFlowStep.OrderLookup);

        // Assign required properties here in the constructor to appease the
        // Typescript gods.
        this._currentStep = ReturnsFlowStep.OrderLookup;
        this._isOrderLoaded = false;
        this._isExchangeModeActive = false;
      } else {
        throw error;
      }
    }
  }

  /**
   * Saves this flow state to session storage.
   */
  private saveToSessionStorage(): void {
    ReturnsLabelService.saveFlowStateToSessionStorage(this);
  }

  /**
   * Partially resets the flow up to the selected step by clearing any data
   * that should be entered from that step onwards.
   *
   * If a step is not specified, the {@link currentStep current step} will be used instead.
   *
   * @param step - The {@link ReturnsFlowStep step} to reset the flow to.
   *
   * @see The {@link clearSessionFlowState} method if you want to completely reset the flow.
   */
  @action public resetFlowToStep(step?: ReturnsFlowStep): void {
    const stepToResetTo = step ?? this.currentStep;
    const stepNumber = RETURNS_FLOW_STEP_ORDER[stepToResetTo];

    // Pattern-matching switch
    // Good resource on this: https://kyleshevlin.com/pattern-matching
    switch (true) {
      // If the step is OrderLookup or an earlier step...
      case stepNumber <= RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.OrderLookup]: {
        this._emailAddressOrOrderNumber = undefined;
        this._billingZipCode = undefined;
        this._orderHistory = undefined;

        // falls through
      }

      // If the step is OrderHistory or an earlier step...
      case stepNumber <=
        RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.OrderHistory]: {
        this._isOrderLoaded = false;
        this._order = undefined;
        this._orderItems = undefined;
        this._itemsSelectedForReturn = undefined;
        this._itemsPendingReturnTypeSelection = undefined;

        // falls through
      }

      // If the step is OrderDetails or an earlier step...
      case stepNumber <=
        RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.OrderDetails]: {
        this._currentItem = undefined;
        this._returnOrder = undefined;

        // falls through
      }

      // If the step is ReturnTypeSelect or an earlier step...
      case (stepNumber ===
        RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.ReturnTypeSelect] &&
        !this.hasPreviousItem()) ||
        stepNumber <
          RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.ReturnTypeSelect]: {
        // Only reset if not popping an item from the return type
        // selection stack.
        this._isExchangeModeActive = false;
        this._storedReturnReason = undefined;

        // falls through
      }

      // If the step is Review or an earlier step...
      case stepNumber <= RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.Review]: {
        // Nothing here, but leaving the case for the future.
        // falls through
      }

      // If the step is Confirmation or an earlier step...
      case stepNumber <=
        RETURNS_FLOW_STEP_ORDER[ReturnsFlowStep.Confirmation]: {
        // Nothing here, but leaving the case for the future.
        // falls through
      }

      default:
        break;
    }
  }

  /**
   * Goes to the specified step.
   *
   * Will also:
   * - Trigger state changes on any observer components.
   * - {@link resetFlowToStep Reset the flow to the specified step}.
   *
   * @param step - The {@link ReturnsFlowStep step} to go to.
   */
  @action public goTo(step: ReturnsFlowStep): void {
    switch (step) {
      case ReturnsFlowStep.OrderLookup:
        break;

      case ReturnsFlowStep.OrderHistory:
        // Check that an order history has been loaded
        break;

      case ReturnsFlowStep.OrderDetails:
        // Check that an order has been loaded
        if (!this.isOrderLoaded) {
          this.goBack();
          return;
        }
        break;

      case ReturnsFlowStep.ReturnTypeSelect:
        if (this.isReturnTypeSelectionComplete) {
          this.previousItem();
        }

        break;

      case ReturnsFlowStep.Review:
        // Check that return order is complete
        break;

      case ReturnsFlowStep.Confirmation:
        // Check that return order is confirmed.
        break;

      default:
        break;
    }

    this.resetFlowToStep(step);
    this._currentStep = step;
    this.saveToSessionStorage();
  }

  /**
   * Goes back one step.
   *
   * Will also:
   * - Trigger state changes on any observer components.
   * - {@link resetFlowToStep Reset the flow to the new step}.
   */
  @action public goBack(): void {
    switch (this._currentStep) {
      case ReturnsFlowStep.OrderLookup:
        break;

      case ReturnsFlowStep.OrderHistory:
        this.goTo(ReturnsFlowStep.OrderLookup);
        break;

      case ReturnsFlowStep.OrderDetails:
        if (this._orderHistory) {
          this.goTo(ReturnsFlowStep.OrderHistory);
        } else {
          this.goTo(ReturnsFlowStep.OrderLookup);
        }
        break;

      case ReturnsFlowStep.ReturnTypeSelect:
        // If there is a previous item, go back to that item.
        if (this.hasPreviousItem()) {
          this.previousItem();
        } else {
          // If not, go back to the order details screen.
          this.goTo(ReturnsFlowStep.OrderDetails);
        }

        break;

      case ReturnsFlowStep.Review:
        this.goTo(ReturnsFlowStep.ReturnTypeSelect);
        break;

      case ReturnsFlowStep.Confirmation:
        this.goTo(ReturnsFlowStep.Review);
        break;

      default:
        break;
    }
  }

  /**
   * Gets a URL for the current step in the flow. Useful for
   * coming back to the returns flow after leaving temporarily.
   *
   * @returns A {@link URL} object.
   */
  public getCurrentStepURL(): URL {
    return ReturnsFlowStateModel.getStepURL(this._currentStep);
  }

  /**
   * Determines if there is a next item in the
   * {@link ReturnsFlowStateModel.itemsPendingReturnTypeSelection `itemsPendingReturnTypeSelection`} array.
   *
   * @returns A value of `true` if there is indeed a next item in the array.
   *
   * @throws An {@link InvalidStateError} if an order hasn't been loaded to the Model yet, or if the items
   * to return haven't been selected yet.
   */
  public hasNextItem(): boolean {
    if (!this._isOrderLoaded || !this._itemsPendingReturnTypeSelection) {
      throw new InvalidStateError(
        'Cannot determine if there is a next item. Please load an order into the model and select the items to return first.'
      );
    }

    if (this._itemsPendingReturnTypeSelection.length <= 0) {
      return false;
    }

    return true;
  }

  /**
   * Gets the count of items pending return type selection.
   * @returns The count of items pending return type selection.
   */
  private get itemsPendingReturnTypeSelectionCount(): number {
    if (
      !this._isOrderLoaded ||
      !this._itemsPendingReturnTypeSelection ||
      !this._itemsSelectedForReturn
    ) {
      throw new InvalidStateError(
        'Cannot determine if there is a previous item. Please load an order into the model and select the items to return first.'
      );
    }

    let pendingItems = this._itemsPendingReturnTypeSelection.length;

    // Add one to account for the current item if present.
    if (this.currentItem) {
      pendingItems += 1;
    }

    return pendingItems;
  }

  /**
   * Determines if there is a previous item.
   *
   * @returns A value of `true` if there is indeed a next item in the array.
   *
   * @throws An {@link InvalidStateError} if an order hasn't been loaded to the Model yet, or if the items
   * to return haven't been selected yet.
   */
  public hasPreviousItem(): boolean {
    if (
      !this._isOrderLoaded ||
      !this._itemsPendingReturnTypeSelection ||
      !this._itemsSelectedForReturn
    ) {
      throw new InvalidStateError(
        'Cannot determine if there is a previous item. Please load an order into the model and select the items to return first.'
      );
    }

    // Add one to account for the current item.
    const pendingItems = this.itemsPendingReturnTypeSelectionCount;
    const allItems = this._itemsSelectedForReturn.length;

    if (pendingItems < allItems) {
      return true;
    }

    return false;
  }

  /**
   * Returns the next item's SKU in the
   * {@link ReturnsFlowStateModel.itemsPendingReturnTypeSelection `itemsPendingReturnTypeSelection`} array,
   * or `null` if there is no next item.
   *
   * This method automatically saves the updated flow state to the session storage.
   *
   * @returns The next item's SKU, or `null`.
   *
   * @throws An {@link InvalidStateError} if an order hasn't been loaded to the Model yet, or if the items
   * to return haven't been selected yet.
   */
  @action public nextItem(): Nullable<string> {
    if (!this._itemsPendingReturnTypeSelection) {
      throw new InvalidOperationError(
        'Cannot get the next item. Please load an order into the model and select the items to return first.'
      );
    }

    if (this.hasNextItem()) {
      this._currentItem =
        this._itemsPendingReturnTypeSelection.shift() as string;
    } else {
      this._currentItem = null;

      // Manually set step to Review since return type selection is complete.
      this._currentStep = ReturnsFlowStep.Review;
    }

    this.saveToSessionStorage();
    return this._currentItem;
  }

  /**
   * Returns the previous item.
   *
   * This method automatically saves the updated flow state to the session storage.
   *
   * @returns The next item's SKU, or `null`.
   *
   * @throws An {@link InvalidStateError} if an order hasn't been loaded to the Model yet, or if the items
   * to return haven't been selected yet.
   */
  @action public previousItem(): Nullable<string> {
    if (
      !this._itemsSelectedForReturn ||
      !this._itemsPendingReturnTypeSelection
    ) {
      throw new InvalidOperationError(
        'Cannot get the next item. Please load an order into the model and select the items to return first.'
      );
    }

    if (this.hasPreviousItem()) {
      const originalItemCount = this._itemsSelectedForReturn.length;
      const pendingItemCount = this.itemsPendingReturnTypeSelectionCount;

      const previousItemIdx = originalItemCount - pendingItemCount - 1;

      // If there is a current item already, save add it back at the head of
      // the pending items array.
      if (this._currentItem) {
        this._itemsPendingReturnTypeSelection = [
          this._currentItem,
          ...this._itemsPendingReturnTypeSelection
        ];
      }

      this._currentItem = this._itemsSelectedForReturn[previousItemIdx];

      this._returnOrder?.returnItems?.pop();
    }

    this.saveToSessionStorage();
    return this._currentItem;
  }

  /**
   * Loads an order history into the flow state to allow the customer
   * to select which one to start a return for.
   *
   * This step is optional; you can directly load an order to the
   * flow state.
   *
   * @param orderHistory - The {@link IOrderHistory order history} to load.
   */
  @action public loadOrderHistory(
    orderHistory: OrderHistoryModel<HistoricOrder>
  ): void {
    this._orderHistory = orderHistory;
    this.goTo(ReturnsFlowStep.OrderHistory);
  }

  /**
   * Loads an order into the flow state.
   * **This step is required before selecting the items to return**.
   *
   * @param order - The order to load.
   * @throws An {@link InvalidArgumentError} if the specified order has not been delivered yet.
   */
  @action public loadOrder(order: HistoricOrder): void {
    const { orderID, shipments } = order;

    if (!OrderLookupService.isOrderReturnable(order)) {
      throw new InvalidArgumentError(
        `Cannot load non-returnable order "${orderID}" into the returns flow state model.`
      );
    }

    // TODO: Break down this card so it represents shipments. The order itself has no status.
    const { status } = order.shipments[0];

    // Flatten all items from all shipments
    const orderItems = (shipments as Array<IMaskedShipment>).reduce<
      Array<IOrderLine>
    >((prev, shipment) => {
      for (const item of shipment.items) {
        const { quantity } = item;
        const newItems: Array<IOrderLine> = Array(quantity).fill(item);
        prev.push(...newItems);
      }

      return prev;
    }, []);

    this._order = order;
    this._orderItems = orderItems;
    this._itemsPendingReturnTypeSelection = orderItems.map((i) => i.sku);
    this._itemsSelectedForReturn = [];

    this._isOrderLoaded = true;
    this.goTo(ReturnsFlowStep.OrderDetails);
  }

  /**
   * Selects (by SKU) the subset of the loaded order's items that
   * will be returned.
   *
   * **This is the second required step**, but you must first
   * {@link ReturnsFlowStateModel.loadOrder load an order} into the flow for it to succeed.
   *
   * @param itemSKUs - The SKUs of the items that are going to be returned.
   *
   * @throws An {@link InvalidStateError} if an order hasn't been loaded in.
   * @throws An {@link InvalidArgumentError} if a passed SKU is not found in the order's items.
   */
  @action public selectItemsToReturn(itemSKUs: Array<string>): void {
    if (!this._orderItems) {
      throw new InvalidStateError(
        'Cannot select return items for order. Please initialize the return order first.'
      );
    }

    for (const sku of itemSKUs) {
      // If there is no item with this SKU in the orderItems array
      if (!this._orderItems.some((item) => item.sku === sku)) {
        throw new InvalidArgumentError(
          `Cannot select item with SKU "${sku}" to return in order "${this._order?.orderID}" since it was not found in the order's item list.`
        );
      }
    }

    // TODO: Throw if a shipment is selected for return that is not returnable.
    // Should not be selectable in the UI.

    // If the execution reaches this point, all of the items exist
    // in the order.
    this._itemsSelectedForReturn = itemSKUs;
    this._itemsPendingReturnTypeSelection = itemSKUs;

    // Initialize the return order.
    this._returnOrder = {
      originalOrderID: this._order?.orderID,
      returnItems: [],

      shippingAddress: this.prefillShippingAddress()
    };

    this.nextItem();
  }

  /**
   * Prefills the shipping address for the return order. If the config
   * `showShippingAddress` is set to `false` the default shipping address will be
   * used instead of the order shipping address.
   * @returns The pre-filled shipping address.
   */
  private prefillShippingAddress(): Nullable<IAddressWithPhoneNumber> {
    const returnsConfig = ConfigurationService.getConfig('returns');

    /** Whether to show the shipping address to the end-user and allow them to edit it. */
    const showShippingAddress = returnsConfig.getSetting(
      'showShippingAddress'
    ).value;

    /** The fallback shipping address to use if the user should not choose a shipping address. */
    const defaultShippingAddress = returnsConfig.getSetting(
      'defaultShippingAddress'
    ).value;

    // If we're disabling the ability for the customer to see, enter, or edit the shipping
    // address, then fallback to a pre-defined address. This is typically the warehouse
    // address.
    if (!showShippingAddress) {
      return {
        addressLine1: defaultShippingAddress.street.value,
        city: defaultShippingAddress.city.value,
        country: defaultShippingAddress.country.value as Country,
        stateProvince: defaultShippingAddress.state.value as Province,
        zipPostalCode: defaultShippingAddress.zipCode.value,
        phoneNumber: defaultShippingAddress.phoneNumber.value,
        firstName: defaultShippingAddress.firstName.value,
        lastName: defaultShippingAddress.lastName.value
      };
    }

    /**
     * As `shippingAddress` is a nullable field, we can cast the type to the
     * order object to be a `HistoricOrder` and we can have access to the first best
     * shipping address there.
     *
     * If the order is a `MaskedHistoricOrder` then the shipping address will be null,
     * no problem, we can fill in that through the UI form later.
     */
    const order = this._order as IHistoricOrder;

    return order.shipments[0].shippingAddress;
  }

  /**
   * Selects a return type for the {@link ReturnsFlowStateModel.currentItem current item}.
   *
   * Only the return reason has to be provided for `Refund`
   * and `SameSKUExchange` return types.
   *
   * After recording the selection, the model will automatically
   * proceed to the {@link ReturnsFlowStateModel.nextItem next item}.
   *
   * @param returnType - The type of this return. Must be a member of the {@link ReturnItemType} enum.
   * @param returnReason - The reason for this return. Must be a member of the {@link ReturnReason} enum.
   */
  public async selectItemReturnType(
    returnType: ReturnItemType.Refund,
    returnReason: ReturnReason
  ): Promise<void>;

  /**
   * Selects a return type for the {@link ReturnsFlowStateModel.currentItem current item}.
   *
   * The `exchangeItemSKU` parameter must be provided for exchange return types (with
   * the exception of `SameSKUExchange`).
   *
   * After recording the selection, the model will automatically
   * proceed to the {@link ReturnsFlowStateModel.nextItem next item}.
   *
   * @param returnType - The type of this return. Must be a member of the {@link ReturnItemType} enum.
   * @param returnReason - The reason for this return. Must be a member of the {@link ReturnReason} enum.
   * @param [exchangeItemSKU] - For exchanges, the SKU of the item to be sent in exchange.
   */
  public async selectItemReturnType(
    returnType: ReturnItemType,
    returnReason: ReturnReason,
    exchangeItemSKU: string
  ): Promise<void>;

  /**
   * Selects a return type for the {@link ReturnsFlowStateModel.currentItem current item}.
   *
   * Only the return reason has to be provided for `Refund` and `SameSKUExchange` return types.
   * The `exchangeItemSKU` parameter must be provided for exchange return types (with the
   * exception of `SameSKUExchange`).
   *
   * After recording the selection, the model will automatically
   * proceed to the {@link ReturnsFlowStateModel.nextItem next item}.
   *
   * @param returnType - The type of this return. Must be a member of the {@link ReturnItemType} enum.
   * @param returnReason - The reason for this return. Must be a member of the {@link ReturnReason} enum.
   * @param [exchangeItemSKU] - For exchanges, the SKU of the item to be sent in exchange.
   *
   * @throws An {@link InvalidStateError} if this method gets called before meeting all of its prerequisites.
   * @throws An {@link InvalidArgumentError} if no `exchangeItemSKU` is supplied for returns types that require it.
   */
  @action public async selectItemReturnType(
    returnType: ReturnItemType,
    returnReason: ReturnReason,
    exchangeItemSKU?: string
  ): Promise<void> {
    if (!this._currentItem) {
      throw new InvalidStateError(
        `Cannot select return type since there is no current item.`
      );
    }

    if (!this._returnOrder?.returnItems) {
      throw new InvalidStateError(
        `Cannot select return type for item "${this._currentItem}". Please initialize the return order first.`
      );
    }

    if (
      !this._itemsSelectedForReturn ||
      !this._itemsPendingReturnTypeSelection
    ) {
      throw new InvalidStateError(
        `Cannot select return type for item "${this._currentItem}". Please select the items to return first.`
      );
    }

    if (!exchangeItemSKU && returnType !== ReturnItemType.Refund) {
      throw new InvalidArgumentError(
        'Exchange Item SKU was not provided. It can only be skipped when making Refund return types.'
      );
    }

    const { itemUPC, exchangeItemUPC } = await this.getReturnItemUPCs(
      this._currentItem,
      exchangeItemSKU
    );

    // Push the return options to the return order.
    this._returnOrder.returnItems.push({
      itemSKU: this._currentItem,
      itemUPC,
      isEvenExchange: this.isEvenExchange(returnType),
      returnType,
      returnReason,
      exchangeItemSKU,
      exchangeItemUPC
    });

    this._isExchangeModeActive = false;
    this._storedReturnReason = null;

    this.nextItem();
  }

  /**
   * Gets an `itemUPC` and `exchangeItemUPC` pair. In order to place returns
   * in AWS we need to provide the UPC code for each product in returns.
   *
   * @param itemSKU - The current item SKU.
   * @param exchangeItemSKU - The exchange item SKU.
   * @throws {@link RequestError} When cannot fetch the products based on SKU.
   * @returns - An object containing `itemUPC` and `exchangeItemUPC`.
   */
  private async getReturnItemUPCs(
    itemSKU: string,
    exchangeItemSKU?: string
  ): Promise<ReturnItemUPCs> {
    const productPromises: Array<Promise<ProductModel<ProductType>>> = [
      ProductService.getProduct(itemSKU)
    ];

    if (exchangeItemSKU) {
      productPromises.push(ProductService.getProduct(exchangeItemSKU));
    }

    const [currentProduct, exchangeProduct] =
      await Promise.allSettled(productPromises);

    if (currentProduct.status === 'rejected') {
      throw new UnableToAccessResourceError(
        `Cannot retrieve current item product for the SKU: "${itemSKU}" to get UPC code.`,
        {
          cause: currentProduct.reason
        }
      );
    }

    if (exchangeProduct && exchangeProduct.status === 'rejected') {
      throw new UnableToAccessResourceError(
        `Cannot retrieve exchange item product for the SKU: "${exchangeItemSKU}" to get UPC code.`,
        {
          cause: exchangeProduct.reason
        }
      );
    }

    return {
      itemUPC: currentProduct.value.upc as string,
      exchangeItemUPC: exchangeProduct?.value.upc
    };
  }

  /**
   * Starts an exchange by enabling exchange mode and storing a return reason
   * in the model. The item to be exchanged will be the {@link IReturnsFlowState.currentItem current item}.
   *
   * To complete the exchange, call {@link ReturnsFlowStateModel.completeExchange the complete exchange method}.
   *
   * @param returnReasonToStore - The {@link ReturnReason return reason} to store in the model, which will
   * be the reason of the resulting {@link ReturnItem} return item.
   */
  public startExchange(returnReasonToStore: ReturnReason): void {
    this._storedReturnReason = returnReasonToStore;
    this._isExchangeModeActive = true;

    this.saveToSessionStorage();
  }

  /**
   * Aborts an exchange by disabling exchange mode and clearing any stored data
   * related to the active exchange from the model. The {@link IReturnsFlowState.currentItem current item} will not be changed.
   */
  public abortExchange(): void {
    this._storedReturnReason = null;
    this._isExchangeModeActive = false;

    this.saveToSessionStorage();
  }

  /**
   * Completes an exchange by disabling exchange mode and saving the
   * return item selection.
   *
   * Be sure to call the start exchange method first.
   *
   * @param exchangeItemSKU - The variant that the customer selected to receive in exchange.
   * @param returnType - The type of this return. Must be a member of the {@link ReturnItemType} enum.
   * Defaults to `ReturnItemType.DifferentModelExchange`.
   *
   * @throws An {@link InvalidStateError} if this method gets called before meeting all of its prerequisites.
   */
  public completeExchange(
    exchangeItemSKU: string,
    returnType: ReturnItemType = ReturnItemType.DifferentModelExchange
  ): void {
    if (!this.isExchangeModeActive) {
      throw new InvalidStateError(
        'Cannot complete item exchange: exchange mode is not enabled. Please make sure to call `startExchange` before calling `completeExchange`.'
      );
    }

    if (!this._storedReturnReason) {
      throw new InvalidStateError(
        'Cannot complete item exchange: no stored return reason was found. Please make sure to call `startExchange` before calling `completeExchange`.'
      );
    }

    this.selectItemReturnType(
      returnType,
      this._storedReturnReason,
      exchangeItemSKU
    );
  }

  /**
   * Evaluates if the {@link IReturnOrder return order} being built by this flow state
   * is {@link IReturnsFlowState.isReturnOrderComplete complete} already and returns it if so.
   *
   * If the return order is not complete yet, it will throw.
   *
   * @returns The {@link IReturnOrder return order} if complete.
   * @throws An {@link InvalidStateError} if the return order is not complete yet.
   *
   * @see The {@link ReturnsFlowStateModel.tryGetCompleteReturnOrder try-get version} of this method for a non-throwing alternative.
   */
  public getCompleteReturnOrder(): IReturnOrder {
    if (this.isReturnOrderComplete) {
      return this._returnOrder as IReturnOrder;
    }

    throw new InvalidStateError(
      `Cannot get complete Return Order: The current order doesn't have all the data necessary to be considered complete.`
    );
  }

  /**
   * Retrieves the {@link IReturnLabel return label} preventing it from being created multiple times.
   *
   * @returns The return label.
   *
   * @throws An {@link InvalidStateError} when the `labelOption` in `flowState` is
   * undefined, or a required field is missing.
   *
   * @throws An {@link InvalidReturnAddressError} when the address stored in the
   * flow state is rejected by the {@link ReturnsLabelService.getLabel `ReturnsLabelService`}.
   */
  public async getReturnLabel(): Promise<IReturnLabel> {
    if (!this._labelOption) {
      throw new InvalidStateError(
        'Cannot get return label without having a label option defined'
      );
    }

    if (
      !this._order?.orderID ||
      !this._returnOrder?.shippingAddress ||
      !this._order?.email
    ) {
      throw new InvalidStateError(
        'Cannot get return label without having order id, order email and shipping address defined'
      );
    }

    if (this._returnLabel) {
      return this._returnLabel;
    }

    const addressData = {
      city: this._returnOrder.shippingAddress.city,
      countryCode: this._returnOrder.shippingAddress.country,
      email: this._order.email,
      firstAddress: this._returnOrder.shippingAddress.addressLine1,
      firstName: this._returnOrder.shippingAddress.firstName,
      lastName: this._returnOrder.shippingAddress.lastName,
      phone: this._returnOrder.shippingAddress.phoneNumber,
      postalCode: this._returnOrder.shippingAddress.zipPostalCode,
      stateProvince: this._returnOrder.shippingAddress.stateProvince,
      secondAddress: this._returnOrder.shippingAddress.addressLine2
    } as ILabelAddressData;

    // They should always have a upc.
    const items =
      this._returnOrder.returnItems?.map((item) => item?.itemUPC ?? '') ?? [];

    // This can throw an `InvalidReturnAddressError`
    const returnLabel = await ReturnsLabelService.getLabel(
      this._order?.orderID,
      addressData,
      this.isQRCode,
      items
    );

    this._returnLabel = returnLabel;

    return this._returnLabel;
  }

  /**
   * Evaluates if the {@link IReturnOrder return order} being built by this flow state
   * is {@link IReturnsFlowState.isReturnOrderComplete complete} already and returns it if so.
   *
   * If the return order is not complete yet, it return a `null` value.
   *
   * @returns The {@link IReturnOrder return order} if complete, or `null` if not.
   *
   * @see The {@link ReturnsFlowStateModel.getCompleteReturnOrder get version} of this method for an execution-stopping alternative.
   */
  public tryGetCompleteReturnOrder(): Nullable<IReturnOrder> {
    if (this.isReturnOrderComplete) {
      return this._returnOrder as IReturnOrder;
    }

    return null;
  }

  /** @inheritdoc */
  @computed public get currentStep(): ReturnsFlowStep {
    return this._currentStep;
  }

  /** @inheritdoc */
  @computed public get emailAddressOrOrderNumber(): Nullable<string> {
    return this._emailAddressOrOrderNumber;
  }

  /** @inheritdoc */
  @computed public get billingZipCode(): Nullable<string> {
    return this._billingZipCode;
  }

  /** @inheritdoc */
  @computed public get orderHistory(): Nullable<
    OrderHistoryModel<HistoricOrder>
  > {
    return this._orderHistory;
  }

  /** @inheritdoc */
  @computed public get isOrderLoaded(): boolean {
    return this._isOrderLoaded;
  }

  /** @inheritdoc */
  @computed public get haveItemsToReturnBeenSelected(): boolean {
    return Boolean(this._itemsSelectedForReturn?.length);
  }

  /** @inheritdoc */
  @computed public get isExchangeModeActive(): boolean {
    return this._isExchangeModeActive;
  }

  /** @inheritdoc */
  @computed public get isReturnTypeSelectionComplete(): boolean {
    // Return type selection is complete if...
    if (
      // An order has been loaded
      this._isOrderLoaded &&
      // The items to return have been selected
      this.haveItemsToReturnBeenSelected &&
      // And there are no more items pending return type selection.
      this.itemsPendingReturnTypeSelectionCount === 0
    ) {
      return true;
    }

    // Otherwise, it's not complete.
    return false;
  }

  /** @inheritdoc */
  @computed public get isReturnOrderComplete(): boolean {
    if (
      !this._returnOrder ||
      !this.isOrderLoaded ||
      !this.isReturnTypeSelectionComplete
    )
      return false;

    const { originalOrderID, returnItems, shippingAddress } = this._returnOrder;

    if (
      !(originalOrderID && returnItems && shippingAddress && this._labelOption)
    )
      return false;

    if (returnItems.length !== this._itemsSelectedForReturn?.length)
      return false;

    return true;
  }

  /** @inheritdoc */
  @computed public get returnOrder(): Nullable<
    Partial<Writeable<IReturnOrder>>
  > {
    // Check if the return order has items
    if (this._returnOrder?.returnItems) {
      // Default the return ORDER type to Refund.
      let returnOrderType: ReturnOrderType = ReturnOrderType.Refund;

      for (const item of this._returnOrder.returnItems) {
        // But, if there is a single non-refund (i.e. exchange) return item in the order...
        if (item?.returnType !== ReturnItemType.Refund) {
          // Set the type for the whole order as Exchange.
          returnOrderType = ReturnOrderType.Exchange;
          break;
        }
      }

      this._returnOrder = { ...this._returnOrder, type: returnOrderType };
    }

    return this._returnOrder;
  }

  /** @inheritdoc */
  @computed public get order(): Nullable<HistoricOrder> {
    return this._order;
  }

  /** @inheritdoc */
  @computed public get orderItems(): Nullable<ReadonlyArray<IOrderLine>> {
    return this._orderItems;
  }

  /** @inheritdoc */
  @computed public get itemsSelectedForReturn(): Nullable<
    ReadonlyArray<string>
  > {
    return this._itemsSelectedForReturn;
  }

  /** @inheritdoc */
  @computed public get itemsPendingReturnTypeSelection(): Nullable<
    ReadonlyArray<string>
  > {
    return this._itemsPendingReturnTypeSelection;
  }

  /** @inheritdoc */
  @computed public get currentItem(): Nullable<string> {
    return this._currentItem;
  }

  /** @inheritdoc */
  @computed public get labelOption(): LabelOption {
    if (!this._labelOption) {
      throw new InvalidStateError(
        'Label option should be set on creation or in the constructor.'
      );
    }

    return this._labelOption;
  }

  /** @inheritdoc */
  public setLabelOption(option: LabelOption): void {
    this._labelOption = option;
  }

  /** @inheritdoc */
  public setReturnOrderID(orderID: string): void {
    if (!this._returnOrder) {
      throw new InvalidStateError(
        'Return order should be defined to set an orderID.'
      );
    }

    this._returnOrder.orderID = orderID;
  }

  /**
   * When the order is masked, we can use this method to set the shipping address through the UI form.
   * @param address - The shipping address to set.
   * @throws An {@link InvalidStateError} if the return order is not defined.
   */
  public setShippingAddress(address: IAddressWithPhoneNumber): void {
    if (!this._returnOrder) {
      throw new InvalidStateError(
        'Return order should be defined to set a shipping address.'
      );
    }

    this._returnOrder.shippingAddress = address;
  }

  /**
   * Returns to the beginning of the returns flow and sets
   * the error message to be displayed to the user.
   * @param error - A string that can be displayed as an error.
   */
  public returnToStartWithError(error: string): void {
    this._errorMessage = error;
    this.goTo(ReturnsFlowStep.OrderLookup);
  }

  /** @inheritdoc */
  public toDTO(): DTO<IReturnsFlowState> {
    return {
      currentStep: this.currentStep,
      isOrderLoaded: this.isOrderLoaded,
      haveItemsToReturnBeenSelected: this.haveItemsToReturnBeenSelected,
      isExchangeModeActive: this.isExchangeModeActive,
      isReturnTypeSelectionComplete: this.isReturnTypeSelectionComplete,
      isReturnOrderComplete: this.isReturnOrderComplete,
      returnOrder: this.returnOrder,
      order: this.order,
      orderHistory: this.orderHistory?.toDTO(),
      orderItems: this.orderItems,
      itemsSelectedForReturn: this.itemsSelectedForReturn,
      itemsPendingReturnTypeSelection: this.itemsPendingReturnTypeSelection,
      currentItem: this.currentItem,
      labelOption: this._labelOption
    };
  }
}
