import classNames from 'classnames';
import { ReactNode, FunctionComponent } from 'react';

import S from './styles.module.scss';

/**
 * Card component props.
 */
export interface ICardProps {
  /**
   * Whether to include a border around the card. Default: true.
   */
  border?: boolean;

  /**
   * Body of the card.
   */
  children?: ReactNode;

  /**
   * The title.
   */
  title?: ReactNode;

  /**
   * Variant. The 'active' variant has inverted title bar colors.
   */
  variant?: 'default' | 'active';

  /**
   * Content for the right side of the title bar, ie. Close icon.
   */
  endAdornment?: ReactNode;

  /**
   * Padding size. Default is no padding.
   */
  padding?: 'md';
}

/**
 * Get the styling from the variant string.
 * @param variant - String that represents a card styling type.
 * @returns Returns a className from the styling import.
 */
const getStylingFromVariant = (variant: 'default' | 'active'): string => {
  switch (variant) {
    case 'active':
      return S.rootActive;
    default:
      return S.rootDefault;
  }
};

/**
 * Get the styling from the padding string.
 * @param padding - String that represents the padding.
 * @returns Returns a className from the styling import.
 */
const getStylingFromPadding = (padding?: 'md'): string => {
  switch (padding) {
    case 'md':
      return S.rootPaddingMd;
    default:
      return '';
  }
};

/**
 * A card. It has a title-bar on the top and a body underneath.
 */
export const Card: FunctionComponent<ICardProps> = ({
  padding,
  border = true,
  title = null,
  variant = 'default',
  endAdornment = null,
  children = null
}) => {
  return (
    <div
      className={classNames(
        S.rootCommon,
        getStylingFromVariant(variant),
        getStylingFromPadding(padding),
        border ? S.rootBorder : ''
      )}
    >
      {(title || endAdornment) && (
        <div className={S.title}>
          <span className={S.titleText}>{title}</span>
          {endAdornment}
        </div>
      )}
      <div className={S.container}>{children}</div>
    </div>
  );
};
