import type { AddToCartButton } from '../cart/AddToCartButton';
import type { ProductDetailsPanel } from './ProductDetailsPanel';
import type { VariationPanel } from './VariationPanel';

/**
 * Enumerates valid variants of the Product Details components.
 * @see {@link ProductDetailsPanel} to see it in use.
 */
enum ProductDetailsVariant {
  /**
   * Default variant to be used with the full PDP.
   *
   * {@link ProductDetailsPanel}: Uses a PDP carousel.
   * {@link AddToCartButton}: Displays "add to cart" messaging.
   * {@link VariationPanel}: Displays a reviews link.
   */
  Default,

  /**
   * Variant to be used in Quickview.
   *
   * {@link ProductDetailsPanel}: Uses a smaller carousel.
   * {@link AddToCartButton}: Displays "add to cart" messaging.
   */
  Quickview,

  /**
   * Variant to be used when updating cart/list items.
   *
   * {@link AddToCartButton}: Displays "update" messaging instead of "add to cart" messaging.
   */
  Update,

  /**
   * Style to be used when Exchange Mode is active.
   *
   * {@link AddToCartButton}: Becomes red and displays "Add to Exchange" messaging.
   */
  Exchange,

  /**
   * Style to be used on Quickview when Exchange Mode is active.
   * Mostly used when making even exchanges (same model).
   *
   * {@link ProductDetailsPanel}: Uses a smaller carousel.
   * {@link AddToCartButton}: Becomes stays green and displays "Add to Exchange" messaging.
   */
  ExchangeQuickview
}

export default ProductDetailsVariant;
