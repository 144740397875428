import type { IProductVM } from '@/react/view-models/ProductVM';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { msg } from '@/services/isomorphic/I18NService';
import { IPrice, PriceModel } from '@/services/models/Price';
import type { IRatings } from '@/services/models/ReviewsModel';
import { Asyncable, Nullable } from '@/type-utils';
import ProductDetailsVariant from '../ProductDetailsVariant';
import { product_group_men } from "@/lang/__generated__/ahnu/product_group_men";
import { product_group_women } from "@/lang/__generated__/ahnu/product_group_women";
import { product_group_kids } from "@/lang/__generated__/ahnu/product_group_kids";
import { product_group_youth } from "@/lang/__generated__/ahnu/product_group_youth";
import { product_group_unisex } from "@/lang/__generated__/ahnu/product_group_unisex";
import { product_group_allgender } from "@/lang/__generated__/ahnu/product_group_allgender";

/** Interface for Variation Settings hook. */
export interface IVariationSettingsHook {
  /** Flag for whether wishlist is enabled. */
  isWishlistEnabled: boolean;

  /** Flag for whether star ratings are shown. */
  isShowStarRatings: boolean;

  /** The name of the product. */
  name: string;

  /** The sku of the product. */
  sku: string;

  /** The ratings of the product. */
  ratings: Asyncable<Nullable<IRatings>>;

  /** The price model of the product. */
  priceModel: Nullable<IPrice>;

  /** The group name of the product. */
  groupName: Nullable<string>;
}

/**
 * Hook to get variation settings.
 * @param variant - The product variant to display.
 * @param product - The product to display.
 * @returns Wishlist enabled state, product model.
 */
export const useVariationSettings = (
  variant: ProductDetailsVariant,
  product: IProductVM
): IVariationSettingsHook => {
  const config = ConfigurationService.getConfig('product');
  const isWishlistEnabled = config.getSetting('wishlist.enable').value;

  const reviewsConfig = ConfigurationService.getConfig('reviews');
  const isShowStarRatings = reviewsConfig.getSetting(
    'customerReviews.show'
  ).value;

  const { price, name, sku, ratings, group } = product;
  const priceModel = PriceModel.from(price as IPrice);

  let groupName: Nullable<string>;

  switch (group) {
    case 'men':
      groupName = msg(product_group_men);
      break;
    case 'women':
      groupName = msg(product_group_women);
      break;
    case 'kids':
      groupName = msg(product_group_kids);
      break;
    case 'youth':
      groupName = msg(product_group_youth);
      break;
    case 'unisex':
      groupName = msg(product_group_unisex);
      break;
    case 'all-gender':
      groupName = msg(product_group_allgender);
      break;
    default:
      groupName = null;
  }

  return {
    isWishlistEnabled,
    isShowStarRatings,
    name,
    sku,
    ratings,
    priceModel,
    groupName
  };
};
