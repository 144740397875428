import { FunctionComponent } from 'react';
import SkeletonLoader from '@/react/components/utility/SkeletonLoader';
import S from './styles.module.scss';

/** Skeleton loader for the Size Chart component. */
const SizeChartSkeleton: FunctionComponent = () => {
  return (
    <div className={S.sizeChartSkeleton}>
      {[...Array(30)].map((_) => (
        <div className={S.tableLine}>
          {[...Array(4)].map((_) => (
            <SkeletonLoader className={S.tableCell} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SizeChartSkeleton;
