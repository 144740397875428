'use client';

import {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useMemo
} from 'react';

import {
  DecisionIDOf,
  ExperienceID,
  IDecision
} from '@/services/isomorphic/PersonalizationService';
import { Nullable } from '@/type-utils';
import { ExperienceContext, IExperienceContext } from '../ExperienceContext';

export interface IExperienceProviderProps<T extends ExperienceID>
  extends PropsWithChildren {
  /**
   * The experience that holds the various decisions.
   */
  experienceID: string;
  /**
   * The decision object that contains an id and props for the decision component, it is null to start, but
   * gets a value after querying the `PersonalizationService`.
   */
  decision: Nullable<IDecision<T, DecisionIDOf<T>>>;
}

/**
 * The provider that holds the chosen decision.
 */
export const ExperienceProvider: FunctionComponent<
  IExperienceProviderProps<ExperienceID>
> = ({ experienceID, decision, children }): ReactElement => {
  const value = useMemo((): IExperienceContext<ExperienceID> => {
    return {
      experienceID,
      decision
    };
  }, [experienceID, decision]);

  return (
    <ExperienceContext.Provider value={value}>
      {children}
    </ExperienceContext.Provider>
  );
};
