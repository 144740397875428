'use client';

import { observer } from 'mobx-react-lite';
import { PropsWithChildren, Suspense } from 'react';
import { classes } from '@/next-utils/css-utils/scss-utils';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { useLocale } from '@/react/hooks/useLocale';
import type { IPlacement } from '@/services/models/Content';
import { IImage } from '@/services/models/Media/Image';
import { IProduct } from '@/services/models/Product';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';

import { ExpressBuyWithApplePayButton } from '../../ApplePay';
import AddToCartButton from '../../cart/AddToCartButton';
import { GenericContent } from '../../cms-content';
import { Breakpoint, Breakpoints, Default } from '../../core-ui/Breakpoints';
import { Link } from '../../core-ui/Link';
import { CommonErrorBoundary } from '../../errors/boundaries/CommonErrorBoundary';
import { QuickviewCarousel } from '../../product-list/quickview/QuickviewCarousel';
import { MainCarousel } from '../MainCarousel';
import ProductDetailsVariant from '../ProductDetailsVariant';
import { VariationPanel } from '../VariationPanel';

import S from './styles.base.module.scss';
import { product_tiles_quickView_viewProductDetails } from "@/lang/__generated__/ahnu/product_tiles_quickView_viewProductDetails";

export interface IProductDetailsPanelProps extends PropsWithChildren {
  /** The product view model. */
  product: IProductVM;

  /**
   * The previously selected product that will be replaced on resolve.
   * For use with {@link variant} `=` {@link ProductDetailsVariant.Update}.
   */
  productToUpdate?: IProduct;

  /** Placement for the PDP Content. */
  pdpContentPlacement?: IPlacement | null;

  /**
   * The variant of this details panel.
   * @see {@link ProductDetailsVariant} for more info on each variant.
   */
  variant?: ProductDetailsVariant;

  /**
   * Callback that will be executed when the variation selection is resolved.
   * @param newProduct - The new product variant that was just submitted.
   */
  onAddToCart?: (newProduct: IProduct) => void;
}

interface IProductDetailsPanelUpdateProps extends IProductDetailsPanelProps {
  /** @inheritdoc */
  productToUpdate: IProduct;

  /** @inheritdoc */
  variant: ProductDetailsVariant.Update;
}

/**
 * Props for the product details panel.
 */
type ProductDetailsPanelProps<T extends IProductDetailsPanelProps> =
  T['variant'] extends ProductDetailsVariant.Update
    ? IProductDetailsPanelUpdateProps & T
    : T['productToUpdate'] extends IProduct
      ? IProductDetailsPanelUpdateProps & T
      : T;

/** Can display a product's details in almost every setting. */
export const ProductDetailsPanel = observer(function ProductDetailsPanel<
  T extends IProductDetailsPanelProps
>({
  variant = ProductDetailsVariant.Default,
  product,
  productToUpdate,
  pdpContentPlacement,
  onAddToCart,
  children
}: ProductDetailsPanelProps<T>) {
  const [msg, msgf] = useLocaleMessage();
  const { language, country } = useLocale();

  const { images } = product;

  // If this panel is a quickview variant.
  const isQuickviewVariant =
    variant === ProductDetailsVariant.Quickview ||
    variant === ProductDetailsVariant.ExchangeQuickview;

  // If this panel is a full-size variant.
  const isFullSizeVariant =
    variant === ProductDetailsVariant.Default ||
    variant === ProductDetailsVariant.Exchange;

  // If this is an update panel.
  const isUpdate = variant === ProductDetailsVariant.Update;

  // If the carousel should be passed into the variations panel on mobile.
  const isCarouselShownWithinVariationsPanelOnMobile =
    ConfigurationService.getConfig('product', language, country).getSetting(
      'isCarouselShownWithinVariationsPanelOnMobile'
    ).value;

  const productCarousel = (
    <CommonErrorBoundary>
      {isFullSizeVariant ? (
        <MainCarousel images={images as Array<IImage>} />
      ) : (
        <QuickviewCarousel images={images as Array<IImage>} />
      )}
    </CommonErrorBoundary>
  );

  const bottomContent = (
    <>
      <div className={S.addToCartButton}>
        <AddToCartButton
          variant={variant}
          product={product}
          productToUpdate={productToUpdate}
          onAddToCart={onAddToCart}
        />
        {(variant === ProductDetailsVariant.Default ||
          variant === ProductDetailsVariant.Quickview) && (
          <ExpressBuyWithApplePayButton
            className={S.applePayContainer}
            product={product}
            theme="white-outline"
          />
        )}
      </div>
      {children && (
        <div className={S.additionalContentContainer}>{children}</div>
      )}
      {/*
        Only show the "View Product Details" link if the variant
        is one of the quickview variants.
      */}
      {isQuickviewVariant && (
        <div className={S.fullDetails}>
          <Link href={product.urlToProduct}>
            {msg(product_tiles_quickView_viewProductDetails)}
          </Link>
        </div>
      )}
    </>
  );

  return (
    <div
      className={classes(S.productDetailsPanel, {
        [S.quickview]: isQuickviewVariant || isUpdate
      })}
    >
      <Breakpoints>
        {isCarouselShownWithinVariationsPanelOnMobile && (
          <Breakpoint media={['xxs', 'xs', 'sm']}>
            <div className={S.variationPanelContainer}>
              {/*
                On mobile, we pass the product carousel as a child of
                the variation panel, so that it can be shown below
                the product selection options.
              */}
              <VariationPanel variant={variant} product={product}>
                <div className={S.carouselContainer}>{productCarousel}</div>
              </VariationPanel>
              {bottomContent}
              {pdpContentPlacement && (
                <GenericContent placement={pdpContentPlacement} />
              )}
            </div>
          </Breakpoint>
        )}
        <Default>
          <>
            <div className={S.carouselContainer}>
              {productCarousel}
              {pdpContentPlacement && (
                <GenericContent placement={pdpContentPlacement} />
              )}
            </div>
            <div className={S.variationPanelContainer}>
              <VariationPanel variant={variant} product={product} />

              {bottomContent}
            </div>
          </>
        </Default>
      </Breakpoints>
    </div>
  );
});
