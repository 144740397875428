import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import type CartVM from '@/react/view-models/CartVM';
import { msg } from '@/services/isomorphic/I18NService';
import {
  CartQuantityExceededError,
  LineItemQuantityExceededError
} from '@/services/models/Cart';
import { useCallback } from 'react';
import { cart_errors_lineQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_lineQuantityExceeded";
import { cart_errors_cartTotalQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_cartTotalQuantityExceeded";

/**
 * A hook which returns a function that changes the quantity of a line item in the cart.
 * @param cart - The cart view model.
 * @returns A function that changes the quantity of a line item in the cart.
 */
export function useChangeCartLineQuantity(
  cart: CartVM
): (lineId: string, quantity: number) => Promise<void> {
  const { alert } = useErrorAlert();

  return useCallback(
    async (lineId, quantity) => {
      try {
        await cart.updateLineItem(lineId, quantity);
      } catch (err) {
        if (err instanceof LineItemQuantityExceededError) {
          alert(msg(cart_errors_lineQuantityExceeded));
        } else if (err instanceof CartQuantityExceededError) {
          alert(msg(cart_errors_cartTotalQuantityExceeded));
        } else {
          throw err;
        }
      }
    },
    [cart, alert]
  );
}
