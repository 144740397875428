'use client';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import CookieService from '@/services/isomorphic/CookieService';
import { ComponentType, FunctionComponent, useEffect, useState } from 'react';
import { ECOMM_OVERRIDE_COOKIE_NAME } from '@/configs/env/public';
import type { CookieModel } from '@/services/models/Cookie';
import type { Nullable } from '@/type-utils';

/**
 * The props that will be added to the wrapped component.
 */
type WithEcommerceEnabledProps = NonNullable<unknown>;

/**
 * A higher order component that will only render the wrapped component if ecommerce is enabled.
 * @param WrappedComponent - The component to wrap.
 * @returns The wrapped component with the extra info.
 */
export function withEcommerceEnabled<P>(
  WrappedComponent: ComponentType<P & WithEcommerceEnabledProps>
): FunctionComponent<P> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I'm not quite sure how to type this.
  const EcommerceDependantComponent = (props: any): any => {
    const [shouldOverrideEcommerceEnablement, setShouldOverrideEcommerceEnablement] = useState<boolean>(false);

    useEffect(() => {
      // Check for the ecommerce override cookie to force-enable ecommerce functionality. We
      // merely check for the presence of the cookie on the client-side as we cannot expose
      // the secret value of the cookie to the client-side. Fortunately, the display of an
      // ecommerce-related component has no practical effect if the server-side does not
      // also allow for ecommerce functionality.
      const cookie: Nullable<CookieModel> = CookieService.tryGet(ECOMM_OVERRIDE_COOKIE_NAME);

      setShouldOverrideEcommerceEnablement(
        Boolean(
          cookie && 
          typeof cookie.value === 'string'
        )
      );
    }, []);

    // Check the configuration to see if ecommerce is enabled for this environment.
    const enabled =
      ConfigurationService.getConfig('ecommerce').getSetting(
        'ecommerceEnabled'
      ).value;

    // If ecommerce functionality is not enabled and the override cookie is not present,
    // return null to prevent this ecommerce-related component from being rendered.
    if (!enabled && !shouldOverrideEcommerceEnablement) return null;

    // At this point, the props being passed in are the original props the component expects.
    return <WrappedComponent {...props} />;
  };
  return EcommerceDependantComponent;
}
