import WebStorageService from '@/services/client/WebStorageService';
import { PlacedOrderModel, type IPlacedOrder } from '@/services/models/Order';

/**
 * Writes the given placed order to session storage.
 * @param order - The placed order to write to session storage.
 */
export function writePlacedOrderToSession(order: IPlacedOrder): void {
  /**
   * Store the placed order in session storage so that the
   * confirmation page can retrieve it upon redirect.
   *
   * **Q. Why do we store the `PlacedOrderModel` response rather than a copy of the cart and checkout forms?**.
   *
   * A. Although we have a few preventative measures to ensure that the order as seen by the customer
   * matches the order placed on the server (e.g. order validations, data-preserving schema validations, etc.),
   * an innocent change or race condition could break this guarantee. Therefore, as a final layer of insurance,
   * the order confirmation page uses not local state or form data, but the actual placed order response from
   * the server. This ensures that the customer sees the exact order that was placed, and if it differs from
   * what they expected, then they can contact customer service with their placed order details.
   *
   * Additionally, as seen in the `VPSPlaceOrderTemplate`, a cart isn't even strictly necessary to place
   * an order. So, it would inaccurate and short-sighted to base the order confirmation page on cart state.
   */
  WebStorageService.sessionStorage!.setItem(
    order.orderToken,
    PlacedOrderModel.toDTO(order)
  );
}
