'use client';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';
import GenericSelector from '../GenericSelector';

interface ITargetDemographicSelectorProps {
  /**
   * The product to display style options for.
   */
  product: IProductVM;
}

/** The Group selector component for the PDP. */
const TargetDemographicSelector = observer(function TargetDemographicSelector({
  product
}: ITargetDemographicSelectorProps) {
  return (
    <GenericSelector
      variationType={DisplayVariationAttributeType.TargetDemographic}
      product={product}
    />
  );
});

export default TargetDemographicSelector;
