'use client';

import { observer } from 'mobx-react-lite';
import { msg, msgf } from '@/services/isomorphic/I18NService';
import S from './styles.module.scss';
import { cart_promotions_fallbackLabel } from "@/lang/__generated__/ahnu/cart_promotions_fallbackLabel";
import { cart_promotions_applied } from "@/lang/__generated__/ahnu/cart_promotions_applied";

/** A summary of a promotion applied to a line item. */
export interface ILineItemPromotionSummary {
  /** The label of the promotion. */
  label?: string;
  /** Whether the promotion is visible. */
  visible?: boolean;
}

/** Props for the {@link LineItemPromotions} component. */
export interface ILineItemPromotionsProps {
  /** A list of summary objects for the promotions applied to a line item. */
  promotions: ReadonlyArray<ILineItemPromotionSummary>;
}

/** Displays the promotions applied to a line item. */
export const LineItemPromotions = observer(function LineItemPromotions({
  promotions
}: ILineItemPromotionsProps) {
  if (promotions.length === 0) return null;

  const promotionsCountRecord = countPromotions(promotions);

  return Object.entries(promotionsCountRecord).map(([label, count]) => {
    return (
      <div key={label} className={S.promotion}>
        {msgf(cart_promotions_applied, {
          label,
          count
        })}
      </div>
    );
  });
});

/**
 * Count the number of promotions applied to a line item.
 * @param promotions - The promotions applied to the line item.
 * @returns A record of the promotions applied to the line item.
 */
function countPromotions(
  promotions: ReadonlyArray<ILineItemPromotionSummary>
): Record<string, number> {
  const promotionsRecord: Record<string, number> = {};

  for (const p of promotions) {
    const { visible } = p;

    if (!visible) {
      continue;
    }

    // TODO: Generate generic labels per promotion type.
    const label = (p.label ?? '') || msg(cart_promotions_fallbackLabel);

    if (label in promotionsRecord) {
      promotionsRecord[label] += 1;
    } else {
      promotionsRecord[label] = 1;
    }
  }

  return promotionsRecord;
}
