import type { MaybePromise } from '@/type-utils';
import { useCallback, useState } from 'react';

/**
 * A React hook for managing whether there are pending operations in progress.
 * @returns A tuple containing whether there are pending operations and a
 * function to add a promise-like to the list of pending operations.
 *
 * **Usage Note**: A "pending" operation is an async operation that has not settled yet.
 * If an operation errors, it will still be removed from the list.
 *
 * **Implementation Note**: The list of pending operations *must not* be exposed since an unrelated
 * component shouldn't be able to read operations that it does not own or did not initiate. Doing so
 * would violate component encapsulation and composition principles.
 */
export function usePending(): [
  isPending: boolean,
  addPending: (promise: MaybePromise<unknown>) => void
] {
  const [promises, setPromises] = useState<Array<MaybePromise<unknown>>>([]);

  const addPromise = useCallback((newPromise: MaybePromise<unknown>): void => {
    if (!(newPromise instanceof Promise)) return;

    // must use an updater function to circumvent stale reference to array
    // in case multiple promises are added in quick succession
    setPromises((promises) => [...promises, newPromise]);

    const remove = (): void => {
      setPromises((promises) =>
        promises.filter((promise) => promise !== newPromise)
      );
    };

    // eslint-disable-next-line no-void -- cleanup the promise from the array when it settles
    void newPromise.then(remove, remove); // we use `then` instead of `finally` due to a weird interaction with Jest. It's also marginally faster.
  }, []);

  const isPending = promises.length !== 0;

  return [isPending, addPromise];
}
