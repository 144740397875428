'use client';

import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';

import SizeChartService from '@/services/isomorphic/SizeChartService';
import {
  ISizeChart,
  ISizeMeasurements
} from '@/services/serverless/integrations/AWS/AWSSizeChartService/AWSSizeChartService';

import { msg } from '@/services/isomorphic/I18NService';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import LoggerService from '@/services/isomorphic/LoggerService';

import { Button } from '@/react/components/core-ui/Button';
import { Alert } from '@/react/components/core-ui/Alert';

import SizeChartSkeleton from './SizeChartSkeleton';
import S from './styles.base.module.scss';
import { product_sizeChart_category_men } from "@/lang/__generated__/ahnu/product_sizeChart_category_men";
import { product_sizeChart_category_women } from "@/lang/__generated__/ahnu/product_sizeChart_category_women";
import { product_sizeChart_category_unisex } from "@/lang/__generated__/ahnu/product_sizeChart_category_unisex";
import { product_sizeChart_category_youth } from "@/lang/__generated__/ahnu/product_sizeChart_category_youth";
import { product_sizeChart_category_children } from "@/lang/__generated__/ahnu/product_sizeChart_category_children";
import { product_sizeChart_category_kids } from "@/lang/__generated__/ahnu/product_sizeChart_category_kids";
import { product_sizeChart_category_infants } from "@/lang/__generated__/ahnu/product_sizeChart_category_infants";
import { product_sizeChart_category_toddlers } from "@/lang/__generated__/ahnu/product_sizeChart_category_toddlers";
import { product_sizeChart_category_littleKids } from "@/lang/__generated__/ahnu/product_sizeChart_category_littleKids";
import { product_sizeChart_category_bigKids } from "@/lang/__generated__/ahnu/product_sizeChart_category_bigKids";
import { product_sizeChart_measurements_us } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_us";
import { product_sizeChart_measurements_uk } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_uk";
import { product_sizeChart_measurements_eu } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_eu";
import { product_sizeChart_measurements_heelToe } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_heelToe";
import { product_sizeChart_measurements_inches } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_inches";
import { product_sizeChart_measurements_mx } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_mx";
import { product_sizeChart_measurements_centimeters } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_centimeters";
import { product_sizeChart_measurements_jp } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_jp";
import { product_sizeChart_measurements_cn } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_cn";
import { product_sizeChart_measurements_millimeters } from "@/lang/__generated__/ahnu/product_sizeChart_measurements_millimeters";
import { general_errors_unexpectedError } from "@/lang/__generated__/ahnu/general_errors_unexpectedError";
import { product_sizeChart_descriptionTop } from "@/lang/__generated__/ahnu/product_sizeChart_descriptionTop";
import { product_sizeChart_descriptionBottom } from "@/lang/__generated__/ahnu/product_sizeChart_descriptionBottom";
import { product_sizeChart_ages_infants } from "@/lang/__generated__/ahnu/product_sizeChart_ages_infants";
import { product_sizeChart_ages_toddlers } from "@/lang/__generated__/ahnu/product_sizeChart_ages_toddlers";
import { product_sizeChart_ages_littleKids } from "@/lang/__generated__/ahnu/product_sizeChart_ages_littleKids";
import { product_sizeChart_ages_bigKids } from "@/lang/__generated__/ahnu/product_sizeChart_ages_bigKids";
import { product_sizeChart_hideMoreCountries } from "@/lang/__generated__/ahnu/product_sizeChart_hideMoreCountries";
import { product_sizeChart_showMoreCountries } from "@/lang/__generated__/ahnu/product_sizeChart_showMoreCountries";

/**
 * Gets the category label based on a category key.
 * @param key - A key used to get the category.
 * @returns - A message string.
 * @throws - If the key doesn't match any item in the object.
 */
const getCategoryLabel = (key: string): string => {
  const categoryLabels: Record<string, string> = {
    men: msg(product_sizeChart_category_men),
    women: msg(product_sizeChart_category_women),
    unisex: msg(product_sizeChart_category_unisex),
    youth: msg(product_sizeChart_category_youth),
    children: msg(product_sizeChart_category_children),
    kids: msg(product_sizeChart_category_kids),
    infants: msg(product_sizeChart_category_infants),
    toddlers: msg(product_sizeChart_category_toddlers),
    littleKids: msg(product_sizeChart_category_littleKids),
    bigKids: msg(product_sizeChart_category_bigKids)
  };

  if (!categoryLabels[key]) {
    throw new InvalidArgumentError(`The category ${key} doesn't exist.`);
  }

  return categoryLabels[key];
};

/**
 * Gets the measurement label relevant to the specific measurement locale.
 * @param key - The key used to retrieve the message.
 * @returns A string from the localization service as a react element.
 * @throws - If the provided key is not accounted for.
 */
const getMeasurementLabel = (key: string): ReactElement => {
  const measurementsLabels: Record<string, ReactElement | string> = {
    us: msg(product_sizeChart_measurements_us),
    uk: msg(product_sizeChart_measurements_uk),
    eu: msg(product_sizeChart_measurements_eu),
    'heel-toe-measurement': (
      <>
        {msg(product_sizeChart_measurements_heelToe)}
        <span className={S.measurement}>
          {msg(product_sizeChart_measurements_inches)}
        </span>
      </>
    ),
    mx: (
      <>
        {msg(product_sizeChart_measurements_mx)}
        <span className={S.measurement}>
          {msg(product_sizeChart_measurements_centimeters)}
        </span>
      </>
    ),
    jp: (
      <>
        {msg(product_sizeChart_measurements_jp)}
        <span className={S.measurement}>
          {msg(product_sizeChart_measurements_centimeters)}
        </span>
      </>
    ),
    cn: (
      <>
        {msg(product_sizeChart_measurements_cn)}
        <span className={S.measurement}>
          {msg(product_sizeChart_measurements_millimeters)}
        </span>
      </>
    )
  };

  if (!measurementsLabels[key]) {
    throw new InvalidArgumentError(`The measurement ${key} doesn't exist.`);
  }

  return measurementsLabels[key] as ReactElement;
};

const otherCountriesList = ['mx', 'jp', 'cn'];

export interface ITableProps {
  /**
   * Object that contains all available country measurements.
   */
  measurements: ISizeMeasurements;
  /**
   * Boolean that dictates if the interface should show more country columns.
   */
  showCountries: boolean;
  /**
   * Index that indicates which column is being hovered.
   */
  selectedColumn: number | null;
  /**
   * Function that will be executed when a table cell is hovered.
   * @param event - Object generated by the event.
   */
  onHover(event: unknown): void;
  /**
   * Function that will be executed when the mouse leaves the table cell.
   */
  onMouseLeave(): void;
}

/**
 * Component for displaying the header of a table, based on available measurements and if we want to show more countries
 * measurements or not.
 */
const TableHeader: FunctionComponent<ITableProps> = ({
  measurements,
  showCountries,
  selectedColumn,
  onHover,
  onMouseLeave
}) => {
  const templates = Object.entries(measurements).map(([key, value], index) => {
    if (!showCountries && otherCountriesList.includes(key)) {
      return null;
    }

    /** The measurement labels to display in the table's header */
    const element = getMeasurementLabel(key);

    const shouldHighlight = index === selectedColumn;
    return (
      <div
        className={`${S.tableHead} ${shouldHighlight ? S.hover : ''}`}
        key={key}
        onMouseMove={onHover}
        onMouseLeave={onMouseLeave}
      >
        {element}
      </div>
    );
  });

  return <div className={S.tableHeadRow}>{templates}</div>;
};

/**
 * Component for displaying a row in a table, based on available measurements and if we want to show more countries
 * measurements or not.
 */
const TableRow: FunctionComponent<ITableProps> = ({
  measurements,
  showCountries,
  selectedColumn,
  onHover,
  onMouseLeave
}) => {
  return (
    <div className={S.tableRow}>
      {Object.entries(measurements).map(([key, value], index) => {
        const shouldHighlight = index === selectedColumn;

        if (!showCountries && otherCountriesList.includes(key)) {
          return null;
        }
        if (value) {
          return (
            <div
              className={`${S.tableCell} ${shouldHighlight ? S.hover : ''}`}
              key={key}
              onMouseEnter={onHover}
              onMouseLeave={onMouseLeave}
            >
              {value}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

/**
 * Displays a table with all available sizes information from different types
 * of measurements and countries.
 */
export const SizeChart: FunctionComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [showCountries, setShowCountries] = useState(false);
  const [sizeChart, setSizeChart] = useState<ISizeChart | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('men');
  const [loading, setLoading] = useState(true);
  const [showMoreCountries, setShowMoreCountries] = useState(true);

  const [error, setError] = useState<string | null>(null);

  const toggleShowCountry = (): void => {
    setShowCountries(!showCountries);
  };

  const getSizeChart = useCallback(async (): Promise<void> => {
    try {
      const res = await SizeChartService.getSizeChart();
      const sortedRes = {
        men: { ...res.men },
        women: { ...res.women },
        unisex: { ...res.unisex },
        youth: { ...res.youth },
        kids: { ...res.kids },
        infants: { ...res.infants },
        toddlers: { ...res.toddlers },
        littleKids: { ...res.littleKids },
        bigKids: { ...res.bigKids }
      };

      sortSizeChart(sortedRes);
    } catch (error) {
      LoggerService.error(
        new Error('An error ocurred when getting fetching the size chart.', {
          cause: error
        })
      );

      setError(msg(general_errors_unexpectedError));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSizeChart();
  }, [getSizeChart]);

  const sortSizeChart = (chart: ISizeChart): void => {
    const final = {};

    Object.entries(chart).forEach(
      ([key, value]: [string, { [key: string]: ISizeMeasurements }]) => {
        const sorted = Object.keys(value).sort((a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true
          });
        });

        const sortedSizes = {};

        sorted.forEach((size: string) => {
          const measurements = value[size];
          const orderedSizes: ISizeMeasurements = {
            us: measurements.us,
            'heel-toe-measurement': measurements['heel-toe-measurement'],
            uk: measurements.uk,
            eu: measurements.eu,
            mx: measurements.mx,
            jp: measurements.jp,
            cn: measurements.cn
          };

          Object.defineProperty(sortedSizes, size, {
            value: orderedSizes,
            enumerable: true
          });
        });

        Object.defineProperty(final, key, {
          value: sortedSizes,
          enumerable: true
        });
      }
    );

    setSizeChart(final as ISizeChart);
  };

  const changeCategory = (category: string): void => {
    if (sizeChart) {
      const selectedObject = sizeChart[category as keyof typeof sizeChart];
      const sampleSize: ISizeMeasurements = Object.values(selectedObject)[0];
      setSelectedCategory(category);
      setShowMoreCountries(!!sampleSize.eu && !!sampleSize.uk);
    }
  };

  const onHover = (event: Event): void => {
    const cell = event.target as HTMLElement;
    const row = cell?.parentElement as HTMLDivElement;
    const columnIndex = [].indexOf.call(row?.children, cell as never);
    setSelectedIndex(columnIndex);
  };

  const onMouseLeave = (): void => {
    setSelectedIndex(null);
  };

  /**
   * Check the the product config for categories to be shown on size chart.
   * @param category - The category to be checked.
   * @returns - A boolean indicating if the category is enabled.
   */
  const isCategoryEnabledByString = (category: string): boolean => {
    let isEnabled = false;
    const config = ConfigurationService.getConfig('product');
    const sizeChartCategoryConfig = config.getSetting(
      'sizeChartCategoriesToShow'
    ).value;

    Object.entries(sizeChartCategoryConfig).forEach(([key, value]) => {
      if (key === category) {
        isEnabled = value.value;
      }
    });

    return isEnabled;
  };

  if (error) {
    return <Alert variant="error">{error}</Alert>;
  }

  if (loading || !sizeChart) {
    return <SizeChartSkeleton />;
  }

  return (
    <div className={S.sizeChart}>
      <p>{msg(product_sizeChart_descriptionTop)}</p>
      <p>{msg(product_sizeChart_descriptionBottom)}</p>
      <div className={S.wrapper}>
        <div className={S.column}>
          {Object.keys(sizeChart).map((category: string) => {
            if (isCategoryEnabledByString(category)) {
              let innerObject: ReactElement | string;
              if (category === 'infants') {
                innerObject = (
                  <>
                    {msg(product_sizeChart_category_infants)}
                    <span>{msg(product_sizeChart_ages_infants)}</span>
                  </>
                );
              } else if (category === 'toddlers') {
                innerObject = (
                  <>
                    {msg(product_sizeChart_category_toddlers)}
                    <span>{msg(product_sizeChart_ages_toddlers)}</span>
                  </>
                );
              } else if (category === 'littleKids') {
                innerObject = (
                  <>
                    {msg(product_sizeChart_category_littleKids)}
                    <span>{msg(product_sizeChart_ages_littleKids)}</span>
                  </>
                );
              } else if (category === 'bigKids') {
                innerObject = (
                  <>
                    {msg(product_sizeChart_category_bigKids)}
                    <span>{msg(product_sizeChart_ages_bigKids)}</span>
                  </>
                );
              } else {
                innerObject = getCategoryLabel(category);
              }

              return (
                <Button
                  className={`${S.category} ${
                    selectedCategory === category ? S.active : ''
                  }`}
                  onClick={() => {
                    changeCategory(category);
                  }}
                  key={category}
                  variant="text"
                >
                  {innerObject}
                </Button>
              );
            }

            return null;
          })}
        </div>
        <div className={S.tableWrapper}>
          <div className={S.categoryLabel}>
            <span>{getCategoryLabel(selectedCategory)}</span>
            {showMoreCountries && (
              <div className={S.showMoreCountries}>
                <Button
                  onClick={toggleShowCountry}
                  variant="text"
                  className={S.button}
                >
                  {showCountries
                    ? `${msg(product_sizeChart_hideMoreCountries)}`
                    : `${msg(product_sizeChart_showMoreCountries)}`}
                </Button>
              </div>
            )}
          </div>
          <div className={S.table}>
            <TableHeader
              measurements={
                Object.values(
                  sizeChart[selectedCategory as keyof typeof sizeChart]
                )[0] as ISizeMeasurements
              }
              showCountries={showCountries}
              selectedColumn={selectedIndex}
              onHover={onHover}
              onMouseLeave={onMouseLeave}
            />

            <div className={S.tableBody}>
              {Object.values(
                sizeChart[selectedCategory as keyof typeof sizeChart]
              ).map((sizes: ISizeMeasurements) => {
                return (
                  <TableRow
                    key={sizes.us}
                    selectedColumn={selectedIndex}
                    measurements={sizes}
                    showCountries={showCountries}
                    onHover={onHover}
                    onMouseLeave={onMouseLeave}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
