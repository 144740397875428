/**
 * Enumerates the possible states for an Order.
 * @see [Various Order Statuses and Corresponding Logic | Confluence](https://deckersbrand.atlassian.net/wiki/spaces/PPE/pages/2663448699/Various+Order+Statuses+and+Corresponding+Logic)
 *
 * This informs the "progress bar" in Order cards.
 */
enum OrderStatus {
  /**
   * The initial state when an order is placed.
   */
  Placed = 'Placed',

  /**
   * The order has been placed, but not yet processed
   * into the system.
   */
  Processing = 'Processing',

  /**
   * The order has been placed and received by the system
   * successfully.
   */
  Received = 'Received',

  /**
   * The order's goods have been shipped, but they have not yet been received
   * by the customer.
   */
  Shipped = 'Shipped',

  /** The order's goods have been shipped and delivered to the customer's address. */
  Delivered = 'Delivered',

  /** The order has been fulfilled and is now marked as closed. */
  Completed = 'Completed',

  /** The order was cancelled. */
  Cancelled = 'Cancelled',

  /**
   * The order is _backordered_, meaning that although it was accepted,
   * it cannot be currently fulfilled due to a lack of available supply.
   *
   * @see [Backorder]: Definition, Causes, Example, Vs. Out-of-Stock](https://www.investopedia.com/terms/b/backorder.asp)
   */
  Backorder = 'Backorder',

  /**
   * The order cannot be currently fulfilled because the product has not
   * yet launched.
   */
  Preorder = 'Preorder'
}

/**
 * Defines the priority each order status takes.
 * The lower the number, the higher its priority.
 *
 * Useful for orders that have different statuses per shipment.
 * The overall order status will be the highest priority
 * status assigned to any of its shipments.
 */
export const ORDER_STATUS_PRIORITY = {
  [OrderStatus.Placed]: 0,
  [OrderStatus.Processing]: 1,
  [OrderStatus.Received]: 2,
  [OrderStatus.Backorder]: 3,
  [OrderStatus.Preorder]: 4,
  [OrderStatus.Shipped]: 5,
  [OrderStatus.Delivered]: 6,
  [OrderStatus.Completed]: 7,
  [OrderStatus.Cancelled]: 8
};

export default OrderStatus;
