'use client';

import { FunctionComponent, ReactElement, Suspense, useMemo } from 'react';

import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { IImage } from '@/services/models/Media/Image';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { Slider, Slide, ISliderProps } from '../../Slider';
import { ISliderPaginationThumbnail } from '../../Slider/components/Pagination/ISliderPaginationOptions';
import { Image } from '../../core-ui/Image';

import { Breakpoint, Breakpoints, Default } from '../../core-ui/Breakpoints';

import S from './styles.base.module.scss';

export interface IMainCarouselProps {
  /** Images to display. */
  images: Array<IImage>;
}

/**
 * Generates the new thumbnails based on the active images.
 * @param images - Images off the product model.
 * @returns An array of slider thumbnail objects.
 */
function generateThumbnails(
  images: ReadonlyArray<IImage>
): Array<ISliderPaginationThumbnail> {
  return images.map((image) => {
    return { src: image.src!, alt: image.alt! };
  });
}

/**
 * Generates slides based on an array of images.
 * @param images - Images off the product model.
 * @returns An array of react elements.
 */
function generateSliderImages(
  images: ReadonlyArray<IImage>
): Array<ReactElement> {
  return images.map((imageObject) => {
    const image = {
      src: imageObject.src,
      alt: imageObject.alt,
      uuid: imageObject.uuid,
      aspectRatio:
        imageObject.aspectRatio instanceof StaleWhileRevalidate
          ? imageObject.aspectRatio.value
          : imageObject.aspectRatio
    };

    return (
      <Slide key={image.src}>
        <Image
          enhanceOnZoom
          image={image}
          fit="contain"
          useSkeletonLoader={false}
          className={S.imageContainer}
        />
      </Slide>
    );
  });
}

/** The main carousel for the PDP. */
export const MainCarousel: FunctionComponent<IMainCarouselProps> = ({
  images
}) => {
  const mainImages = useMemo(() => generateSliderImages(images), [images]);

  const desktopSliderConfig = useBrandLocaleValue<ISliderProps>(
    () => ({
      default: {
        height: '100%',
        navigationWrapperClassName: S.navigationWrapper,
        pagination: {
          enabled: true,
          position: 'left',
          thumbnails: generateThumbnails(images),
          wrapperClassName: S.thumbnails,
          inset: false,
          direction: 'vertical'
        },
        loop: true,
        navigation: {
          inset: true,
          arrowVariant: 'arrow',
          buttonClassName: S.navigation
        }
      },
      AHNU: {
        height: '100%',
        navigationWrapperClassName: S.navigationWrapper,
        pagination: {
          enabled: true,
          position: 'bottom',
          thumbnails: generateThumbnails(images),
          wrapperClassName: S.thumbnails,
          inset: false,
          direction: 'horizontal'
        },
        loop: true,
        navigation: {
          inset: true,
          arrowVariant: 'angleLight',
          buttonClassName: S.navigation
        }
      }
    }),
    [images]
  );

  const mobileSliderConfig = {
    height: 'auto',
    loop: true,
    navigation: false,
    pagination: {
      inset: false,
      activeClassName: S.activeCircle,
      wrapperClassName: S.pagination,
      buttonClassName: S.circle,
      direction: 'horizontal'
    }
  } as ISliderProps;

  return (
    <div className={S.container}>
      <Suspense>
        <Breakpoints>
          <Breakpoint media="desktop">
            <Slider {...desktopSliderConfig}>{mainImages}</Slider>
          </Breakpoint>
          <Default>
            <Slider {...mobileSliderConfig}>{mainImages}</Slider>
          </Default>
        </Breakpoints>
      </Suspense>
    </div>
  );
};
