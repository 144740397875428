import Currency from '@/constructs/Currency';
import { NumberOrString, NumericString } from '@/type-utils';
import MoneyModel, { MoneyRepresentation } from './MoneyModel';
import Round from './Round';

export { default as Currency } from '@/constructs/Currency';
export type { default as IMoney } from './IMoney';
export { default as MoneyModel } from './MoneyModel';
export { default as Round } from './Round';
export * from './schemas';

/**
 * Formats a money representation to a string, such that trailing decimals
 * are stripped if the amount is a whole number, **unless** the amount is zero.
 *
 * Non-whole values will be truncated to the currency's number of decimal places.
 *
 * @param money - The money representation to format.
 * @returns The formatted string.
 * @example
 * stripDecimalsIfWhole(MoneyModel.fromAmount(1)) // '$1'
 * stripDecimalsIfWhole(MoneyModel.fromAmount(1.5)) // '$1.50'
 *
 */
export function stripDecimalsIfWhole(money: MoneyRepresentation): string {
  const moneyModel = MoneyModel.fromAmount(money);
  const decimalPlaces =
    moneyModel.isZero || !moneyModel.isIntegral
      ? moneyModel.currencyDecimals
      : 0;

  return moneyModel.toString({
    roundingMode: Round.TowardZero,
    roundingDecimalPlaces: decimalPlaces,
    displayDecimalPlaces: decimalPlaces
  });
}

/**
 * Options for converting a money representation to its amount in its minor unit.
 */
export interface IToMinorUnitOptions {
  /**
   * The currency to interpret the money representation as.
   */
  currency?: Currency;
  /**
   * The rounding mode to use when truncating the value to a whole number.
   * By default, it uses {@link Round.HalfAwayFromZero}.
   */
  roundingMode?: Round;
}

export function toMinorUnit(
  money: MoneyModel,
  options: Pick<IToMinorUnitOptions, 'roundingMode'>
): NumericString;
export function toMinorUnit(
  money: NumberOrString,
  options: IToMinorUnitOptions
): NumericString;

/**
 * Converts a money representation to a numeric string of its value in the currency's minor unit.
 * @param money - The money representation to convert.
 * @param options - The options for the conversion.
 * @returns The money representation in the currency's minor unit.
 * @example
 * toMinorUnit(100, Currency.USD) // '10000'
 * toMinorUnit(150.75, Currency.CAD) // '15075'
 * toMinorUnit(150, 'JPY') // '150'
 */
export function toMinorUnit(
  money: MoneyRepresentation,
  { currency, roundingMode = Round.HalfAwayFromZero }: IToMinorUnitOptions = {}
): NumericString {
  const moneyModel = MoneyModel.fromAmount(money, currency);

  const minorMoney = moneyModel
    .multiplyBy(10 ** moneyModel.currencyDecimals)
    .toFixed({
      roundingMode,
      // this rounds up to the nearest whole number
      // this is a safe operation because the order amount is already
      // rounded to the nearest whole number valid currency amount
      roundingDecimalPlaces: 0,
      displayDecimalPlaces: 0
    });

  return minorMoney.amount;
}
