import { z } from 'zod';

import { LabelService } from '@/services/isomorphic/ReturnsLabelService';
import { ReturnOrderType, ReturnReason, ReturnShippingMethod } from '../order';

import ReturnsFlowStep from './ReturnsFlowStep';
import { ReturnItemType } from '../order/item';

const AddressSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  email: z.string(),
  firstAddress: z.string(),
  secondAddress: z.string().optional(),
  city: z.string(),
  stateProvince: z.string(),
  postalCode: z.string(),
  countryCode: z.string()
});

export const ReturnsLabelSchema = z.object({
  addressData: AddressSchema,
  orderNumber: z.string(),
  isQRCode: z.boolean(),
  labelService: z.nativeEnum(LabelService),
  items: z.array(z.string())
});

export const ReprintLabelSchema = z.object({
  transactionID: z.string(),
  shipmentID: z.string(),
  labelService: z.nativeEnum(LabelService)
});

export const ReturnItemSchema = z.object({
  itemSKU: z.string(),
  returnType: z.nativeEnum(ReturnItemType),
  returnReason: z.nativeEnum(ReturnReason),
  exchangeItemSKU: z.string().nullish(),
  isEvenExchange: z.boolean().nullish()
});

export const ReturnOrderSchema = z.object({
  originalOrderID: z.string(),
  orderID: z.string(),
  type: z.nativeEnum(ReturnOrderType),
  returnItems: z.array(ReturnItemSchema),
  shippingAddress: AddressSchema,
  returnShippingMethod: z.nativeEnum(ReturnShippingMethod)
});

export const ReturnsFlowStateSchema = z.object({
  currentStep: z.nativeEnum(ReturnsFlowStep),
  isOrderLoaded: z.boolean(),
  haveItemsToReturnBeenSelected: z.boolean(),
  isExchangeModeActive: z.boolean(),
  isReturnTypeSelectionComplete: z.boolean(),
  isReturnOrderComplete: z.boolean(),
  returnOrder: ReturnOrderSchema.deepPartial().nullish(),

  // TODO: Add proper schema for orders
  order: z.any(),

  // TODO: Add proper schema for order items
  orderItems: z.array(z.any()).nullish(),

  itemsSelectedForReturn: z.array(z.string()).nullish(),
  itemsPendingReturnTypeSelection: z.array(z.string()).nullish(),
  currentItem: z.string().nullish(),
  storedReturnReason: z.nativeEnum(ReturnReason).nullish()
});
