/* eslint-disable no-console -- The logger mock uses `console.log` to log messages. */
import { mocked } from '@/configs';
import { Constructor, Nullable } from '@/type-utils';
import type {
  ReturnsFlowStateModel,
  ReturnsFlowStep
} from '@/services/models/Returns/flow';
import Service from '../../Service';
import { LABEL_MOCK } from '../../serverless/integrations/PitneyBowesReturnsService/mocks/labelMock';
import MockService, { MockState, ServiceMock } from '../MockService';
import type { ReturnsLabelService } from './ReturnsLabelService';
import { LabelService } from './contracts/LabelService';

const initialState = {};

/**
 * Mock implementation of the LoggerService class.
 */
export default class ReturnsLabelServiceMock extends ServiceMock<ReturnsLabelService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): ReturnsLabelService {
    return MockService.getMockOf(
      this.service
    ) as unknown as ReturnsLabelService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<ReturnsLabelService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Service): void {
    const mockEnabled: boolean = mocked.ReturnsLabelService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getLabel: () => {
          return Promise.resolve(LABEL_MOCK);
        },
        getFlowStateFromSessionStorage: (): Nullable<ReturnsFlowStateModel> =>
          ({
            currentStep: 'confirmation' as ReturnsFlowStep,
            isOrderLoaded: true,
            haveItemsToReturnBeenSelected: true,
            isExchangeModeActive: false,
            isReturnTypeSelectionComplete: true,
            isReturnOrderComplete: true
          } as ReturnsFlowStateModel),
        reprintLabel: () => {
          return Promise.resolve(LABEL_MOCK.documents[0].contents);
        },
        getLabelService: () => {
          return LabelService.UPS;
        }
      },
      {},
      this.state
    );
  }
}
