/** Enumerates valid return reasons. */
enum ReturnReason {
  /** The customer accidentally ordered an incorrect item. */
  OrderedIncorrectItem = 'customerOrderedIncorrectly',

  /** The item does not fit the customer. */
  DoesNotFit = 'doesNotFit',

  /** The item did not meet the customer's expectations. */
  DidNotMeetExpectations = 'didNotMeetExpectations',

  /** The customer changed their mind. */
  ChangedMind = 'changedMind',

  /** The item was not what the customer expected. */
  NotWhatExpected = 'styleNotWhatExpected',

  /** The customer did not like the item's color. */
  DoNotLikeTheColor = 'doesNotLikeColor',

  /** The customer received the wrong item. */
  WrongItem = 'wrongItemShipped'
}

export default ReturnReason;
