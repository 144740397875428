import { InstanceTypeOf } from '@/type-utils';
import { IHTTPErrorMetaData } from '../api-utils/IHTTPErrorMetaData';
import { errorFactory } from '../error-utils';

export const { UnableToAccessResourceError } = errorFactory(
  'UnableToAccessResourceError',
  {
    mapsToHTTPError: 'UnprocessableEntityError'
  } as IHTTPErrorMetaData
);

/**
 * Error to use when a resource could not be accessed, not necessarily because it could
 * not be found, but for any unexpected reasons.
 */
export type UnableToAccessResourceError = InstanceTypeOf<
  typeof UnableToAccessResourceError
>;
