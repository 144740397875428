import { Nullable, TimeStampable } from '@/type-utils';

import { IMoney } from '@/services/models/Money';
import { IMaskedShipment } from '@/services/models/Shipment';

import type { IHistoricOrder } from './IHistoricOrder';

/** The order type, is this full or masked. */
export enum OrderType {
  Full = 'full',
  Masked = 'masked'
}

/**
 * Describes a historic order object with masked PII. Mostly used for retrieving
 * order status for guest users.
 *
 * This object is also the simplest type of historic order. This is why the
 * other order types (such as {@link IHistoricOrder}) build up from it.
 */
export interface IMaskedHistoricOrder {
  /** Whether the order is masked or has full details. */
  orderType: OrderType;

  /** Unique identifier for the order. */
  orderID: string;

  /**
   * Date order created in [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601)
   * format.
   */
  orderDate: TimeStampable;

  /**
   * The shipments in the order. For example, backordered or pre-order items
   * will be broken out into separate shipments.
   */
  shipments: ReadonlyArray<IMaskedShipment>;

  /**
   * The subtotal of the order, in the traditional sense, that is,
   * **BEFORE** shipping, discounts, and taxes, **AND** gift cards.
   *
   * In other words, this is purely the sum of all purchasable items.
   */
  subtotal: Nullable<IMoney>;

  /**
   * The total of the order, in the traditional sense, that is,
   * after shipping, discounts and tax prices, but **NO** gift cards.
   *
   * It is the amount we deduct from the customer across their payment methods.
   */
  total: Nullable<IMoney>;

  /**
   * The tax applied to the order. This includes sales tax and shipping tax.
   */
  tax: Nullable<IMoney>;

  /**
   * The cost of shipping the order **AFTER** any shipping discounts.
   *
   * TODO: this may need to be refactored to support multiple shipments.
   */
  shippingCost: Nullable<IMoney>;

  /**
   * The total discount amount applied to the order. This represents the total
   * amount saved by the user through promotions and coupons, and includes BOTH
   * discounts applied to the lines and to the cart as a whole, but **NOT** shipping discounts.
   *
   * This is a **POSITIVE** value. Multiply this by `-1` to get the difference
   * on the cart total.
   */
  discount: Nullable<IMoney>;

  /**
   * The amount paid for the order using gift cards.
   *
   * This is a **POSITIVE** value. Multiply this by `-1` to get the difference
   * on the cart total.
   */
  giftCardTotal: Nullable<IMoney>;

  /** Masked email of customer. */
  email: string;

  /** The total number of items in the order, including the quantities. */
  readonly totalItems: number;
}
