import type { FC, PropsWithChildren } from 'react';
import { useApplePayScript } from '../hooks/useApplePayScript';
import { ApplePayAvailableContext } from '../internal/ApplePayAvailableContext';

/**
 * A provider which loads the Apple Pay JS SDK and provides a context
 * to determine whether Apple Pay is available.
 */
export const ApplePayProvider: FC<PropsWithChildren> = ({ children }) => {
  const [script, isAvailable] = useApplePayScript();

  return (
    <ApplePayAvailableContext.Provider value={isAvailable}>
      {script}
      {children}
    </ApplePayAvailableContext.Provider>
  );
};
