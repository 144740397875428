/**
 * @file This is for internal use only. Consumers should use the `ApplePayAvailableProvider`
 * component to initialize the Apple Pay JS SDK within a subtree, and the `useIsApplePayAvailable`
 * hook to determine whether Apple Pay is available within a subtree.
 */
import { createContext } from 'react';

/** A context that determines whether Apple Pay is available. */
export const ApplePayAvailableContext = createContext<boolean | null>(null);
ApplePayAvailableContext.displayName = 'ApplePayAvailableContext';
