/**
 * The metadata for a shoe height style.
 */
export interface IShoeHeightMetadata {
  /**
   * The unique identifier for the style.
   */
  id: string;

  /**
   * A human-readable name for the style.
   */
  name: string;

  /**
   * The source URL for the style icon.
   */
  src: string;

  /**
   * The width of the style icon.
   */
  width: number;

  /**
   * The height of the style icon.
   */
  height: number;
}

/**
 * The possible shoe height styles.
 */
export type ShoeHeight = 'low-cut' | 'mid-cut' | 'high-cut';

export const SHOE_HEIGHT_ORDER = [
  'low-cut',
  'mid-cut',
  'high-cut'
] as Array<ShoeHeight>;

// TODO: Replace this to be dynamically determined from product group metadata.
export const STYLE_DATA: Record<ShoeHeight, IShoeHeightMetadata> = {
  'low-cut': {
    id: 'low-cut',
    name: 'Low',
    src: '/images/icon_low-shoe.svg',
    width: 95,
    height: 55
  },
  'mid-cut': {
    id: 'mid-cut',
    name: 'Mid',
    src: '/images/icon_mid-shoe.svg',
    width: 95,
    height: 55
  },
  'high-cut': {
    id: 'high-cut',
    name: 'High',
    src: '/images/icon_high-shoe.svg',
    width: 95,
    height: 55
  }
};
