/**
 * @file This simple object determines the order of the steps in the returns flow.
 */

import ReturnsFlowStep from './ReturnsFlowStep';

export default {
  [ReturnsFlowStep.OrderLookup]: 0,
  [ReturnsFlowStep.OrderHistory]: 1,
  [ReturnsFlowStep.OrderDetails]: 2,
  [ReturnsFlowStep.ReturnTypeSelect]: 3,
  [ReturnsFlowStep.Review]: 4,
  [ReturnsFlowStep.Confirmation]: 5,
  [ReturnsFlowStep.TrackReturn]: 6
};
