/**
 * Represents the label service to be used to create a return label or reprint a label.
 */
export enum LabelService {
  /**
   * Pitney Bowes is a now defunct label service that was used to create
   * return labels. It uses USPS for validation and as a carrier.
   * @deprecated
   *
   * It works by calling the service with some user information and some configuration
   * and returns either a base64 encoded image of the label or a URL to the QR code image.
   * The address validation uses a USPS tool and is fairly strict.
   *
   * The labels can reprinted by calling the service again with a unique tracking number.
   * Although reprints do have a variable expiration date and can only be reprinted a certain
   * number of times.
   */
  PitneyBowes = 'pitneybowes',
  /**
   * UPS is a label service that is used to create return labels. It uses UPS (itself)
   * as a carrier.
   *
   * It works by calling the service with some user information and some configuration and
   * returns a base64 encoded image of the label. This label can be converted into a QR code image
   * with an additional call. There is validation on the return addresses.
   *
   * The labels can be reprinted by calling the service again with a unique tracking number.
   */
  UPS = 'ups'
}
