import axios, { AxiosInstance, AxiosResponse } from 'axios';

import type { Nullable, DTO } from '@/type-utils';
import { NotImplementedError } from '@/utils/errors';

import WebStorageService from '@/services/client/WebStorageService';
import siteCached from '@/services/utils/siteCached';
import { exhaustiveGuard } from '@/utils/function-utils';
import { clientOnlyMethod } from '../../utils/environment-utils';

import Service from '../../Service';

import {
  type IReturnsFlowState,
  ReturnsFlowStateModel
} from '../../models/Returns/flow';

import { EnvironmentService } from '../EnvironmentService';
import ReturnsLabelServiceMock from './ReturnsLabelServiceMock';
import { IReturnLabel } from './contracts/IReturnLabel';
import type { ILabelAddressData } from './contracts/ILabelAddressData';
import { InvalidReturnAddressError } from './errors/InvalidReturnAddressError';
import type { IReturnLabelParams } from './contracts/IReturnLabelParams';
import type { IReprintLabelParams } from './contracts/IReprintLabelParams';
import { LabelService } from './contracts/LabelService';
import type { IReprintLabel } from './contracts/IReprintLabel';
import ConfigurationService, { type Config } from '../ConfigurationService';

/** Abstracts returns for the project. */
export class ReturnsLabelService extends Service {
  private _client: Nullable<AxiosInstance>;

  /**
   * Gets either the current client or creates a new one.
   * @returns An Axios instance.
   */
  @siteCached
  private get client(): AxiosInstance {
    this._client = axios.create({
      baseURL: '/api/returns'
    });

    return this._client;
  }

  /**
   * Gets the existing client or creates a new one
   * if none exists.
   * @returns A `Config<'returns'>`.
   */
  @siteCached
  private get returnsConfig(): Config<'returns'> {
    const config = ConfigurationService.getConfig('returns');
    return config;
  }

  /** Initialize ReturnsLabelService. */
  public constructor() {
    super();
  }

  /**
   * The stub method to get the ReturnsLabel. Here it uses mock data in order to
   * assemble the request body, but in the future it should use the return form
   * to supply this address data and order number.
   * @param orderNumber - The order number.
   * @param addressData - The address of the return label.
   * @param isQRCode - Is this label a qr code or a returns label.
   * @returns The label response, when the UI is in place we should trim
   * this down to what is needed for the frontend.
   * @throws If run on the server.
   */
  public async getLabel(
    orderNumber: string,
    addressData: ILabelAddressData,
    isQRCode: boolean,
    items: Array<string>
  ): Promise<IReturnLabel> {
    if ((typeof window === "undefined")) {
      throw new NotImplementedError(
        'Cannot yet use ReturnsLabelService on Server.'
      );
    }

    try {
      const labelService = this.getLabelService();
      const body: IReturnLabelParams = {
        addressData,
        orderNumber,
        isQRCode,
        labelService,
        items
      };

      const res: AxiosResponse<IReturnLabel> = await this.client.post(
        'label',
        body
      );

      return res.data;
    } catch (error) {
      // PitneyBowes fails with 400 errors when the address is invalid.
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw new InvalidReturnAddressError(
          'Cannot create return label: The provided return address is invalid.'
        );
      }

      // Rethrow if the error is an unexpected one.
      throw error;
    }
  }

  /**
   * Takes in shipmentID from the frontend and uses it to request a reprint label.
   * @param shipmentID - The identifier for this shipment.
   * @param transactionID - The identifier for this return.
   * @param labelService - The label service to use.
   * @returns A reprint label.
   */
  public async reprintLabel(
    shipmentID: string,
    transactionID: string,
    labelService: LabelService
  ): Promise<IReprintLabel> {
    if ((typeof window === "undefined")) {
      throw new NotImplementedError('Cannot yet use ReturnsService on Server.');
    }

    const res: AxiosResponse<IReprintLabel> = await this.client.post(
      `label/reprint`,
      {
        shipmentID,
        transactionID,
        labelService
      } as IReprintLabelParams
    );

    return res.data;
  }

  /**
   * Saves the provided {@link IReturnsFlowState returns flow state} to Session.
   *
   * @param flowState - The flow state to save.
   */
  @clientOnlyMethod public saveFlowStateToSessionStorage(
    flowState: ReturnsFlowStateModel
  ): void {
    const dto = flowState.toDTO();
    WebStorageService.sessionStorage?.setItem('flowState', dto);
  }

  /**
   * Construct a {@link ReturnsFlowStateModel} from the returns flow data
   * stored in the current session storage (if any).
   *
   * @returns A {@link ReturnsFlowStateModel}, or `null` if no returns flow data is found in session storage.
   */
  @clientOnlyMethod
  public getFlowStateFromSessionStorage(): Nullable<ReturnsFlowStateModel> {
    const dto =
      WebStorageService.sessionStorage?.getItem<DTO<IReturnsFlowState>>(
        'flowState'
      );

    return dto ? ReturnsFlowStateModel.from(dto) : null;
  }

  /**
   * Gets the label service for the current brand and locale.
   * @returns The label service.
   * @throws If the label service is unknown.
   */
  public getLabelService(): LabelService {
    const labelService = this.returnsConfig.getSetting('labelServices')
      .value as LabelService;
    switch (labelService) {
      case LabelService.UPS:
        return LabelService.UPS;
      case LabelService.PitneyBowes:
        return LabelService.PitneyBowes;
      default:
        return exhaustiveGuard(
          labelService,
          `Unknown label service: ${labelService}.`
        );
    }
  }
}

export default ReturnsLabelService.withMock(
  new ReturnsLabelServiceMock(ReturnsLabelService)
) as unknown as ReturnsLabelService;
