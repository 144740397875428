'use client';

import { FunctionComponent } from 'react';
import {
  type IProductVM,
  DisplayVariationAttributeType
} from '@/react/view-models/ProductVM';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import LoggerService from '@/services/isomorphic/LoggerService';
import { exhaustiveFallback } from '@/utils/function-utils';
import { observer } from 'mobx-react-lite';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { ColorSelector } from './ColorSelector';
import MaterialSelector from './MaterialSelector';
import DefaultShoeHeightSelector, {
  type IShoeHeightSelectorProps
} from './ShoeHeightSelector';
import { SizeSelector } from './SizeSelector';

import TargetDemographicSelector from './TargetDemographicSelector';
import { ShoeHeightSelectorAB } from './ShoeHeightSelector/personalization/ABTests/ShoeHeightSelectorAB';

import S from './styles.base.module.scss';
import { product_details_style } from "@/lang/__generated__/ahnu/product_details_style";

export interface IVariationSelectorProps {
  /**
   * The product view model to render the variation selector for.
   */
  product: IProductVM;

  /**
   * The type variation selector to render.
   */
  type: DisplayVariationAttributeType;
}

/**
 * A component that renders the appropriate variation selector based on the
 * specified variation selector type and product.
 *
 * **Note:** This does not simply map the type to the corresponding component, but may also
 * add other UI, styles, and logic depending on the given props.
 */
const VariationSelector = observer(function VariationSelector({
  product,
  type
}: IVariationSelectorProps) {
  const [msg, msgf] = useLocaleMessage();

  const ShoeHeightSelector = useBrandLocaleValue<
    FunctionComponent<IShoeHeightSelectorProps>
  >(
    () => ({
      default: DefaultShoeHeightSelector,
      AHNU: ShoeHeightSelectorAB
    }),
    []
  );

  switch (type) {
    case DisplayVariationAttributeType.Color: {
      return (
        <div className={S.borderedSectionWithHeading}>
          <ColorSelector product={product} />
        </div>
      );
    }
    case DisplayVariationAttributeType.Size: {
      return <SizeSelector product={product} />;
    }
    case DisplayVariationAttributeType.Material: {
      return (
        <div className={S.borderedSection}>
          <MaterialSelector product={product} />
        </div>
      );
    }
    case DisplayVariationAttributeType.ShoeHeight: {
      return (
        <div className={S.borderedSectionWithHeading}>
          <div className={S.sectionHeading}>{msg(product_details_style)}</div>
          <ShoeHeightSelector product={product} />
        </div>
      );
    }
    case DisplayVariationAttributeType.TargetDemographic: {
      return (
        <div className={S.targetDemographicSection}>
          <TargetDemographicSelector product={product} />
        </div>
      );
    }
    default:
      return exhaustiveFallback(type, () => {
        LoggerService.warn(
          `Product "${product.sku}" tried to render an unknown display variation selector type: "${type}".`
        );
        // render nothing
        return undefined;
      });
  }
});

export default VariationSelector;
