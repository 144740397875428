import { errorFactory } from '@/utils/error-utils';
import { InvalidArgumentError } from '@/utils/errors';

/**
 * An error for when order lookup fails to find the order or email address. Or the zip code doesn't match.
 */
const { UnableToFindOrderError } = errorFactory(
  'UnableToFindOrderError',
  InvalidArgumentError
);

export default UnableToFindOrderError;
