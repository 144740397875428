import type CartVM from '@/react/view-models/CartVM';
import UserInteractionService, {
  EventType
} from '@/services/isomorphic/UserInteractionService';
import { useCallback } from 'react';

/**
 * A hook which returns a function that removes a line item from the cart.
 * @param cart - The cart view model.
 * @returns A function that removes a line item from the cart.
 */
export function useRemoveFromCart(
  cart: CartVM
): (lineId: string) => Promise<void> {
  return useCallback(
    async (lineId) => {
      const lineItem = cart.items.find((item) => item.uuid === lineId);
      if (!lineItem) throw new Error('Item not found');

      UserInteractionService.makeAction({
        action: EventType.ProductRemove,
        lineItem
      });

      await cart.removeItem(lineId);
    },
    [cart]
  );
}
