import { DTO, Nullable } from '@/type-utils';

import Model from '../Model';

import type { IStorage } from '.';

/**
 * Represents a first-party {@link IStorage storage} object that wraps and extends a
 * [WebStorage API Storage object](https://developer.mozilla.org/en-US/docs/Web/API/Storage).
 */
export default class StorageModel
  extends Model<DTO<IStorage>>
  implements IStorage
{
  private wrappedStorage: Storage;

  /** @inheritdoc */
  public constructor(dto: DTO<IStorage>, wrappedStorage: Storage) {
    super(dto);

    this.wrappedStorage = wrappedStorage;
  }

  /**
   * Given a [WebStorage API Storage object](https://developer.mozilla.org/en-US/docs/Web/API/Storage),
   * create a new {@link StorageModel} to wrap it.
   *
   * @param wrappedStorage - The WebStorage API Storage object to wrap.
   * @returns A new {@link StorageModel}.
   */
  public static fromWebStorage(wrappedStorage: Storage): StorageModel {
    return new StorageModel(
      {
        length: wrappedStorage.length
      },
      wrappedStorage
    );
  }

  /** @inheritdoc */
  public key(keyNumber: number): Nullable<string> {
    return this.wrappedStorage.key(keyNumber);
  }

  /** @inheritdoc */
  public setItem<T>(key: string, value: T): void;

  /** @inheritdoc */
  public setItem(key: string, value: string, stringify: false): void;

  /** @inheritdoc */
  public setItem<T>(key: string, value: T, stringify: true): void;

  /** @inheritdoc */
  public setItem<T>(key: string, value: T, stringify = true): void {
    const valueToSet = stringify ? JSON.stringify(value) : (value as string);
    this.wrappedStorage.setItem(key, valueToSet);
  }

  /** @inheritdoc */
  public getItem<T>(key: string): Nullable<T>;

  /** @inheritdoc */
  public getItem(key: string, parse: false): Nullable<string>;

  /** @inheritdoc */
  public getItem<T>(key: string, parse: true): Nullable<T>;

  /** @inheritdoc */
  public getItem<T>(key: string, parse = true): Nullable<T> {
    const stringValue = this.wrappedStorage.getItem(key);

    if (!stringValue) {
      return null;
    }

    if (parse) {
      return JSON.parse(stringValue);
    }

    return stringValue as T;
  }

  /** @inheritdoc */
  public removeItem(key: string): void {
    this.wrappedStorage.removeItem(key);
  }

  /** @inheritdoc */
  public clear(): void {
    this.wrappedStorage.clear();
  }

  /** @inheritdoc */
  public get length(): number {
    return this.wrappedStorage.length;
  }

  /** @inheritdoc */
  public toDTO(): DTO<IStorage> {
    return {
      length: this.length
    };
  }
}
