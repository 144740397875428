'use client';

import { useSingleProductCart } from '@/react/hooks/useSingleProductCart';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';
import type IStylable from '../../traits/IStylable';
import type { IApplePayButtonProps } from '../ApplePayButton';
import { ApplePayProvider } from '../ApplePayProvider';
import { BuyWithApplePayButton } from '../BuyWithApplePayButton';

export interface IExpressBuyWithApplePayButtonProps
  extends IStylable,
    Omit<IApplePayButtonProps, 'onClick' | 'locale'> {
  /** The {@link IProductVM} backing the product to be purchased with Apple Pay. */
  product: IProductVM;
}

export const ExpressBuyWithApplePayButton = observer(
  function ExpressBuyWithApplePayButton({
    product,
    isLoading = false,
    ...restProps
  }: IExpressBuyWithApplePayButtonProps) {
    const [cart, isPending] = useSingleProductCart(product);

    return (
      <ApplePayProvider>
        {/* This condition is inside the `ApplePayProvider` to start loading
         the script even if the button isn't ready to be rendered. */}
        {!product.isPurchasable ? null : (
          <BuyWithApplePayButton
            cart={cart}
            isLoading={isLoading || isPending || cart.isEmpty}
            {...restProps}
          />
        )}
      </ApplePayProvider>
    );
  }
);
