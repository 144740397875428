'use client';

import { useSharedScript } from '@/react/hooks/useSharedScript';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import PaymentMethodsService from '@/services/isomorphic/PaymentMethodsService';
import type { ReactNode } from 'react';

/**
 * A hook for enabling the use of Apple Pay.
 * @returns A 2-tuple containing a ReactNode which loads the
 * Apple Pay SDK if Apple Pay is enabled and the node is rendered,
 * and a boolean indicating whether Apple Pay features are available for use.
 */
export function useApplePayScript(): [script: ReactNode, isAvailable: boolean] {
  const sdkURL =
    ConfigurationService.getConfig('applePay').getSetting('sdkURL').value;
  const [script, status] = useSharedScript(sdkURL);

  const isReady = status === 'ready';

  // always show in Storybook
  if ((typeof __isolatedContext__ !== "undefined")) return [script, isReady];

  // If Apple Pay is disabled in the configuration, return a null node to avoid loading the SDK
  if (!PaymentMethodsService.isApplePayEnabled()) return [null, false];

  /**
   * We must check that the status of the script is 'ready' before checking
   * whether Apple Pay is supported because the SDK may load APIs that are
   * required to determine if it is supported.
   */
  const isAvailable = isReady && PaymentMethodsService.isApplePaySupported();

  return [script, isAvailable];
}
