import type { IPBLabelAddress } from '../interfaces/IPBLabelAddress';
import type { IPBReturnLabel } from '../interfaces/IPBReturnLabel';
import { CONTENTS_MOCK } from './contentsMock';

/**
 * This is a mock of the transformed from address. This is what would be sent
 * in the body of the shipping request.
 */
export const FROM_ADDRESS: IPBLabelAddress = {
  company: 'Shop',
  name: 'first last',
  phone: '3333333',
  email: 'test@deckers.com',
  addressLines: ['123 Leroux'],
  cityTown: 'Flagstaff',
  stateProvince: 'AZ',
  postalCode: '86001-4741',
  countryCode: 'US'
};

/**
 * This is a total response return label mock from `PitneyBowes`.
 */
export const LABEL_MOCK: IPBReturnLabel = {
  fromAddress: {
    company: 'Shop',
    name: 'first last',
    phone: '3333333',
    email: 'test@deckers.com',
    residential: true,
    addressLines: ['123 Leroux'],
    cityTown: 'Flagstaff',
    stateProvince: 'AZ',
    postalCode: '86001-4741',
    countryCode: 'US',
    deliveryPoint: '50'
  },
  toAddress: {
    company: 'PITNEY BOWES RETURNS',
    addressLines: ['PARCEL RETURN SERVICE', '', ''],
    cityTown: '',
    stateProvince: '',
    postalCode: '56901'
  },
  parcel: { weight: { unitOfMeasurement: 'OZ', weight: 0 } },
  rates: [
    {
      carrier: 'NEWGISTICS',
      parcelType: 'PKG',
      specialServices: [],
      serviceId: 'PRCLSEL'
    }
  ],
  documents: [
    {
      contentType: 'BASE64',
      contents: CONTENTS_MOCK,
      customerData: {
        labelDetails: [
          {
            name: 'barcodeType',
            value: 'CODE128'
          }
        ]
      },
      fileFormat: 'PNG',
      resolution: 'DPI_300',
      size: 'DOC_4X6',
      type: 'SHIPPING_LABEL'
    }
  ],
  shipmentOptions: [
    { name: 'SHIPPER_ID', value: '3002199443' },
    { name: 'CLIENT_ID', value: 'UGGA' },
    { name: 'MINIMAL_ADDRESS_VALIDATION', value: 'true' },
    { name: 'CLIENT_SERVICE_FLAG', value: 'Standard' },
    { name: 'DISPOSITION_RULESET_ID', value: '1' },
    {
      name: 'SMART_LABEL_BARCODE',
      value: '725108600101UGGA040100000000000092773661M'
    },
    { name: 'RETURN_ID' }
  ],
  parcelTrackingNumber: '92023901007103000927736629',
  shipmentId: 'NGST2210949922176742'
};
