import type { RenderError } from '@/react/components/errors/devtools/RenderError';
import type { IRouter } from '@/react/utils/router-utils/useRouter';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import type { IBreadcrumb } from '@/services/models/Breadcrumb';
import type { ILineItem } from '@/services/models/Cart/LineItem';
import type { ProductAvailabilityState } from '@/services/models/Inventory';
import type { IImage } from '@/services/models/Media/Image';
import type { IPrice } from '@/services/models/Price';
import type { IProduct, IProductDetails } from '@/services/models/Product';
import type { IRatings } from '@/services/models/ReviewsModel';
import type { ISizeChart } from '@/services/models/SizeChart';
import { getHook } from '@/services/utils/react-utils/hook-utils';
import type { Asyncable, Nullable } from '@/type-utils';
import { isNullOrEmpty } from '@/utils/null-utils';
import { makeAutoObservable } from 'mobx';
import type CartVM from '../../CartVM';
import type GroupInventoryVM from '../../Inventory/GroupInventoryVM';
import DisplayVariationAttributeType from '../DisplayVariationAttributeType';
import type IDescriptionDisplay from '../IDescriptionDisplay';
import type IDisplayProductAvailability from '../IDisplayProductAvailability';
import type {
  DisplayVariationAttributeFromType,
  IDisplaySizeVariationAttribute
} from '../IDisplayVariationAttribute';
import type IProductVM from '../IProductVM';
import type { IUpdateFromRouterOptions } from '../ProductVM';

/**
 * Enumerates values allowed for the `genderDisplayMode` query param in
 * Gender Adaptive PDP experiences.
 */
export enum GenderAdaptiveDisplayMode {
  Men = 'men',
  Women = 'women',
  AllGender = 'allgender'
}

/**
 * Decorator for {@link IProductVM} instances with all-gender sizing. Enables the
 * display sizes only for a specific gender.
 */
export class GenderAdaptiveProductVM implements IProductVM {
  /** @inheritdoc */
  public get inventoryMap(): GroupInventoryVM {
    return this.vm.inventoryMap;
  }

  /** @inheritdoc */
  public constructor(private vm: IProductVM) {
    makeAutoObservable(this);
  }

  /** @inheritdoc */
  public get isPDP(): boolean {
    return this.vm.isPDP;
  }

  /** @inheritdoc */
  public get hasAllGenderSizing(): boolean {
    return this.vm.hasAllGenderSizing;
  }

  /** @inheritdoc */
  public get variationUPCs(): ReadonlyArray<string> {
    return this.vm.variationUPCs;
  }

  /** @inheritdoc */
  public get styleNumber(): string {
    return this.vm.styleNumber;
  }

  /** @inheritdoc */
  public get areAllVariantsOOSorUnavailable(): boolean {
    return this.vm.areAllVariantsOOSorUnavailable;
  }

  /** @inheritdoc */
  public determineResultingOnlineStatusOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): boolean {
    return this.vm.determineResultingOnlineStatusOnAttributeSelection(
      variationType,
      value
    );
  }

  /** @inheritdoc */
  public determineResultingAvailabilityOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): ProductAvailabilityState {
    return this.vm.determineResultingAvailabilityOnAttributeSelection(
      variationType,
      value
    );
  }

  /** @inheritdoc */
  public get currentProduct(): IProduct {
    return this.vm.currentProduct;
  }

  /** @inheritdoc */
  public get price(): Nullable<IPrice> {
    return this.vm.price;
  }

  /** @inheritdoc */
  public get sku(): string {
    return this.vm.sku;
  }

  /** @inheritdoc */
  public get group(): Nullable<string> {
    return this.vm.group;
  }

  /** @inheritdoc */
  public get ratings(): Asyncable<Nullable<IRatings>> {
    return this.vm.ratings;
  }

  /** @inheritdoc */
  public get reviewsID(): string {
    return this.vm.reviewsID;
  }

  /** @inheritdoc */
  public get sizeChart(): Nullable<ISizeChart> {
    return this.vm.sizeChart;
  }

  /** @inheritdoc */
  public get defaultColor(): string {
    return this.vm.defaultColor;
  }

  /** @inheritdoc */
  public async addToCart(cart: CartVM): Promise<void> {
    return this.vm.addToCart(cart);
  }

  /** @inheritdoc */
  public async replaceInCart(
    cart: CartVM,
    productLineItem: ILineItem
  ): Promise<void> {
    return this.vm.replaceInCart(cart, productLineItem);
  }

  /** @inheritdoc */
  public get allVariationTypes(): ReadonlyArray<DisplayVariationAttributeType> {
    return this.shouldShowGenderSelector
      ? [
          ...this.vm.allVariationTypes,
          DisplayVariationAttributeType.TargetDemographic
        ]
      : this.vm.allVariationTypes;
  }

  /** @inheritdoc */
  public get urlToProduct(): URL {
    return this.vm.urlToProduct;
  }

  /** @inheritdoc */
  public get name(): string {
    return this.vm.name;
  }

  /** @inheritdoc */
  public get images(): ReadonlyArray<IImage> {
    return this.vm.images;
  }

  /** @inheritdoc */
  public get details(): IProductDetails {
    return this.vm.details;
  }

  /** @inheritdoc */
  public get selectedVariations(): {
    [key in DisplayVariationAttributeType]?: string | undefined;
  } {
    return this.vm.selectedVariations;
  }

  /** @inheritdoc */
  public get breadcrumbs(): ReadonlyArray<IBreadcrumb> {
    return this.vm.breadcrumbs;
  }

  /** @inheritdoc */
  public get availability(): IDisplayProductAvailability {
    return this.vm.availability;
  }

  /** @inheritdoc */
  public get isPurchasable(): boolean {
    return this.vm.isPurchasable;
  }

  /** @inheritdoc */
  public get description(): IDescriptionDisplay {
    return this.vm.description;
  }

  /** @inheritdoc */
  public async selectVariationAttribute(
    variationType: DisplayVariationAttributeType,
    value: string
  ): Promise<void> {
    const router = getHook<IRouter>('Router');

    if (variationType === DisplayVariationAttributeType.TargetDemographic) {
      const { url } = EnvironmentService;

      url.searchParams.set('genderDisplayMode', value);

      router.replace(url);
    } else {
      await this.vm.selectVariationAttribute(variationType, value);
    }
  }

  /** @inheritdoc */
  public async updateInventory(): Promise<void> {
    await this.vm.updateInventory();
  }

  /** @inheritdoc */
  public reportProductPersonalizationPage(): void {
    return this.vm.reportProductPersonalizationPage();
  }

  /** @inheritdoc */
  public determineResultingUpcOnAttributeSelection(
    variationType: DisplayVariationAttributeType,
    value: string
  ): Nullable<string> {
    return this.vm.determineResultingUpcOnAttributeSelection(
      variationType,
      value
    );
  }

  /** @inheritdoc */
  public getVariationAttributesByType<T extends DisplayVariationAttributeType>(
    type: T,
    onlineOnly = true
  ): ReadonlyArray<DisplayVariationAttributeFromType<T>> {
    if (type === DisplayVariationAttributeType.TargetDemographic) {
      const router = getHook<IRouter>('Router');
      const currentDisplayMode = router.query.genderDisplayMode as
        | string
        | undefined;

      return [
        {
          value: GenderAdaptiveDisplayMode.Men as string
        },
        {
          value: GenderAdaptiveDisplayMode.Women as string
        }
      ].map((option) => ({
        ...option,

        type: DisplayVariationAttributeType.TargetDemographic,

        isSelectable: true,

        // For every option, add an `isSelected` property that will be true if...
        isSelected:
          // ...either the current display mode matches the current option...
          currentDisplayMode === option.value ||
          // ...or there is no selected display mode and this is the default
          // option.
          (isNullOrEmpty(currentDisplayMode) &&
            option.value === this.defaultGenderSelection)
      })) as unknown as ReadonlyArray<DisplayVariationAttributeFromType<T>>;
    }

    const unfilteredAttributes = this.vm.getVariationAttributesByType(
      type,
      onlineOnly
    );

    if (type === DisplayVariationAttributeType.Size) {
      const router = getHook<IRouter>('Router');

      const displayMode = router.query.genderDisplayMode as string | undefined;

      let filteredSizes = unfilteredAttributes.filter(
        (size) =>
          (size as IDisplaySizeVariationAttribute).metadata.gender ===
          (displayMode ?? this.defaultGenderSelection)
      );

      const selectedSize =
        this.vm.selectedVariations[DisplayVariationAttributeType.Size];

      if (selectedSize?.includes('allgender')) {
        const selectedSizeSegments = selectedSize
          .split('-')
          .pop()
          ?.split('/')
          .map((size) => size.replaceAll(/[MW-]/g, ''));

        if (!selectedSizeSegments) {
          return filteredSizes;
        }

        const [selectedMensSize, selectedWomensSize] = selectedSizeSegments;

        filteredSizes = filteredSizes.map((size) => {
          const sizeSegments = size.value.split('-');

          const sizeNumber = sizeSegments[sizeSegments.length - 1].replaceAll(
            /[MW-]/g,
            ''
          );

          const sizeGender = sizeSegments[sizeSegments.length - 2];

          if (
            (sizeGender === 'women' && sizeNumber === selectedWomensSize) ||
            (sizeGender === 'men' && sizeNumber === selectedMensSize)
          ) {
            return { ...size, isSelected: true };
          }

          return size;
        });
      }

      return filteredSizes;
    }

    return unfilteredAttributes;
  }

  /** @inheritdoc */
  public filterOfflineVariations<T extends DisplayVariationAttributeType>(
    variationAttributes: ReadonlyArray<DisplayVariationAttributeFromType<T>>
  ): ReadonlyArray<DisplayVariationAttributeFromType<T>> {
    return this.vm.filterOfflineVariations(variationAttributes);
  }

  /** @inheritdoc */
  public hasVariationAttributeSelected(
    type: DisplayVariationAttributeType
  ): boolean {
    return this.vm.hasVariationAttributeSelected(type);
  }

  /** @inheritdoc */
  public updateRouter(router: IRouter): void {
    return this.vm.updateRouter(router);
  }

  /** @inheritdoc */
  public async updateFromRouter(
    router: IRouter,
    options?: IUpdateFromRouterOptions
  ): Promise<void> {
    return this.vm.updateFromRouter(router, options);
  }

  /**
   * Retrieves the configuration for gender adaptive display.
   * @returns The configuration for gender adaptive display.
   */
  // Allow this type to be inferred to leverage the static typing provided by
  // config objects.
  //
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- See above.
  private get config() {
    return ConfigurationService.getConfig('product').getSetting(
      'details.genderAdaptiveDisplay'
    );
  }

  /**
   * The default value to use for the `genderDisplayMode` URL param.
   * @returns The default value to use for the `genderDisplayMode` URL param.
   */
  private get defaultGenderSelection(): GenderAdaptiveDisplayMode {
    return this.shouldShowGenderSelector
      ? (this.config.defaultGenderSelection.value as GenderAdaptiveDisplayMode)
      : // Use all-gender as the default experience if a gender selector is not
        // being shown. This is to prevent the display of sizes for one gender
        // without the ability of switching to the other.
        GenderAdaptiveDisplayMode.AllGender;
  }

  /**
   * If the custom gender selector for gender adaptive displays should be
   * shown.
   *
   * @returns `true` if the selector should be shown.
   */
  private get shouldShowGenderSelector(): boolean {
    return this.isPDP || this.config.useOnQuickview.value;
  }

  /** @inheritdoc */
  public get transientErrors(): Nullable<RenderError> {
    return this.vm.transientErrors;
  }
}
