/** Enumerates valid Return Shipping Methods. */
enum ReturnShippingMethod {
  /** The customer prints a shipping label. */
  ShippingLabel,

  /** The customer shows a digital QR code when posting the items. */
  QRCode
}

export default ReturnShippingMethod;
