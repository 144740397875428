/**
 * Represents known display variation attribute types.
 */
enum DisplayVariationAttributeType {
  Color = 'color',
  Size = 'size',
  Material = 'material',
  ShoeHeight = 'shoeHeight',
  TargetDemographic = 'target'
}

export default DisplayVariationAttributeType;
