'use client';

import type IStylable from '@/react/components/traits/IStylable';
import { Button } from '@/react/components/core-ui/Button';

import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import type { IProductVM } from '@/react/view-models/ProductVM';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import S from './styles.module.scss';

interface IGenericSelectorProps extends IStylable {
  /**
   * The product view model.
   */
  product: IProductVM;

  /**
   * The display variation attribute type to render a selector for.
   */
  variationType: DisplayVariationAttributeType;

  /**
   * The function to use to sort the variation values.
   * @param a - The first value to compare.
   * @param b - The second value to compare.
   * @returns A number indicating the sort order.
   */
  sortFn?: (a: string, b: string) => number;

  /**
   * An optional function that can be used to transform the variation value into  a
   * human-readable string.
   * @param value - The value to format.
   * @returns The formatted value.
   */
  valueFormatterFn?: (value: string) => string;
}

/** The Group selector component for the PDP. */
const GenericSelector = observer(function GenericSelector({
  product,
  variationType,
  sortFn = (a, b) => a.localeCompare(b),
  valueFormatterFn = (value) => value,
  className,
  style
}: IGenericSelectorProps) {
  const variations = product
    .getVariationAttributesByType(variationType)
    .slice(0); // Clone the array to avoid modifying the original.

  variations.sort((vA, vB) => sortFn(vA.value, vB.value));

  return (
    <div className={S.container}>
      {variations.map(({ type, value, isSelected, isSelectable }) => (
        <Button
          key={value}
          variant="tertiary"
          style={style}
          className={classNames(
            S.button,
            { [S.active]: isSelected },
            className
          )}
          disabled={!isSelectable}
          onClick={() =>
            isSelectable && product.selectVariationAttribute(type, value)
          }
        >
          {valueFormatterFn(value) || value}
        </Button>
      ))}
    </div>
  );
});

export default GenericSelector;
