import { errorFactory } from '@/utils/error-utils';
import { ResourceNotFoundError } from '@/utils/errors';

/**
 * An error for when an order is not found during lookup.
 */
const { OrderNotFoundError } = errorFactory(
  'OrderNotFoundError',
  ResourceNotFoundError
);

export default OrderNotFoundError;
