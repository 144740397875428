import { ILineItemPromotion } from '../LineItem';
import PromotionTarget from '../PromotionTarget';

export default {
  id: '0',
  campaignID: '0',
  name: '5 off',
  target: PromotionTarget.LineItem,
  type: 0,
  label: '$5 off total with coupon code 5OFFDEAL',
  visible: true,
  apply: 'once',
  mode: 0,
  value: 2.5,
  couponID: '5OFFDEAL'
} as ILineItemPromotion;
