/**
 * A utility function which wraps an asynchronous operation to warn the user from
 * unloading the page while the operation is in progress. This is useful for
 * operations which may result in data loss or a broken experience if interrupted.
 *
 * **Note**: Usages of this function are merely a preventative measure; it does not
 * guarantee that a user won't still navigate away or forcefully close the tab. This
 * is particularly true with mobile devices. As a result, usages of this utility do not
 * replace the need for proper interruption handling and recovery mechanisms.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes beforeunload event on MDN}
 *
 * @param operation - The operation to perform.
 * @returns The result of the operation.
 * @example
 * // Prevents the customer from unloading the page while the order is being processed.
 * await withPreventUnload(() => cart.placeOrder());
 */
export const withPreventUnload = async <T>(
  operation: () => Promise<T> // this should not be used with synchronous operations
): Promise<T> => {
  const abortController = new AbortController();
  const { signal } = abortController;

  window.addEventListener(
    'beforeunload',
    // Warn that navigating away while the operation is in progress may
    // result in data loss or a broken experience.
    (event) => event.preventDefault(),
    { signal }
  );

  try {
    return await operation();
  } finally {
    abortController.abort();
  }
};
